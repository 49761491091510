import React, { useState } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar";
import About2 from "./About2";
import Footer from "components/Footers/Footer";
import businesspeoplestackinghandstogether from "assets/img/businesspeople-stacking-hands-together.jpg";
import sharedvision from "assets/img/icons/shared-vision.png";
import customer from "assets/img/icons/customer.png";
import mission from "assets/img/icons/mission.png";
import success from "assets/img/icons/success.png";
import missionnew from "assets/img/missionnew.jpeg";
import visionnew from "assets/img/visionnew.jpeg";
import ourexcellence from "assets/img/ourexcellence.jpg";
import ourvaluess from "assets/img/ourvaluess.jpg";
import startup from "assets/img/startup.jpg";
import marslabdb from "assets/img/marslabdb.png";
import marslabml from "assets/img/marslabml.png";
import award1 from "assets/img/award1.png";
import award2 from "assets/img/award2.png";
import award from "assets/img/icons/award.png";

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "10px", zIndex: 1 }}
      onClick={onClick}
    >
      <span>{"<"}</span>
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "10px", zIndex: 1 }}
      onClick={onClick}
    >
      <span>{">"}</span>
    </div>
  );
};

const Aboutt = () => {
  const totalSteps = 3;
  const [currentStep, setCurrentStep] = useState(1);

  const handleNext = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrev = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const stepsContent = [
    {
      title: "Our Mission",
      content: (
        <div>
          <p>
            We are committed to providing comprehensive IT solutions that align
            with our customers' unique needs and aspirations. We strive to:
          </p>
        </div>
      ),
    },
    {
      title: "Our Vission",
      content: (
        <div>
          <p>
            To be the premier IT solutions provider, recognized for our
            exceptional expertise, unwavering commitment to customer success,
            and dedication to ethical practices.
          </p>
        </div>
      ),
    },
    {
      title: "Our Values",
      content: (
        <div>
          <p>
            Our core values guide our every action and decision, ensuring that
            we remain a trusted partner for our customers:
          </p>
        </div>
      ),
    },
    {
      title: "Excellence",
      content: (
        <div>
          <p>
            Our core values guide our every action and decision, ensuring that
            we remain a trusted partner for our customers:
          </p>
        </div>
      ),
    },
    {
      title: "Awards",
      content: (
        <div>
          <p>
            Our core values guide our every action and decision, ensuring that
            we remain a trusted partner for our customers:
          </p>
        </div>
      ),
    },
  ];

  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.25,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1280, // desktop breakpoint
        settings: {
          slidesToShow: 2.25,
        },
      },
      {
        breakpoint: 1024, // tablet breakpoint
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 900, // tablet landscape breakpoint
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600, // mobile breakpoint
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="">
      <IndexNavbar fixed />
      <div className="xl:mt-20 lg:mt-16 mt-16">
        <div className="">
          <div className="bg-stone-200">
            <div className="container mx-auto text-danger relative overflow-hidden xl:px-16 px-4 xl:py-4 lg:py-4 py-4">
              <a href="index" className="link text-ivory-black">
                Home{" "}
              </a>{" "}
              ― Our company
            </div>
          </div>
        </div>

        <div className="container mx-auto relative overflow-hidden xl:px-16">
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 xl:py-16">
            <div className="font">
              <div className="xl:p-0 lg:p-0">
                <div className="xl:block md:block hidden">
                  <p className="px-4 font xl:text-3xl md:text-xl uppercase text-xl font-semibold xl:w-11/12 xl:pt-12  md:pt-12 xl:py-0">
                    Your Trusted IT Partner for the Digital Age
                  </p>
                </div>

                <div className="xl:block md:block hidden">
                  <p className="px-4 font xl:text-lg md:text-sm md:pt-4 text-black xl:pt-8 pt-4">
                    In today's rapidly evolving digital landscape, businesses
                    require a reliable and experienced IT partner to navigate
                    the complexities of technology and harness its power to
                    achieve their strategic goals. At MARSLAB we are passionate
                    about empowering organizations of all sizes to embrace
                    technology, optimize their operations, and thrive in the
                    digital era.
                  </p>
                </div>
              </div>
            </div>

            <div className="font xl:mt-0 md:p-4 p-4 md:mt-6 lg:p-0">
              <img
                src={businesspeoplestackinghandstogether}
                alt="Your browser does not support this image"
                className="rounded-xl md:h-200 "
              ></img>
            </div>

            <div className="xl:hidden md:hidden block">
              <p className=" px-4 font pt-4 xl:text-4xl md:text-2xl text-2xl font-thin md:font-thin xl:w-9/12 md:pt-12 xl:py-0">
                Your Trusted IT Partner for the Digital Age
              </p>
            </div>
            <div className="xl:hidden md:hidden block">
              <p className=" px-4 font xl:text-base md:text-base md:pt-4 text-black xl:pt-8 pt-4">
                In today's rapidly evolving digital landscape, businesses
                require a reliable and experienced IT partner to navigate the
                complexities of technology and harness its power to achieve
                their strategic goals. At MARSLAB we are passionate about
                empowering organizations of all sizes to embrace technology,
                optimize their operations, and thrive in the digital era.
              </p>
            </div>
          </div>
        </div>

        <div className="font container mx-auto relative overflow-hidden xl:px-16 px-4 xl:py-8 lg:py-0 space-y-6 lg:space-y-2 pb-4 xl:pt-0 pt-8">
          <div className="tab-buttons text-center">
            <div className="grid xl:grid-cols-5 md:grid-cols-5 grid-cols-2 border-b-c xl:gap-0 gap-2 ">
              <button
                className={`${
                  activeTab === 1
                    ? "active xl:text-xl md:text-sm text-base"
                    : "border-sky-600"
                } font font-semibold xl:text-xl md:text-sm text-base xl:pt-2 xl:py-0 py-2 focus:outline-none`}
                onClick={() => handleTabClick(1)}
              >
                <div className="flex items-center xl:ml-10">
                  <img
                    src={sharedvision}
                    className="xl:h-8 xl:w-8 xl:mr-2 xl:mb-2 h-5 w-5 mr-2"
                  />
                  <p className="xl:text-xl xl:mb-2">Our Vision</p>
                </div>
              </button>

              <button
                className={`${
                  activeTab === 2
                    ? "active xl:text-xl md:text-sm text-base"
                    : "border-sky-600"
                } font font-semibold xl:text-xl md:text-sm text-base xl:pt-2 xl:py-0 py-2 focus:outline-none`}
                onClick={() => handleTabClick(2)}
              >
                <div className="flex items-center xl:ml-10">
                  <img
                    src={mission}
                    className="xl:h-6 xl:w-6 xl:mr-2 xl:mb-2 h-5 w-5 mr-2"
                  />
                  <p className="xl:text-xl xl:mb-2">Our Mission</p>
                </div>
              </button>

              <button
                className={`${
                  activeTab === 3
                    ? "active xl:text-xl md:text-sm text-base"
                    : "border-sky-600"
                } font font-semibold xl:text-xl md:text-sm text-base xl:pt-2 xl:py-0 py-2 focus:outline-none`}
                onClick={() => handleTabClick(3)}
              >
                <div className="flex items-center xl:ml-10">
                  <img
                    src={customer}
                    className="xl:h-8 xl:w-8 xl:mr-2 xl:mb-2 h-5 w-5 mr-2"
                  />
                  <p className="xl:text-xl xl:mb-2">Our Values</p>
                </div>
              </button>

              <button
                className={`${
                  activeTab === 4
                    ? "active xl:text-xl md:text-sm text-base"
                    : "border-sky-600"
                } font font-semibold xl:text-xl md:text-sm text-base xl:pt-2 xl:py-0 py-2 focus:outline-none`}
                onClick={() => handleTabClick(4)}
              >
                <div className="flex items-center xl:ml-4">
                  <img
                    src={success}
                    className="xl:h-8 xl:w-8 xl:mr-2 xl:mb-2 h-5 w-5 mr-1"
                  />
                  <p className="xl:text-xl xl:mb-2">Our Excellence</p>
                </div>
              </button>

              <section id="award">
                <button
                  className={`${
                    activeTab === 5
                      ? "active xl:text-xl md:text-sm text-base"
                      : "border-sky-600"
                  } font font-semibold xl:text-xl md:text-sm text-base xl:ml-0 -ml-22 md:ml-0 xl:pt-2 xl:py-0 py-2 focus:outline-none`}
                  onClick={() => handleTabClick(5)}
                >
                  <div className="flex items-center xl:ml-10 ml-8">
                    <img
                      src={award}
                      className="xl:h-8 xl:w-8 xl:mr-2 xl:mb-2 h-5 w-5 mr-1"
                    />
                    <p className="xl:text-xl xl:mb-2">Certification</p>
                  </div>
                </button>
              </section>
            </div>
          </div>
        </div>

        <div className="font container mx-auto relative overflow-hidden xl:px-16 px-4">
          <div className="tab-content text-center">
            {activeTab === 1 && (
              <div className="content-center xl:px-5 xl:pt-0 xl:text-xl">
                <div className="grid xl:grid-cols-2 md:grid-cols-2 text-lg ">
                  <div className="font text-left xl:text-lg text-sm  xl:pt-28 xl:p-5 p-5">
                    <p>
                      To be the premier IT solutions provider, recognized for
                      our exceptional expertise, unwavering commitment to
                      customer success, and dedication to ethical practices.
                    </p>
                  </div>
                  <div className="grid xl:grid-cols-1 text-left xl:mb-0 mb-8 md:ml-12 xl:ml-0 lg:mt-4 ml-0">
                    <img
                      src={visionnew}
                      alt="Your browser does not support this content"
                      className="rounded-xl xl:w-35rem"
                    />
                  </div>
                </div>
              </div>
            )}
            {activeTab === 2 && (
              <div className="content-center xl:px-5 xl:pt-0 xl:text-xl">
                {/* Content for Our Vision */}
                <div className="grid xl:grid-cols-2 md:grid-cols-2 text-lg ">
                  <div className="font text-left xl:text-lg text-sm xl:pt-4 xl:p-5 p-5">
                    <p>
                      We are committed to providing comprehensive IT solutions
                      that align with our customers' unique needs and
                      aspirations. We strive to:
                    </p>
                    <ul className="list-none xl:pt-4 pt-4">
                      <li className="font xl:text-lg text-sm flex items-start  ">
                        <span className="mr-2 text-red">✔</span> Deliver
                        exceptional IT services that exceed expectations
                      </li>
                      <li className="font xl:text-lg text-sm flex items-start ">
                        <span className="mr-2">✔</span> Empower businesses with
                        innovative and secure technology solutions
                      </li>
                      <li className="font xl:text-lg text-sm flex items-start ">
                        <span className="mr-2 text-red">✔</span> Drive
                        efficiency and productivity through seamless technology
                        integration
                      </li>
                      <li className="font xl:text-lg text-sm flex items-start ">
                        <span className="mr-2">✔</span> Foster a culture of
                        continuous improvement and innovation
                      </li>
                      <li className="font xl:text-lg text-sm flex items-start ">
                        <span className="mr-2 text-red">✔</span> Establish
                        long-lasting partnerships with our customers
                      </li>
                    </ul>{" "}
                  </div>
                  <div className="grid xl:grid-cols-1 text-left xl:mb-0 mb-8 md:ml-8 xl:ml-0 md:mt-16 xl:mt-0 lg:mt-4 mt-0 ml-0">
                    <img
                      src={missionnew}
                      alt="Your browser does not support this content"
                      className="rounded-xl xl:w-35rem"
                    />
                  </div>
                </div>
              </div>
            )}
            {activeTab === 3 && (
              <div className="content-center xl:px-5 xl:pt-6 xl:text-xl">
                {/* Content for Our Mission */}
                <div className="grid xl:grid-cols-2 md:grid-cols-2 text-lg ">
                  <div className="font text-left xl:text-lg text-sm  xl:pt-4 xl:p-5 p-5">
                    <p>
                      Our core values guide our every action and decision,
                      ensuring that we remain a trusted partner for our
                      customers:
                    </p>
                    <ul className="list-none xl:pt-4 pt-4">
                      <li className="font xl:text-lg text-sm flex items-start  ">
                        <span className="mr-2 text-red">✔</span> Deliver
                        Customer Focus: We prioritize our customers' needs,
                        tailoring our solutions to their specific requirements
                        and goals.
                      </li>
                      <li className="font xl:text-lg text-sm flex items-start ">
                        <span className="mr-2">✔</span> Innovation: We
                        continuously seek out and implement cuttingedge
                        technologies to empower our customers.
                      </li>
                      <li className="font xl:text-lg text-sm flex items-start ">
                        <span className="mr-2 text-red">✔</span> Integrity: We
                        uphold the highest ethical standards in all our
                        interactions and business practices.
                      </li>
                      <li className="font xl:text-lg text-sm flex items-start ">
                        <span className="mr-2">✔</span> Teamwork: We collaborate
                        effectively, leveraging the diverse expertise of our
                        team members to deliver exceptional results.
                      </li>
                      <li className="font xl:text-lg text-sm flex items-start ">
                        <span className="mr-2 text-red">✔</span> Passion: We are
                        passionate about technology and its transformative
                        potential for businesses.
                      </li>
                    </ul>{" "}
                  </div>
                  <div className="grid xl:grid-cols-1 text-left xl:mb-0 mb-8 xl:pt-0 md:ml-12 md:mt-20 xl:mt-0 lg:mt-4 xl:ml-0 ml-0">
                    <img
                      src={ourvaluess}
                      alt="Your browser does not support this content"
                      className="rounded-xl"
                    />
                  </div>
                </div>
              </div>
            )}
            {activeTab === 4 && (
              <div className="content-center xl:px-5 xl:pt-0 xl:text-xl">
                {/* Content for Our Vision */}
                <div className="grid xl:grid-cols-2 md:grid-cols-2 text-lg ">
                  <div className="font text-left xl:text-lg text-sm  xl:pt-28 xl:p-5 p-5">
                    <p>
                      At MARSLAB, we are committed to providing exceptional IT
                      services that deliver tangible results. We believe in
                      building longterm partnerships with our customers,
                      providing them with the ongoing support and guidance they
                      need to maximize their technology investment and achieve
                      their business objectives.
                    </p>
                  </div>
                  <div className="grid xl:grid-cols-1 text-left xl:mb-0 mb-8 md:ml-12 md:mt-16 xl:mt-0 lg:mt-4 xl:ml-0 ml-0">
                    <img
                      src={ourexcellence}
                      alt="Your browser does not support this content"
                      className="rounded-xl"
                    />
                  </div>
                </div>
              </div>
            )}
            <section id="award">
              {activeTab === 5 && (
                <div className="content-center xl:px-5 xl:pt-0 xl:text-xl">
                  {/* Content for Our Vision */}
                  <div className="grid grid-cols-3 gap-4 bg-white xl:mt-8 lg:mt-8 md:mt-6 mt-6">
                    <img src={startup}  className="rounded border"></img>
                    <img src={award1} className="rounded border"></img>
                    <img src={award2} className="rounded border"></img>
                  </div>
                </div>
              )}
            </section>
          </div>
        </div>

        {/* <div className="container mx-auto relative overflow-hidden xl:px-16 px-4">
          <div className="font xl:text-3xl lg:text-2xl md:text-2xl text-xl text-ivory-black font-semibold xl:mt-12 lg:mt-12 md:mt-4 xl:ml-4">
            Our Certifications
          </div>
          <div className="grid grid-cols-3 gap-4 bg-white xl:mt-8 lg:mt-8 md:mt-6 mt-6">
            <img
              src={startup}
              className="xl:mt-14 lg:mt-12 md:mt-10 mt-4"
            ></img>

            <img src={marslabdb} className="xl:ml-4 lg:ml-4 md:ml-4"></img>

            <img src={marslabml}></img>
          </div>
        </div> */}

        <About2 />
        <Footer />
      </div>
    </section>
  );
};

export default Aboutt;
