import React, { useState, useEffect } from "react";
import Footer from "components/Footers/Footer.js";
import SliderPage from "views/SliderPage";
import marshlabbannerr from "assets/img/marshlabbannerr.jpg";
import devotedprogress from "assets/img/devotedprogress.jpg";
import ourpeople from "assets/img/ourpeople.jpg";
import marslabbannermobile from "assets/img/marslabbannermobile.jpg";
import Marslabbannerdesktop from "assets/img/Marslabbannerdesktop.jpg";
import { HashLink } from "react-router-hash-link";

export default function Homepage1() {
  //Home page poop up
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
    localStorage.setItem('popupShown', 'true');
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  useEffect(() => {
    const popupShown = localStorage.getItem('popupShown');
    if (!popupShown) {
      setPopupOpen(false);
      localStorage.setItem('popupShown', 'false');
      openPopup();
    }

    return () => {
      closePopup();
    };
  }, []);

  //Home page poop up end

  //Empower animation

  function typeWriterEffect() {
    const textElement = document.getElementById("typewriterText");
    const textContent = textElement.innerHTML;
    textElement.innerHTML = "";
    let i = 0;
    function type() {
      if (i < textContent.length) {
        textElement.innerHTML += textContent.charAt(i);
        i++;
        setTimeout(type, 50);
      }
    }
    type();
  }
  window.onload = typeWriterEffect;

  //empower animation end

  /* Our team animation */
  document.addEventListener("DOMContentLoaded", function () {
    const leftGrid = document.querySelector(".left-grid");
    const rightContent = document.querySelector(".right-content");

    leftGrid.classList.add("slide-in-left");
    rightContent.classList.add("slide-in-right");
  });

  /* Our team animation ended here */

  return (
    <section>
      <div className="overflow-hidden">
        <div className="header pt-16  max-h-860-px">
          <div className="flex flex-wrap xl:block lg:block md:block hidden ">
            <section
              style={{
                backgroundImage: `url(${Marslabbannerdesktop})`,
                width: "100%",
                backgroundSize: "cover",
                // height: "720px",
              }}
              className="xl:h-720  lg:h-600 md:h-300"
            >
              <div>
                {isPopupOpen && (
                  <div className="popup-container xl:w-11/12 flex ">
                    <div className="popup-content text-semibold text-ivory-black ">
                      <div className="xl:block lg:block md:hidden hidden">
                        <p className="text-left">
                          MARSLAB takes your privacy seriously and are committed
                          to protecting your personal information responsibly.
                          This statement outlines how we collect, use, and share
                          your data when you visit our website and use our
                          services. MARSLAB values your privacy and believes in
                          transparency. This statement explains what information
                          we collect, why we use it, and your choices to control
                          it and We understand your concerns about online
                          privacy. This statement provides clear information on
                          how we handle your data and empowers you to manage
                          your choices.
                        </p>
                      </div>

                      <div className="xl:hidden lg:hidden md:block block">
                        <p className="md:ml-4">
                          MARSLAB takes your privacy seriously and are committed
                          to protecting your personal information responsibly.
                          This statement outlines how we collect, use, and share
                          your data when you visit our website and use our
                          services. MARSLAB values your privacy and believes in
                          transparency. This statement explains what information
                          we collect, why we use it, and your choices to control
                          it and We understand your concerns about online
                          privacy. This statement provides clear information on
                          how we handle your data and empowers you to manage
                          your choices.
                        </p>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="xl:pt-8 md:pt-8 ">
                        <button
                          className="popup-button1 px-4 xl:px-6"
                          onClick={closePopup}
                        >
                          <b>Agree</b>
                        </button>
                      </div>{" "}
                      <div className="xl:pt-8 md:pt-8 ">
                        <button
                          className="popup-button2 px-4"
                          onClick={closePopup}
                        >
                          <b>Disagree</b>
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="overflow-hidden">
                <div className="container mx-auto xl:px-16 px-4">
                  <div className="xl:block md:block grid grid-cols-2">
                    <div className="font flex text-white xl:pt-60  md:pt-56 pt-20">
                      <div className="xl:block lg:hidden md:hidden hidden">
                        <div
                          className="bg-white text-black"
                          style={{
                            height: "705px",
                            width: "410px",
                            marginTop: "-14rem",
                            marginLeft: "47.20rem",
                          }}
                        >
                          <div
                            className="xl:p-12"
                            style={{ position: "relative" }}
                          >
                            <p className="xl:mt-20 font xl:text-banner font-medium-next text-danger">
                              <div class="container">
                                <p class="title title1">Think</p>
                              </div>
                              <div class="container">
                                <p class="title title2">Code</p>
                              </div>
                              <div class="container">
                                <p class="title title3">Teach</p>
                              </div>
                              <div class="container">
                                <p class="title title4">Execute</p>
                              </div>
                            </p>
                          </div>

                          <div
                            className="xl:p-12 bg-grey-100"
                            style={{
                              marginTop: "2rem",
                              height: "300px",
                              zIndex: 1,
                              position: "relative",
                            }}
                          >
                            <div className="xl:mt-6 pt-4 font xl:text-lg font-thin">
                              <h1 className="swiftuptext" id="typewriterText">
                                Empower your business with the limitless
                                scalability and flexibility of a cloud server.
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Tab landscape view  */}

                      <div className="xl:hidden lg:block md:hidden hidden">
                        <div
                          className="bg-white text-black"
                          style={{
                            height: "700px",
                            width: "350px",
                            marginTop: "-14rem",
                            marginLeft: "40rem",
                          }}
                        >
                          <div className="lg:p-12">
                            <p className="xl:mt-20 lg:mt-20 font xl:text-banner lg:text-3xl font-medium-next text-danger">
                              <div class="container">
                                <p class="title title1">Think</p>
                              </div>
                              <div class="container">
                                <p class="title title2">Code</p>
                              </div>
                              <div class="container">
                                <p class="title title3">Teach</p>
                              </div>
                              <div class="container">
                                <p class="title title4">Execute</p>
                              </div>
                            </p>
                          </div>
                          <div
                            className="lg:p-12 xl:p-12 bg-grey-100 "
                            style={{
                              height: "225px",
                              zIndex: 1,
                              position: "relative",
                              marginTop: "3.5rem",
                            }}
                          >
                            <div className="xl:mt-5 pt-4 font xl:text-lg lg:text-xs">
                              <h1 className="swiftuptextlg" id="typewriterText">
                                Empower your business with the limitless
                                scalability and flexibility of a cloud server.
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div className="xl:hidden md:hidden lg:hidden block">
            <div className="grid grid-cols-2">
              <div>
                <img src={marslabbannermobile} className="h-40"></img>
              </div>
              <div>
                <p className="xl:mt-20 pt-8 text-base pl-8 font xl:text-banner font-medium-next text-danger">
                  <div class="container">
                    <p class="titlee title11">Think</p>
                  </div>
                  <div class="container">
                    <p class="titlee title22">Code</p>
                  </div>
                  <div class="container">
                    <p class="titlee title33">Teach</p>
                  </div>
                  <div class="container">
                    <p class="titlee title44">Execute</p>
                  </div>
                </p>
              </div>
            </div>

            <div
              className="p-5 bg-grey-100 md:py-16"
              style={{ zIndex: 10, position: "relative" }}
            >
              <div className="xl:mt-6 pt-4 font text-sm">
                <h1 className="swiftuptextt" id="typewriterText">
                  Empower your business with the limitless scalability and
                  flexibility of a cloud server.
                </h1>
              </div>
            </div>
          </div>

          <div className="xl:hidden lg:hidden md:block hidden">
            <div
              className="bg-white text-black"
              style={{
                // height: "690px",
                width: "260px",
                marginTop: "-19.5rem",
                marginLeft: "30rem",
              }}
            >
              <div className="xl:p-12" style={{ position: "relative" }}>
                <p className="xl:pt-20 md:pt-12 md:pl-8 font md:text-lg  xl:text-banner font-medium-next text-danger">
                  <div class="container">
                    <p class="titleee title111">Think</p>
                  </div>
                  <div class="container">
                    <p class="titleee title222">Code</p>
                  </div>
                  <div class="container">
                    <p class="titleee title333">Teach</p>
                  </div>
                  <div class="container">
                    <p class="titleee title444">Execute</p>
                  </div>
                </p>
              </div>

              <div
                className="xl:p-12 bg-grey-100"
                style={{
                  marginTop: "2rem",
                  height: "122px",
                  zIndex: 1,
                  position: "relative",
                }}
              >
                <div className="xl:pt-6 md:pt-8 pt-4 md:pl-8 md:pr-8 font xl:text-lg md:text-sm font-thin">
                  <h1 className="swiftuptexttab" id="typewriterText">
                    Empower your business with the limitless scalability and
                    flexibility of a cloud server.
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className=" container mx-auto xl:px-16 px-4 xl:pb-12 pb-4 lg:pb-12 md:pb-10"
          style={{ zIndex: 10, position: "relative" }}
        >
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 lg:gap-4 md:grid-cols-2 grid-cols-1 xl:pt-20 lg:pt-12 md:pt-12 pt-4">
            <div className="left-grid">
              <div className="font font-semibold uppercase text-ivory-black xl:text-3xl lg:text-xl text-xl md:text-xl xl:w-9/12 xl:pb-6 xl:pt-0 lg:pt-6 md:pt-0 pt-0">
                Your Trusted IT Partner for the Digital Age
              </div>
              <div className="xl:text-lg md:text-sm lg:text-base xl:mt-0 font text-ivory-black md:mt-4  mt-4 xl:pr-8">
                In today's rapidly evolving digital landscape, businesses
                require a reliable and experienced IT partner to navigate the
                complexities of technology and harness its power to achieve
                their strategic goals. At MARSLAB, we are passionate about
                empowering organizations of all sizes to embrace technology, and
                optimize their operations, and thrive in the digital era.
              </div>

              <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-4 text-ivory-black md:gap-4 md:pt-4 pt-4 lg:pt-6 xl:mt-8 xl:mb-8 ">
                <a href="about-us">
                  <div className="xl:redd lg:redd font">
                    Explore the MarsLab 〉
                  </div>
                </a>
                <a href="contact">
                  <div className="xl:redd lg:redd font">
                    Talk to Our Experts 〉
                  </div>
                </a>
              </div>
            </div>

            <div className="right-content font text-lg pt-4 lg:pt-0 md:pt-2 xl:pt-0 xl:pl-8">
              <img
                src="https://i.ibb.co/HBYwNL2/resources2.jpg"
                className="rounded-md "
              ></img>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap xl:block md:block hidden">
          <div
            style={{
              backgroundImage: `url(${marshlabbannerr})`,
              width: "100%",
            }}
            className="xl:h-475 lg:h-380 md:h-380"
          >
            <div className="container mx-auto relative overflow-hidden xl:px-16 px-4">
              <div className="xl:block md:block grid grid-cols-2">
                <div className="xl:w-12/12 font flex text-white xl:pt-18 md:pt-12 pt-20">
                  <div className="bg-white p-8  rounded-md">
                    <p className="xl:text-3xl md:text-xl text-ivory-black pb-2">
                      Our services
                    </p>
                    <div className="ftDropDown pb-5"></div>
                    <p className="font xl:text-5xl md:text-3xl text-ivory-black xl:pt-4 md:pt-3 ">
                      Marslab Cloud <br></br>Services empower you to
                    </p>
                    <p className="font xl:text-lg text-black xl:pt-4 md:pt-4 ">
                      In today's dynamic digital landscape, organizations are{" "}
                      <br></br>
                      seeking to harness the power of cloud computing to drive
                      <br></br>
                      innovation, and optimize operations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-ivory-black">
          <div className="container mx-auto relative overflow-hidden xl:px-12 px-4 ">
            <div className="xl:py-16 md:py-16 py-8 ">
              <p className="font text-white font-semibold xl:text-4xl lg:text-3xl md:text-4xl text-xl font-square">
                What our happy customer says
              </p>
              <p className="font xl:w-7/12 text-white xl:text-lg text-sm xl:pt-8 md:pt-8 pt-4 pb-4 ">
                Our experts work seamlessly with our customers' team to support
                big ambitions, challenge preconceptions and amplify outcomes.
                Let's advance together.
              </p>
            </div>
          </div>
        </div>

        <div className="xl:p-16 xl:-mt-10 md:-mt-10 -mt-8">
          <SliderPage />
        </div>

        <div className="container mx-auto relative overflow-hidden">
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 xl:py-0 xl:pb-8 md:pb-0 lg:pb-0 pb-0  md:py-8 px-4">
            <div className="xl:block md:block lg:block">
              <div className="font xl:p-12 lg:p-12 md:p-4">
                <img
                  src={devotedprogress}
                  // style={{ height: "450px", width: "450px" }}
                  className="xl:h-500 xl:w-650 rounded-xl"
                ></img>
              </div>
            </div>

            <div className="font">
              <div className="xl:p-12 lg:p-12 xl:pt-20 md:pt-4">
                <p className="xl:text-3xl md:text-xl text-black pb-2 pt-8 lg:pt-16">
                  Dedicated to Progress
                </p>

                <div className="ftDropDown pb-5"></div>
                <div className="xl:block md:block block">
                  <p className="font xl:text-5xl md:text-2xl text-3xl text-black xl:pt-4 md:pt-4 pt-4">
                    Collaborative Ventures and Partnerships
                  </p>
                </div>
                <div className="font xl:text-lg md:text-base xl:pt-8 md:pt-4 pt-4 xl:pb-0 md:pb-0 pb-8">
                  Leverage our profound expertise in complex IT landscapes to
                  formulate an optimal cloud strategy, streamline your IT
                  framework, and attain your envisioned business goals.
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="bg-blueGray-200">
          <div className="container mx-auto relative overflow-hidden  xl:px-16  px-4">
            <div className="grid xl:grid-cols-2 md:grid-cols-2 xl:w-12/12 xl:gap-4 xl:pt-8 pb-4 pt-4 md:gap-4 gap-4 xl:pb-12 md:mb-8">
              <div className="bg-white xl:p-12 md:p-5 p-5">
                <img src="https://i.ibb.co/cbCFXTn/lady.jpg "></img>
                <p className="font xl:text-2xl md:text-2xl text-xl md:pt-6 xl:pt-8 pt-4">
                  Become a Kndryl
                </p>
                <p className="font xl:text-base xl:py-8 md:py-8 py-4">
                  Growth-oriented, open-minded thinker that thrives on change?
                  You belong at Kyndryl.
                </p>
                <a href="#" className="link">
                  Join us ➙
                </a>
              </div>
              <div className="bg-white xl:p-12 md:p-5 p-5">
                <img src="https://i.ibb.co/2t04hL7/groupdiscussion.jpg"></img>
                <p className="font xl:text-2xl md:text-2xl text-xl md:pt-6 xl:pt-8 pt-4">
                  Kyndryl news
                </p>
                <p className="font xl:text-base xl:pt-8 xl:pb-12  md:py-8 py-4">
                  Check out the latest news from the heart of human progress.
                </p>
                <a href="#" className="link">
                  Read more ➙
                </a>
              </div>
            </div>
          </div>
        </div> */}

        <div
          style={{
            backgroundImage: `url(${marshlabbannerr})`,
            width: "100%",
            backgroundSize: "cover",
          }}
          className="xl:h-68new md:h-80"
        >
          <div className="container mx-auto relative overflow-hidden xl:px-16 px-4">
            <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 xl:pt-4">
              <div className="xl:block md:block lg:block block ">
                <div className="font mt-8 xl:mt-2 md:mt-2 lg:mt-4 lg:mb-12 xl:mt-16 lg:p-10 xl:p-0 md:p-6 xl:pb-0 md:pb-12 lg:pb-0 pb-0">
                  <img
                    src={ourpeople}
                    className="xl:h-360 md:h-240 md:w-680 lg:h-330 lg:w-680 rounded-xl"
                  ></img>
                </div>
              </div>

              <div className="font">
                <div className="xl:p-12 lg:p-12">
                  <div className="xl:block md:hidden ">
                    <p className="font xl:text-5xl text-3xl text-white xl:pt-9 lg:pt-8 pt-4">
                      Our Members. <br></br> Your Achievements.
                    </p>
                  </div>
                  <div className="xl:hidden md:block hidden">
                    <p className="font md:text-2xl text-white md:pt-14">
                      Our Members. Your Achievements.
                    </p>
                  </div>
                  <div className="xl:block md:block block">
                    <p className="font xl:text-lg md:text-base md:pt-4 text-white xl:pt-8 pt-4">
                      Our people are the core of all we do and our greatest
                      asset. They collaborate with clients to identify
                      underlying issues and jointly develop fresh, more clever
                      solutions.
                    </p>
                  </div>
                  <div className="xl:my-0 md:my-0 my-6">
                    <HashLink smooth to="/contact#enquiry">
                      <a href="contact">
                        <button className="xl:buttonnewshade1 md:buttonnewshade1 buttonnewshade1">
                          Talk to an Expert ➙
                        </button>
                      </a>
                    </HashLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </section>
  );
}
