import React, { FC, useState, Fragment, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import dropdown from "assets/img/dropdown.png";
import { createPopper } from "@popperjs/core";
import { HashLink } from "react-router-hash-link";

export default function IndexNavbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const popoverDropdownRef = React.createRef();
  const btnDropdownRef = React.createRef();

  const [showFirstMenu, setShowFirstMenu] = useState(false);
  const handleFirstMenuHover = () => {
    setShowFirstMenu(true);
  };

  const handlePackagesMenuLeave = () => {
    setShowFirstMenu(false);
  };

  const [showSecondMenu, setShowSecondMenu] = useState(false);
  const handleSecondMenuHoverr = () => {
    setShowSecondMenu(true);
  };

  const handlePackagesMenuLeavee = () => {
    setShowSecondMenu(false);
  };

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const [isDropdownOpenn, setDropdownOpenn] = useState(false);
  const handleDropdownTogglee = () => {
    setDropdownOpenn(!isDropdownOpenn);
  };

  useEffect(() => {
    if (isDropdownVisible) {
      createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
        placement: "bottom-start",
      });
    }
  }, [isDropdownVisible]);

  return (
    <section className="">
      <nav className="fixed z-50 top-0 left-0 right-0 w-full xl:pb-3 navbar-expand-lg bg-white shadow">
        <div className="container mx-auto xl:px-16 px-4 overflow-hidden">
          <div
            className={
              "lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none py-3 xl:pt-1 xl:py-0" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-warning"
          >
            <div className="px-0">
              <div className="w-full relative flex justify-between lg:w-auto lg:static lg:justify-start">
                <div className="pr-4 xl:ml-0">
                  <span>
                    <a href="/">
                      <img
                        src="https://i.ibb.co/3YyXpNW/marslablogo.png"
                        alt=""
                        className="xl:h-16 lg:w-auto xl:w-70 md:w-48 h-12 w-48 xl:pt-2 lg:pt-0 lg:mt-0 mt-8 xl:mt-0 cursor-pointer pr-0 lg:pr-0 md:pr-0 xl:pr-0"
                      />
                    </a>
                  </span>
                </div>
                <button
                  className="cursor-pointer text-xs leading-none block lg:hidden outline-none focus:outline-none"
                  type="button"
                  onClick={() => setNavbarOpen(!navbarOpen)}
                >
                  ✖
                </button>
              </div>
            </div>

            {/* Mobile and tab view */}

            <div className="xl:hidden lg:hidden md:block block">
              <span
                className="flex-1 flex justify-between my-3 md:pt-3 pt-4"
                onClick={handleDropdownToggle}
              >
                <h3
                  className="md:border-b-long xl:border-b-none font lg:border-b-none pb-0 md:pb-4 font-medium uppercase text-dark-blue text-base pl-2"
                  onClick={handleDropdownToggle}
                >
                  <a href="/solution-homepage">Services</a>
                </h3>
                <img
                  src={dropdown}
                  className="w-4 h-4"
                  alt="dropdown icon"
                  onClick={handleDropdownToggle}
                />
              </span>

              {isDropdownOpen && (
                <div className="dropdown-content">
                  <div className="">
                    <div className="font xl:p-4 pb-0 pl-4 md:pl-8 md:p-0 p-0 xl:w-11/12">
                      <a href="/cyber-security">
                        <p className="font xl:text-sm text-base font-medium text-light-blue xl:pt-4 md:pt-4 lg:pt-14 pt-4">
                          Cyber Security
                        </p>
                      </a>

                      <a href="/cloud-engineering">
                        <p className="font xl:text-sm text-base font-medium text-light-blue xl:pt-0 md:pt-2 lg:pt-14 pt-2">
                          Cloud Engineering
                        </p>
                      </a>

                      <a href="/data-engineering">
                        <p className="font xl:text-sm text-base font-medium text-light-blue  xl:pt-0 md:pt-2 lg:pt-14 pt-2">
                          Data Engineering
                        </p>
                      </a>

                      <a href="/ai-engineering">
                        <p className="font xl:text-sm text-base font-medium text-light-blue xl:pt-0 md:pt-2 lg:pt-14 pt-2">
                          AI Engineering
                        </p>
                      </a>

                      <a href="/infrastructure-engineering">
                        <p className="font xl:text-sm text-base font-medium text-light-blue xl:pt-0 md:pt-2 lg:pt-14 pt-2">
                          Infrastructure Engineering
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="xl:hidden lg:hidden md:block block">
              <span
                className="flex-1 flex justify-between my-3 md:pt-3 pt-4"
                onClick={handleDropdownTogglee}
              >
                <h3
                  className="md:border-b-long xl:border-b-none font lg:border-b-none pb-0 md:pb-4 font-medium uppercase text-dark-blue text-base pl-2"
                  onClick={handleDropdownTogglee}
                >
                  <a href="/solution-homepage">About Us</a>
                </h3>
                <img
                  src={dropdown}
                  className="w-4 h-4"
                  alt="dropdown icon"
                  onClick={handleDropdownTogglee}
                />
              </span>

              {isDropdownOpenn && (
                <div className="dropdown-content">
                  <div className="">
                    <div className="font xl:p-4 pb-0 pl-4 md:pl-8 md:p-0 p-0 xl:w-11/12">
                      <a href="/about-us">
                        <p className="font xl:text-sm text-base font-medium text-light-blue xl:pt-4 md:pt-4 lg:pt-14 pt-4">
                          Our Compnay
                        </p>
                      </a>

                      <a href="/whymarslab">
                        <p className="font xl:text-sm text-base font-medium text-light-blue xl:pt-0 md:pt-2 lg:pt-14 pt-2">
                          Why Marslab
                        </p>
                      </a>

                      <a href="/team">
                        <p className="font xl:text-sm text-base font-medium text-light-blue  xl:pt-0 md:pt-2 lg:pt-14 pt-2">
                          Our Team
                        </p>
                      </a>

                      <a href="/careers">
                        <p className="font xl:text-sm text-base font-medium text-light-blue  xl:pt-0 md:pt-2 lg:pt-14 pt-2">
                          Careers
                        </p>
                      </a>

                      <a href="/about-us">
                        <p className="font xl:text-sm text-base font-medium text-light-blue  xl:pt-0 md:pt-2 lg:pt-14 pt-2">
                          Certification & Recognition
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="xl:block lg:block md:hidden sm:hidden hidden">
              <ul className="flex flex-col lg:flex-row list-none mr-auto xl:ml-12 xl:pt-4  lg:ml-12">
                <div>
                  <a href="/">
                    <p className="pr-1 red-on-hover cursor-pointer xl:text-base1 md:pl-0 pl-0 xl:pl-5 xl:pr-6 md:pb-3 pb-0 xl:pb-0 lg:pb-0 lg:pl-0 md:pt-2 pt-4 xl:pt-7 lg:pt-0 lg:pr-4 xl:pr-0 pr-r md:pr-0 text-base font text-dark-blue">
                      Home
                    </p>
                  </a>
                </div>
                <div className="menu navbar-links">
                  <div
                    className="menu-item"
                    onMouseEnter={handleFirstMenuHover}
                    onMouseLeave={handlePackagesMenuLeave}
                  >
                    <div className="items-center flex text-sky-800 text-sm sm:text-base font-medium pr-4 xl:pr-6 relative cursor-pointer">
                      <h1 className="navbar-dropdown red-on-hover pr-1 cursor-pointer xl:text-base1 lg:text-base font xl:pt-7 ">
                        <a href="/">Services</a>
                        <span className="ml-1">
                          <i class="fa fa-angle-down" aria-hidden="true"></i>
                        </span>
                      </h1>
                    </div>
                    <div className="fixed xl:top-14 lg:top-20 cursor-pointer">
                      {showFirstMenu && (
                        <div className="menu-dropdown bg-white rounded-md shadow-xl xl:mt-7 lg:mt-6 xl:mr-0 lg:mr-40 xl:-ml-24 animate-slide-up">
                          <div className="flex">
                            <div className="grid xl:gri-cols-2 lg:grid-cols-2 xl:gap-0 lg:gap-0 xl:pl-4 lg:pl-4">
                              <div className="flex items-center xl:w-12/12 xl:pl-1 xl:pt-0">
                                <div className="xl:w-2/12">
                                  <img
                                    src="https://i.ibb.co/FWwLbBP/cyber.png"
                                    className="w-8 xl:pt-1 xl:ml-2 lg:pt-4 lg:pr-2 xl:pr-0 xl:pt-0"
                                  ></img>
                                </div>
                                <div className="xl:w-9/12">
                                  <a
                                    href="/cyber-security"
                                    className="pl-3 xl:w-10/12 red-on-hover text-light-blue xl:pl-3"
                                  >
                                    <p className="xl:text-lg lg:text-sm font text-lg font-medium xl:pt-0 md:pt-4 lg:pt-0 pt-4">
                                      Cyber Security
                                    </p>
                                    <p className="xl:text-xs text-xs font">
                                      Cybersecurity protects digital systems and
                                      data from cyber threats.
                                    </p>
                                  </a>
                                </div>
                              </div>

                              <div className="flex items-center xl:w-12/12 xl:pl-1 xl:pt-2">
                                <div className="xl:w-2/12">
                                  <img
                                    src="https://i.ibb.co/kHLsBZ9/dataengineering.png"
                                    className="w-8 xl:pt-1 xl:ml-2  lg:pt-4 lg:pr-2 xl:pr-0 xl:pt-0"
                                  ></img>
                                </div>
                                <div className="xl:w-9/12">
                                  <a
                                    href="/data-engineering"
                                    className="pl-3 xl:w-10/12 red-on-hover text-light-blue xl:pl-8 lg:pl-4"
                                  >
                                    <p className="xl:text-lg lg:text-sm font text-lg font-medium xl:pt-0 md:pt-4 lg:pt-0 pt-4">
                                      Data Engineering
                                    </p>
                                    <p className="xl:text-xs text-xs font">
                                      Data engineering designs and optimizes
                                      systems <br></br>for collecting, storing,
                                      and processing data efficiently.
                                    </p>
                                  </a>
                                </div>
                              </div>

                              <div className="flex items-center xl:w-12/12 xl:pl-1 xl:pt-2">
                                <div className="xl:w-2/12">
                                  <img
                                    src="https://i.ibb.co/4PzkRdc/cloudengineering.png"
                                    className="w-8 xl:pt-1 xl:ml-2  lg:pt-4 lg:pr-2 xl:pr-0 xl:pt-0"
                                  ></img>
                                </div>
                                <div className="xl:w-9/12">
                                  <a
                                    href="/cloud-engineering"
                                    className="pl-3 xl:w-10/12 red-on-hover text-light-blue xl:pl-8 lg:pl-4"
                                  >
                                    <p className="xl:text-lg lg:text-sm font text-lg font-medium xl:pt-0 md:pt-4 lg:pt-0 pt-4">
                                      Cloud Engineering
                                    </p>
                                    <p className="xl:text-xs text-xs font">
                                      Cloud engineering creates and manages
                                      scalable <br></br>digital infrastructure
                                      in the cloud.
                                    </p>
                                  </a>
                                </div>
                              </div>

                              <div className="flex items-center xl:w-12/12 xl:pl-1 xl:pt-2">
                                <div className="xl:w-2/12">
                                  <img
                                    src="https://i.ibb.co/89bNf2v/aiengineering.png"
                                    className="w-8 xl:pt-1 xl:ml-2 lg:pt-4 lg:pr-2 xl:pr-0 xl:pt-0"
                                  ></img>
                                </div>
                                <div className="xl:w-9/12">
                                  <a
                                    href="/ai-engineering"
                                    className="pl-3 xl:w-10/12 red-on-hover text-light-blue xl:pl-8 lg:pl-4"
                                  >
                                    <p className="xl:text-lg lg:text-sm font text-lg font-medium xl:pt-0 md:pt-4 lg:pt-0 pt-4">
                                      AI Engineering
                                    </p>
                                    <p className="xl:text-xs text-xs font">
                                      AI engineering develops and implements{" "}
                                      <br></br> artificial intelligence
                                      solutions for various applications.
                                    </p>
                                  </a>
                                </div>
                              </div>

                              <div className="flex items-center xl:w-12/12 xl:pl-1 xl:pt-2 xl:pb-8 lg:pb-4">
                                <div className="xl:w-2/12">
                                  <img
                                    src="https://i.ibb.co/TRR5Hzf/infrastructureengineering.png"
                                    className="w-8 xl:pt-1 xl:ml-2 lg:pt-4 lg:pr-2 xl:pr-0 xl:pt-0"
                                  ></img>
                                </div>
                                <div className="xl:w-9/12">
                                  <a
                                    href="/infrastructure-engineering"
                                    className="pl-3 xl:w-10/12 red-on-hover text-light-blue xl:pl-8 lg:pl-4"
                                  >
                                    <p className="xl:text-lg lg:text-sm font text-lg font-medium xl:pt-0 md:pt-4 lg:pt-0 pt-4">
                                      Infrastructure Engineering
                                    </p>
                                    <p className="xl:text-xs text-xs font">
                                      Infrastructure engineering manages vital
                                      digital<br></br> systems for operations.
                                    </p>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* Desktop and tab landscape view */}
                <div className="menu navbar-links">
                  <div
                    className="menu-item"
                    onMouseEnter={handleSecondMenuHoverr}
                    onMouseLeave={handlePackagesMenuLeavee}
                  >
                    <div className="items-center flex text-sky-800 text-sm sm:text-base font-medium pr-4 xl:pr-6 relative cursor-pointer">
                      <h1 className="navbar-dropdown red-on-hover pr-1 cursor-pointer xl:text-base1 lg:text-base font xl:pt-7 ">
                        <a href="/">About Us</a>
                        <span className="ml-1">
                          <i class="fa fa-angle-down" aria-hidden="true"></i>
                        </span>
                      </h1>
                    </div>
                    <div className="fixed xl:top-14 lg:top-20 cursor-pointer xl:w-33/12">
                      {showSecondMenu && (
                        <div className="menu-dropdown bg-white rounded-md shadow-xl xl:mt-7 xl:-ml-8 animate-slide-up">
                          <div className="flex">
                            <div className="xl:w-12/12 xl:p-4 lg:p-4 xl:pl-4">
                              <div className=" xl:pt-3 lg:pl-10 lg:pt-4">
                                <div className="flex">
                                  <div className="xl:w-2/12">
                                    <img
                                      src="https://i.ibb.co/L5D7sDY/business-13089655.png"
                                      className="w-9 xl:pt-1 xl:ml-1"
                                    ></img>
                                  </div>
                                  <div className="xl:pl-2 xl:pt-1 lg:pl-3 lg:pt-1">
                                    <a href="/about-us" className="">
                                      <p className="red-on-hover xl:text-lg font text-lg font-medium xl:pt-0 md:pt-4 lg:pt-0 pt-4">
                                        Our Company
                                      </p>
                                    </a>
                                  </div>
                                </div>

                                <div className="flex xl:pt-4 lg:pt-4">
                                  <div className="xl:w-2/12">
                                    <img
                                      src="https://i.ibb.co/vsSs0xV/help-656891.png"
                                      className="w-7 xl:pt-1 xl:ml-2"
                                    ></img>
                                  </div>
                                  <div className="xl:pl-2 xl:pt-1 lg:pl-3 lg:pt-1">
                                    <a href="/whymarslab" className="">
                                      <p className="red-on-hover xl:text-lg font text-lg font-medium xl:pt-0 md:pt-4 lg:pt-0 pt-4">
                                        Why Marslab
                                      </p>
                                    </a>
                                  </div>
                                </div>
                                <div className="flex xl:pt-4 lg:pt-4 ">
                                  <div className="xl:w-2/12">
                                    <img
                                      src="https://i.ibb.co/DGNnrsn/group-13335827.png"
                                      className="w-8 xl:pt-1 xl:ml-2"
                                    ></img>
                                  </div>
                                  <div className="xl:pl-2 xl:pt-1 lg:pl-3 lg:pt-1">
                                    <a href="/team" className="">
                                      <p className="red-on-hover xl:text-lg font text-lg font-medium xl:pt-0 md:pt-4 lg:pt-0 pt-4">
                                        Our Team
                                      </p>
                                    </a>
                                  </div>
                                </div>

                                <div className="flex xl:pt-4 lg:pt-4 xl:pl-1">
                                  <div className="xl:w-2/12">
                                    <img
                                      src="https://i.ibb.co/TBrRh0z/research-7209340.jpg"
                                      className="w-8 xl:pt-1 xl:ml-2"
                                    ></img>
                                  </div>
                                  <div className="xl:pl-2 xl:pt-1 lg:pl-3 lg:pt-1">
                                    <a href="/careers" className="">
                                      <p className="red-on-hover xl:text-lg font text-lg font-medium xl:pt-0 md:pt-4 lg:pt-0 pt-4">
                                        Careers
                                      </p>
                                    </a>{" "}
                                  </div>
                                </div>

                                <div className="flex xl:pt-4 lg:pt-4 xl:pb-2 lg:pb-2">
                                  <div className="xl:w-2/12">
                                    <img
                                      src="https://i.ibb.co/3TBYbw7/award.png"
                                      className="w-8 xl:pt-1 xl:ml-3"
                                    ></img>
                                  </div>
                                  <div className="xl:pl-3 xl:pt-2 lg:pl-3 lg:pt-1">
                                    <HashLink smooth to="/about-us#award">
                                      <a href="/about-us" className="">
                                        <p className="red-on-hover xl:text-lg font text-lg font-medium xl:pt-0 md:pt-4 lg:pt-0 pt-4">
                                          Certification & Recognition
                                        </p>
                                      </a>
                                    </HashLink>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="xl:block lg:block md:hidden hidden">
                  <a href="/contact">
                    <p className="pr-1 cursor-pointer red-on-hover xl:text-base1 md:pl-0 pl-0 xl:pl-5 xl:pr-4 md:pb-3 pb-0 xl:pb-0 lg:pb-0 lg:pl-0 md:pt-2 pt-4 xl:pt-7 lg:pt-0 lg:pr-4 xl:pr-0 pr-r md:pr-0 text-base font text-dark-blue">
                      Contact Us
                    </p>
                  </a>
                </div>
              </ul>
            </div>
            <div className="xl:block lg:block md:hidden hidden sm:hidden">
              <div className="xl:ml-80 lg:ml-81">
                {/* <a>
                  <button className="border-blue bg-white font-semibold px-8 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-2 xl:px-8 xl:py-1 md:px-8 md:mt-12 md:py-1 border rounded-lg text-blue-new md:ml-6 ml-2 xl:ml-0 lg:-ml-3">
                    Sign in
                  </button>
                </a> */}

                <HashLink smooth to="/contact#enquiry">
                  <button class="lg:buttonWithGradient xl:buttonWithGradient md:buttonWithGradient buttonWithGradient font-semibold px-4 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-2  md:px-4 md:mt-12 md:py-1 border rounded-lg red-new text-white md:ml-6 ml-2 xl:ml-0 lg:-ml-3">
                    Enquiry
                  </button>
                </HashLink>
              </div>
            </div>

            {/* Mobile view and tab view  */}
            {/* <div className="xl:hidden  lg:hidden md:block block">
              <a href="/about-us">
                <p className="md:border-b-longg red-on-hover xl:border-b-none lg:border-b-none cursor-pointer md:pl-0 pl-2 xl:pl-0 md:pb-3 pb-0 xl:pb-0 lg:pb-0 lg:pl-0 md:pt-4 pt-5 xl:pt-0 lg:pt-0 font text-base uppercase text-dark-blue">
                  About Us
                </p>
              </a>
            </div> */}
            <div className="xl:hidden lg:hidden md:block block">
              <a href="/contact">
                <p className="md:border-b-longg xl:border-b-none lg:border-b-none cursor-pointer md:pl-6 pl-2 xl:pl-0 md:pb-3 pb-0 xl:pb-0 lg:pb-0 lg:pl-0 md:pt-4 pt-5 xl:pt-0 lg:pt-0  text-base font  uppercase text-dark-blue">
                  Contact Us
                </p>
              </a>
            </div>

            <div className="xl:hidden lg:hidden md:block block sm:block">
              <HashLink smooth to="/contact#enquiry">
                <button class="lg:buttonWithGradient xl:buttonWithGradient md:buttonWithGradient buttonWithGradient font-semibold px-4 py-1 mt-7 xl:mt-4 md:mt-4 xl:mt-1 lg:mt-2  md:px-4 md:mt-12 md:py-1 border rounded-lg red-new text-white md:ml-6 ml-2 xl:ml-0 lg:-ml-3">
                  Enquiry
                </button>
              </HashLink>
            </div>

            <div className="xl:hidden lg:hidden md:block block">
              <div className="mt-32 pt-8 border-t xl:pl-4 md:pl-4 pb-56 pl-0">
                <a href="https://www.facebook.com/marslabintel">
                  <button
                    className="bg-danger text-white shadow-lg font-normal  h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                    href=""
                  >
                    <i className="fab fa-facebook"></i>
                  </button>
                </a>

                <a href="https://twitter.com/Marslab_intell">
                  <button
                    className="bg-danger text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                    href="twitter"
                  >
                    <i className="">𝕏</i>
                  </button>
                </a>

                {/* <button
                  className="bg-black text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-lg outline-none focus:outline-none mr-2"
                  type="button"
                  href="Google"
                >
                  <i class="fab fa-google-plus"></i>
                </button> */}

                <a href="https://www.youtube.com/@MarslabIntelligence">
                  <button
                    className="bg-danger text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                    href="youtube"
                  >
                    <i className="fab fa-youtube"></i>
                  </button>
                </a>

                <a href="https://www.linkedin.com/company/marslab-intelligence-private-limited/">
                  <button
                    className="bg-danger text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                    href="youtube"
                  >
                    <i className="fab fa-linkedin"></i>
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div className="xl:hidden lg:hidden md:block sm:block block md:py-4 py-4">
            <div className="px-0">
              <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
                <div className={`px-0 ${navbarOpen ? "hidden" : ""}`}>
                  <span>
                    <a href="index">
                      <img
                        src="https://i.ibb.co/3YyXpNW/marslablogo.png"
                        alt=""
                        className="xl:h-12 xl:w-52 md:h-45 md:w-48 lg:h-12 h-10 w-48 cursor-pointer pr-0 md:pr-0"
                      />
                    </a>
                  </span>
                </div>
                <button
                  className="cursor-pointer text-xl leading-none px-3 py-1 border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                  type="button"
                  onClick={() => setNavbarOpen(!navbarOpen)}
                >
                  {navbarOpen ? (
                    <i className=""></i>
                  ) : (
                    <i className="fas fa-bars"></i>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </section>
  );
}
