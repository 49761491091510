import React, { useState } from "react";
import Footer from "components/Footers/Footer";
import IndexNavbar from "components/Navbars/IndexNavbar";
import ApplicationContent2 from "components/Dropdowns/ApplicationContent2";
import ApplicationContent3 from "components/Dropdowns/ApplicationContent3";
import marshlabbannerr from "assets/img/marshlabbannerr.jpg";
import mission from "assets/img/icons/mission.png";
import customer from "assets/img/icons/customer.png";
import success from "assets/img/icons/success.png";
import sharedvision from "assets/img/icons/shared-vision.png";
import atworkhappyteamwithlaptop from "assets/img/images/atwork-happyteamwithlaptop.jpg";
import vision from "assets/img/images/vision.png";
import mission1 from "assets/img/images/mission1.png";

const Cyber = () => {
  const totalSteps = 3;
  const [currentStep, setCurrentStep] = useState(1);

  const handleNext = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrev = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const stepsContent = [
    {
      title: "Our Mission",
      content: (
        <div>
          <p>
            We are committed to providing comprehensive IT solutions that align
            with our customers' unique needs and aspirations. We strive to:
          </p>
        </div>
      ),
    },
    {
      title: "Application Security Audit Frameworks",
      content: (
        <div>
          <p>
            To be the premier IT solutions provider, recognized for our
            exceptional expertise, unwavering commitment to customer success,
            and dedication to ethical practices.
          </p>
        </div>
      ),
    },
    {
      title: "Our Values",
      content: (
        <div>
          <p>
            Our core values guide our every action and decision, ensuring that
            we remain a trusted partner for our customers:
          </p>
        </div>
      ),
    },
    {
      title: "Excellence",
      content: (
        <div>
          <p>
            Our core values guide our every action and decision, ensuring that
            we remain a trusted partner for our customers:
          </p>
        </div>
      ),
    },
  ];

  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <section>
      <IndexNavbar />
      <div className="xl:mt-20 lg:mt-18 mt-16">
        <div className="bg-stone-200 ">
          <div className="container mx-auto relative text-danger  overflow-hidden xl:px-16 px-4 xl:py-4 lg:py-4 py-4">
            <a href="index" className="link text-ivory-black">
              Home {"  "} {/* space purpose */}
            </a>
            {"  "}― {"  "}
            <a href="aboutt" className="link">
              Services {"  "}
            </a>
            {"  "} ― {"  "}
            Cyber Security
          </div>
        </div>
      </div>

      {/* section */}

      <div className="">
        <div className="xl:block lg:block md:block block font">
          <div
            style={{
              backgroundImage: `url(${marshlabbannerr})`,
              width: "100%",
            }}
            className="bg-ivory-black xl:h-80 md:h-60 h-48 relative"
          >
            <p className="font uppercase xl:text-5xl md:text-3xl text-xl text-white text-center xl:pt-32 md:pt-28  pt-20 font-semibold">
              Cyber Security
            </p>
          </div>
        </div>
      </div>

      {/* section */}

      <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12">
        <div className="grid xl:flex xl:w-full lg:flex lg:w-full md:grid-cols-1 grid-cols-1 xl:gap-2 xl:pt-8 pt-4 lg:pt-0 md:pt-2">
          <div className="font xl:text-3xl lg:text-3xl md:text-3xl text-2xl font-semibold xl:w-4/12 lg:w-4/12 xl:pt-6 md:pt-6">
            Why Cyber Security is important to your Business?
            <p className="xl:pt-4 xl:text-base text-base pt-1 font-thin">
              Cybersecurity is important to business for several reasons,
              including
            </p>
          </div>

          <div className="xl:w-8/12 lg:w-8/12 pl-3">
            <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 md:gap-2 xl:gap-2">
              <div className="pt-8">
                <img
                  src="https://i.ibb.co/47Hb4P2/protectsensitivedata.png"
                  className="w-10"
                ></img>
                <h5 className="xl:text-lg text-lg font-semibold xl:py-1 font">
                  Protect Sensitive Data
                </h5>
                <div className="font xl:text-base lg:text-lg md:text-lg text-lg md:py-4 lg:py-0 py-4 xl:py-0">
                  Businesses store a lot of sensitive data, such as customer
                  information, financial data, and intellectual property.
                  Cybersecurity helps to protect this data from unauthorized
                  access, disclosure, modification, or destruction.
                </div>
              </div>

              <div className="xl:pt-8 md:pt-8  pt-4 xl:pl-2 pl-0 md:pl-2 lg:pl-2">
                <img
                  src="https://i.ibb.co/18FL7KS/businesscontinuity.png"
                  className="w-10"
                ></img>
                <h5 className="xl:text-lg text-lg font-semibold xl:py-1 font">
                  Maintain Business Continuity
                </h5>
                <div className="font xl:text-base lg:text-lg md:text-lg text-lg md:py-4 lg:py-0 py-4 xl:py-0">
                  A cyberattack can disrupt a business's operations, leading to
                  lost revenue and productivity. Cybersecurity helps to maintain
                  business continuity by protecting systems and data from
                  cyberattacks.
                </div>
              </div>
            </div>

            <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-2 xl:gap-2">
              <div className="xl:pt-12 pt-4">
                <img
                  src="https://i.ibb.co/3czxKs5/legalregulations.png"
                  className="w-10"
                ></img>
                <h5 className="xl:text-lg text-lg font-semibold xl:py-1 font">
                  Comply with Regulations
                </h5>
                <div className="font xl:text-base lg:text-lg md:text-lg text-lg md:py-4 lg:py-0 py-4 xl:py-0">
                  Many industries have regulations that require businesses to
                  protect their data and systems. Cybersecurity helps businesses
                  to comply with these regulations.
                </div>
              </div>

              <div className="xl:pt-12 pt-4 xl:pl-2 pl-0 md:pl-2 lg:pl-2">
                <img
                  src="https://i.ibb.co/y8x0rLp/customertrust.png"
                  className="w-10"
                ></img>
                <h5 className="xl:text-lg text-lg font-semibold xl:py-1 font">
                  Maintain Customer Trust
                </h5>
                <div className="font xl:text-base lg:text-lg md:text-lg text-lg md:py-4 lg:py-0 py-4 xl:py-0">
                  Customers expect businesses to protect their data. A
                  cyberattack can damage a business's reputation and lead to
                  lost customers. Cybersecurity helps businesses to maintain
                  customer trust by protecting their data.
                </div>
              </div>
            </div>

            <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-2 xl:gap-2">
              <div className="xl:pt-12 pt-4">
                <img
                  src="https://i.ibb.co/Z1Qhcpd/databreaches.png"
                  className="w-10"
                ></img>
                <h5 className="xl:text-lg text-lg font-semibold xl:py-1 font">
                  Data Breaches
                </h5>
                <div className="font xl:text-base lg:text-lg md:text-lg text-lg md:py-4 lg:py-0 py-4 xl:py-0">
                  A data breach is when unauthorized individuals gain access to
                  sensitive data. Data breaches can be very costly for
                  businesses, both in terms of financial losses and reputational
                  damage.
                </div>
              </div>

              <div className="xl:pt-12 pt-4 xl:pl-2 pl-0 md:pl-2 lg:pl-2">
                <img
                  src="https://i.ibb.co/HtnL4sq/ransomwareattack.png"
                  className="w-10"
                ></img>
                <h5 className="xl:text-lg text-lg font-semibold xl:py-1 font">
                  Ransomware Attacks
                </h5>
                <div className="font xl:text-base lg:text-lg md:text-lg text-lg md:py-4 lg:py-0 py-4 xl:py-0">
                  Ransomware attacks involve attackers encrypting a business's
                  data and demanding a ransom payment to decrypt it. Ransomware
                  attacks can be very disruptive and costly for businesses.
                </div>
              </div>
            </div>

            <div className="grid xl:grid-cols-2 xl:gap-2">
              <div className="xl:pt-12 pt-4 xl:pl-2 pl-0 md:pl-2 lg:pl-2">
                <img
                  src="https://i.ibb.co/wcD78yF/serviceattacks.png"
                  className="w-10"
                ></img>
                <h5 className="xl:text-lg text-lg font-semibold xl:py-1 font">
                  Denial-Of-Service Attack
                </h5>
                <div className="font xl:text-base lg:text-lg md:text-lg text-lg md:py-4 lg:py-0 py-0 xl:py-0">
                  A denial-of-service attack is when an attacker floods a
                  business's website or server with traffic, making it
                  unavailable to legitimate users. Denial-of-service attacks can
                  be very costly for businesses, especially those that rely on
                  online sales or services.
                </div>
              </div>
              <div className="pt-12 pl-2"></div>
            </div>
          </div>
        </div>
      </div>

      {/* What is VAPT */}

      <div className="bg-gray-50">
        <div className="container  mx-auto relative overflow-hidden xl:px-16 px-4 md:py-4 xl:py-16">
          <div className="grid xl:grid-cols-2 md:grid-cols-2 grid-cols-1 md:gap-4 xl:gap-4">
            <div className="">
              <img
                src="https://i.ibb.co/5GxjqxF/penetrationtesting.png"
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600 xl:mt-20 md:mt-32 mt-8"
                alt="Your browser does not support this part content"
              />
            </div>
            <div className="xl:pl-12">
              <div className="xl:pt-0 pt-4">
                <div className="flex items-center">
                  <p className="font-bold text-red xl:text-5xl text-2xl">01</p>
                  <h2 className="font-semibold xl:text-xl font xl:py-2 pl-2">
                    What is VAPT?
                  </h2>
                </div>
                <p className="text-base font pt-2">
                  Vulnerability Assessment and Penetration Testing (VAPT) is a
                  penetration testing methodology in which the vulnerabilities
                  of a system are first identified and then exploited.
                </p>
              </div>
              <div className="xl:pt-6 pt-4">
                <div className="flex items-center">
                  <p className="font-bold text-red xl:text-5xl text-2xl">02</p>
                  <h2 className="font-semibold xl:text-xl font xl:py-2 pl-2">
                    Why do I need a VAPT?
                  </h2>
                </div>
                <p className="text-base font pt-2">
                  It's crucial to evaluate your organization's cyber security
                  regularly due to the constantly evolving tools, techniques,
                  and procedures used by hackers to access networks. VAPT
                  assists in securing your organization by making security flaws
                  visible and offering advice on how to fix them. Achieving
                  compliance with regulations such as the GDPR, ISO 27001, and
                  PCI DSS, VAPT is becoming more and more crucial for every
                  organization.
                </p>
              </div>
              <div className="xl:pt-6 pt-4">
                <div className="flex items-center">
                  <p className="font-bold text-red xl:text-5xl text-2xl">03</p>
                  <h2 className="font-semibold xl:text-xl font xl:py-2 pl-2">
                    What is CERT-in?
                  </h2>
                </div>
                <p className="text-base font pt-2">
                  CERT-In is an organization mandated by the government to
                  provide information technology (IT) security. The government
                  responds to computer security incidents, reports
                  vulnerabilities, and promotes IT security practices across the
                  states in the country.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Cyber threat awarness */}

      <div className="container mx-auto relative overflow-hidden xl:px-16 xl:py-16 py-8 px-4">
        <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-2 xl:gap-4">
          <div className="">
            <h1 className="font font-semibold xl:text-2xl md:text-xl xl:pb-2 md:pb-2 lg:pb-2 pb-2">
              Cyber Threat Awareness
            </h1>
            <p className="font pt-2">
              India has been positioned as the 7th most breached country in the
              world during the second quarter of 2023, according to the latest
              findings unveiled by cybersecurity firm Surfshark. This
              disconcerting report highlights a surge in data breaches across
              the globe, emphasizing the need for enhanced data protection
              measures.
            </p>
            <p className="font py-2">
              India’s Encouraging Trend Amidst the disheartening global surge in
              data breaches, India’s trajectory appears to be heading in a more
              positive direction.
            </p>
            <p className="font py-2">
              Surfshark’s data breach analysis for Q2 2023 revealed that India
              secured the 7th spot globally with approximately 1.35 million
              leaked accounts. This marked improvement was accompanied by a 44%
              reduction in breach rates compared to the previous quarter.
            </p>
            <a href="https://www.businesstoday.in/technology/story/india-becoming-target-of-increased-cybersecurity-attack-says-report-by-palo-alto-networks-397901-2023-09-11">
              <p className="font text-white font-bold xl:text-sm underline underline-offset-2 mt-4 bg-red-500 p-1 xl:w-10/12">
                <u>
                  India becoming target of increased cybersecurity attack in
                  2023
                </u>
              </p>
              <p className="font text-sm font-semibold pl-1 pt-1">
                by Business Today
              </p>
            </a>

            <a href="https://www.businesstoday.in/technology/story/india-becoming-target-of-increased-cybersecurity-attack-says-report-by-palo-alto-networks-397901-2023-09-11">
              <p className="font text-white font-bold xl:text-sm underline underline-offset-2 mt-4 bg-red-500 p-1 xl:w-10/12">
                <u>Bengalureans lost ₹470 crore in cyber crimes in 2023</u>
              </p>
              <p className="font text-sm font-semibold pl-1 pt-1">
                by The Hindu
              </p>
            </a>

            <a href="https://www.businesstoday.in/technology/story/india-becoming-target-of-increased-cybersecurity-attack-says-report-by-palo-alto-networks-397901-2023-09-11">
              <p className="font text-white font-bold xl:text-sm underline underline-offset-2 mt-4 bg-red-500 p-1 xl:w-10/12">
                <u>Cyber crimes soar in Delhi, complaints jump 200% in 2023</u>
              </p>
              <p className="font text-sm font-semibold pl-1 pt-1 pb-6 xl:pb-0">
                by Economic Times
              </p>
            </a>
          </div>
          <div className="xl:pl-12">
            <img
              src="https://i.ibb.co/wpX0DzQ/cyberthreatawarness.png"
              className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600 mt-0 xl:mt-20 lg:mt-20 md:mt-12 "
              alt="Your browser does not support this part content"
            />
          </div>
        </div>
      </div>

      {/* Pen Testing */}

      <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12">
        <div className="grid xl:flex lg:flex lg:w-full xl:w-full md:grid-cols-1 grid-cols-1 xl:gap-2 xl:pt-8 pt-0 lg:pt-0 md:pt-2">
          <div className="font xl:text-3xl lg:text-3xl md:text-3xl text-2xl font-semibold xl:w-4/12 lg:w-4/12 xl:pt-6 ">
            Does My Organization Need Pen Testing?
            <p className="xl:pt-4 xl:text-base text-lg">
              Here are five reasons why you need a penetration test
            </p>
          </div>

          <div className="xl:w-8/12 lg:w-8/12 pl-3">
            <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-2 xl:gap-2">
              <div className="xl:pt-8 pt-8 lg:pt-0 md:pt-8">
                <img
                  src="https://i.ibb.co/XYFngXb/Detect-Vulnerabilities.png"
                  className="w-10"
                ></img>
                <h5 className="xl:text-lg text-lg font-semibold xl:py-1 font">
                  Detect Vulnerabilities Before Criminals Do
                </h5>
                <div className="font xl:text-base lg:text-lg md:text-lg text-lg md:py-4 lg:py-0 py-4 xl:py-0">
                  A penetration test can reveal vulnerabilities that a
                  cybersecurity strategy may not have considered and help you
                  patch any weak spots before a criminal finds them. It can also
                  reveal vulnerabilities that cannot be detected with automated
                  network vulnerability scanning.
                </div>
              </div>

              <div className="xl:pt-8 pt-8 lg:pt-0 md:pt-8 xl:pl-2 pl-0 md:pl-2">
                <img
                  src="https://i.ibb.co/bHKcJ1W/Network-Defenders.png"
                  className="w-10"
                ></img>
                <h5 className="xl:text-lg text-lg font-semibold xl:py-1 font">
                  Test the Abilities of Your Network Defenders
                </h5>
                <div className="font xl:text-base lg:text-lg md:text-lg text-lg md:py-4 lg:py-0 py-4 xl:py-0">
                  A penetration test can analyse the ability of your IT
                  professionals and intrusion detection programs to spot and
                  respond to an attack. Pen tests also help identify areas where
                  you need to improve your network defences.
                </div>
              </div>
            </div>

            <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-2 xl:gap-2">
              <div className="pt-6">
                <img
                  src="https://i.ibb.co/6vSPgpx/Successful-Attack.png"
                  className="w-10"
                ></img>
                <h5 className="xl:text-lg text-lg font-semibold xl:py-1 font">
                  Assess the Potential Damage of a Successful Attack
                </h5>
                <div className="font xl:text-base lg:text-lg md:text-lg text-lg md:py-4 lg:py-0 py-4 xl:py-0">
                  A successful cyberattack can severely impact your business,
                  including disruption of critical processes, damage to brand
                  reputation, loss of key business data, and more. A penetration
                  test can help you assess and plan for these impacts during the
                  disaster recovery phase.
                </div>
              </div>

              <div className="pt-6 xl:pl-2 pl-0 md:pl-2">
                <img
                  src="https://i.ibb.co/86tWz45/customersecurity.png"
                  className="w-10"
                ></img>
                <h5 className="xl:text-lg text-lg font-semibold xl:py-1 font">
                  Prove Security Effectiveness to Customers and Executives
                </h5>
                <div className="font xl:text-base lg:text-lg md:text-lg text-lg md:py-4 lg:py-0 py-4 xl:py-0">
                  With data breaches becoming common news, customers are
                  increasingly concerned about their data safety. A penetration
                  test can help prove to them that your company is airtight and
                  to executives that investing in cybersecurity is necessary for
                  defending critical company assets.
                </div>
              </div>
            </div>

            <div className="grid xl:grid-cols-2 xl:gap-2">
              <div className="pt-12">
                <img
                  src="https://i.ibb.co/4F1pB0H/networkdowntime.png"
                  className="w-12"
                ></img>
                <h5 className="xl:text-lg text-lg font-semibold xl:py-1 font">
                  Reduce Remediation Costs and Network Downtime
                </h5>
                <div className="font xl:text-base lg:text-lg md:text-lg text-lg md:py-4 lg:py-0 py-4 xl:py-0">
                  Scrambling to fix security holes following a breach can be
                  expensive and cause a significant outage for your business
                  operations and customers. By addressing the vulnerabilities, a
                  penetration test discovers, fixes are faster and less
                  disruptive to your company.
                </div>
              </div>
              <div className="pt-6 pl-2"></div>
            </div>
          </div>
        </div>
      </div>

      {/* 3types services  */}
      <div className="font container mx-auto relative overflow-hidden xl:px-16 px-4 xl:py-8 lg:py-0 space-y-6 lg:space-y-2 pb-4 xl:pt-24 pt-0">
        <div className="tab-buttons text-center">
          <div className="grid xl:grid-cols-3 md:grid-cols-3 grid-cols-1 border-b-c xl:gap-0 gap-2 ">
            <button
              className={`${
                activeTab === 1 ? "active xl:text-xl text-xs" : "border-sky-600"
              } font font-semibold mx-4 xl:text-xl text-base xl:pt-2 xl:py-0 py-2 focus:outline-none`}
              onClick={() => handleTabClick(1)}
            >
              <div className="flex items-center xl:ml-10">
                <img
                  src="https://i.ibb.co/kqr6g8q/applicationsecurity.png"
                  className="xl:h-10 xl:w-10 xl:mr-2 xl:mb-2 h-5 w-5 mr-2"
                />
                <p className="xl:text-base xl:mb-2 text-left pl-2">
                  Application Security Audit Frameworks
                </p>
              </div>
            </button>

            <button
              className={`${
                activeTab === 2 ? "active xl:text-xl text-xs" : "border-sky-600"
              } font font-semibold mx-4 xl:text-xl text-base xl:pt-2 xl:py-0 py-2 focus:outline-none`}
              onClick={() => handleTabClick(2)}
            >
              <div className="flex items-center xl:ml-10">
                <img
                  src="https://i.ibb.co/CwpwFXB/networksecurity.png"
                  className="xl:h-10 xl:w-10 xl:mr-2 xl:mb-2 h-5 w-5 mr-2"
                />
                <p className="xl:text-base xl:mb-2 text-left pl-2">
                  Network Security Audit Frameworks
                </p>
              </div>
            </button>

            <button
              className={`${
                activeTab === 3 ? "active xl:text-xl text-xs" : "border-sky-600"
              } font font-semibold mx-4 xl:text-xl text-base xl:pt-2 xl:py-0 py-2 focus:outline-none`}
              onClick={() => handleTabClick(3)}
            >
              <div className="flex items-center xl:ml-10">
                <img
                  src="https://i.ibb.co/khKjyZq/cloudsecurity.png"
                  className="xl:h-10 xl:w-10 xl:mr-2 xl:mb-2 h-5 w-5 mr-2"
                />
                <p className="xl:text-base xl:mb-2 text-left pl-2">
                  Cloud Security Audit Frameworks
                </p>
              </div>
            </button>
          </div>
        </div>
      </div>

      <div className="font container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-16">
        <div className="tab-content text-center">
          {activeTab === 1 && (
            <div className="content-center xl:px-5 xl:pt-0 xl:text-xl">
              {/* Content for Our Vision */}
              <div className="grid xl:grid-cols-1 md:grid-cols-1 text-lg ">
                <div className="font text-left xl:text-lg text-sm xl:pt-4 xl:p-5 p-5 ">
                  <h1 className="font-semibold font xl:text-2xl text-base text-center pb-2">
                    Quick Description
                  </h1>
                  <p className="text-center xl:px-12 xl:pb-0 pb-4">
                    Reviewing and Analyzing the Applications to identify the
                    security vulnerabilities and <br></br>loopholes present in
                    the web & mobile application.
                  </p>
                  <div className="">
                    <div className="grid xl:grid-cols-2 md:grid-cols-2 grid-cols-1 md:gap-4 xl:gap-4 xl:px-16">
                      <div className="xl:pb-6 xl:pt-12">
                        <div className="rounded-md border p-6 hover:bg-red-50">
                          <h2 className="font font-semibold xl:text-xl">
                            Web App VAPT Standards
                          </h2>
                          <ul className="list-none xl:pt-4 pt-4">
                            <li className="font xl:text-base text-sm flex items-start ">
                              <span className="mr-2 text-red">✔</span> RCE, SQL
                              Injection Attack
                            </li>
                            <li className="font xl:text-base text-sm flex items-start py-3">
                              <span className="mr-2">✔</span> Broken
                              Authentication
                            </li>
                            <li className="font xl:text-base text-sm flex items-start ">
                              <span className="mr-2 text-red">✔</span> Sensitive
                              Data Exposure
                            </li>
                            <li className="font xl:text-base text-sm flex items-start py-3">
                              <span className="mr-2">✔</span> Payment
                              Manipulation
                            </li>
                            <li className="font xl:text-base text-sm flex items-start ">
                              <span className="mr-2 text-red">✔</span> Improper
                              Access Control
                            </li>
                            <li className="font xl:text-base text-sm flex items-start py-3">
                              <span className="mr-2">✔</span> Security
                              Misconfiguration
                            </li>
                            <li className="font xl:text-base text-sm flex items-start ">
                              <span className="mr-2 text-red">✔</span>{" "}
                              Cross-Site Scripting
                            </li>
                            <li className="font xl:text-base text-sm flex items-start py-3">
                              <span className="mr-2">✔</span> OTP/2FA/Captcha
                              Bypass
                            </li>
                            <li className="font xl:text-base text-sm flex items-start ">
                              <span className="mr-2 text-red">✔</span> DOS /
                              Rate Limiting Attack
                            </li>
                            <li className="font xl:text-base text-sm flex items-start py-3">
                              <span className="mr-2">✔</span> Business Login
                              Attacks
                            </li>
                          </ul>{" "}
                        </div>
                      </div>

                      <div className="xl:pb-6 xl:pt-12 xl:pt-0 md:pt-0 pt-4">
                        <div className="rounded-md border p-6 hover:bg-red-50">
                          <h2 className="font font-semibold xl:text-xl">
                            Mobile App VAPT Standards
                          </h2>
                          <ul className="list-none xl:pt-4 pt-4">
                            <li className="font xl:text-base text-sm flex items-start ">
                              <span className="mr-2 text-red">✔</span> Insecure
                              Data Storage
                            </li>
                            <li className="font xl:text-base text-sm flex items-start py-3">
                              <span className="mr-2">✔</span> Unintended Data
                              Leakage
                            </li>
                            <li className="font xl:text-base text-sm flex items-start ">
                              <span className="mr-2 text-red">✔</span> Weak
                              Server-Side Controls
                            </li>
                            <li className="font xl:text-base text-sm flex items-start py-3">
                              <span className="mr-2">✔</span> Insufficient
                              Transport Protection
                            </li>
                            <li className="font xl:text-base text-sm flex items-start ">
                              <span className="mr-2 text-red">✔</span> Poor
                              Authorization Level
                            </li>
                            <li className="font xl:text-base text-sm flex items-start py-3">
                              <span className="mr-2">✔</span> Client-Side
                              Injection
                            </li>
                            <li className="font xl:text-base text-sm flex items-start ">
                              <span className="mr-2 text-red">✔</span> Broken
                              Cryptography
                            </li>
                            <li className="font xl:text-base text-sm flex items-start py-3">
                              <span className="mr-2">✔</span> Lack of Binary
                              Protection
                            </li>
                            <li className="font xl:text-base text-sm flex items-start ">
                              <span className="mr-2 text-red">✔</span> Improper
                              Session Handling
                            </li>
                            <li className="font xl:text-base text-sm flex items-start py-3">
                              <span className="mr-2">✔</span> Improper Input
                              Handling
                            </li>
                          </ul>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeTab === 2 && (
            <div className="content-center xl:px-5 xl:pt-0 xl:text-xl">
              {/* Content for Our Vision */}
              <div className="grid xl:grid-cols-1 md:grid-cols-1 grid-cols-1  text-lg ">
                <div className="font text-left xl:text-lg text-sm xl:pt-4 xl:p-5 p-5">
                  <h1 className="font-semibold font xl:text-2xl text-base text-center pb-2">
                    Quick Description
                  </h1>
                  <p className="text-center xl:px-12 xl:pb-0 pb-2">
                    Reviewing and Analyzing the Network Infrastructure to
                    identify the security <br></br>vulnerabilities and loopholes
                    present in the Network
                  </p>
                  <div className="">
                    <div className="grid xl:grid-cols-1 grid-cols-1 xl:gap-4 xl:px-24 md:px-44 lg:px-52">
                      <div className="xl:pb-6 xl:pt-12 justify-self-center xl:px-32">
                        <div className="rounded-md border p-6 hover:bg-red-50">
                          <h2 className="font font-semibold xl:text-xl text-center">
                            Network VAPT Standards
                          </h2>
                          <ul className="text-center xl:pl-0 lg:pl-36 md:pl-8 pl-0 list-none xl:pt-4 pt-4 grid xl:grid-cols-2 grid-cols-1">
                            <li className="font xl:text-base text-sm flex items-start ">
                              <span className="mr-2 text-red">✔</span> CVE, NVD
                              Vulnerabilities
                            </li>
                            <li className="font xl:text-base text-sm flex items-start pb-4">
                              <span className="mr-2 text-red">✔</span> Open Port
                              / Service Scan
                            </li>
                            <li className="font xl:text-base text-sm flex items-start ">
                              <span className="mr-2 ">✔</span>{" "}
                              SMB/SSH/RDP/FTP/SSL Audit
                            </li>
                            <li className="font xl:text-base text-sm flex items-start pb-4">
                              <span className="mr-2">✔</span> Auth &
                              Authorization Audit
                            </li>
                            <li className="font xl:text-base text-sm flex items-start ">
                              <span className="mr-2 text-red">✔</span>{" "}
                              Firewall/Switches/Router Scan
                            </li>
                            <li className="font xl:text-base text-sm flex items-start pb-4">
                              <span className="mr-2 text-red">✔</span>{" "}
                              Windows/Mac/Linux Patch Scan
                            </li>
                            <li className="font xl:text-base text-sm flex items-start ">
                              <span className="mr-2 ">✔</span> AV/EICAR Audit
                            </li>
                            <li className="font xl:text-base text-sm flex items-start pb-4">
                              <span className="mr-2">✔</span> Virus/Malware Scan
                            </li>
                            <li className="font xl:text-base text-sm flex items-start ">
                              <span className="mr-2 text-red">✔</span> Wireless
                              Network Audit
                            </li>
                            <li className="font xl:text-base text-sm flex items-start pb-4">
                              <span className="mr-2 text-red">✔</span> Public IP
                              / Data Leak Audit
                            </li>
                            <li className="font xl:text-base text-sm flex items-start pb-4">
                              <span className="mr-2">✔</span> Network
                              Configuration Audit
                            </li>
                            <li className="font xl:text-base text-sm flex items-start pb-4">
                              <span className="mr-2">✔</span> Outdated
                              OS/Plugins/Apps Scan
                            </li>
                          </ul>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeTab === 3 && (
            <div className="content-center xl:px-5 xl:pt-0 xl:text-xl">
              {/* Content for Our Vision */}
              <div className="grid xl:grid-cols-1 md:grid-cols-1 grid-cols-1 text-lg ">
                <div className="font text-left xl:text-lg text-sm xl:pt-4 xl:p-5 p-5">
                  <h1 className="font-semibold font xl:text-2xl text-base text-center ">
                    Quick Description
                  </h1>
                  <p className="text-center xl:px-12 pt-2  xl:pb-0 pb-2">
                    Reviewing and Analyzing the Cloud Infrastructure & it’s
                    Services to identify the security <br></br>vulnerabilities
                    and loopholes present in the Cloud.
                  </p>
                  <div className="">
                    <div className="grid xl:grid-cols-1 grid-cols-1 xl:gap-4 xl:px-24 md:px-44 lg:px-52">
                      <div className="xl:pb-6 xl:pt-12 justify-self-center xl:px-32">
                        <div className="rounded-md border p-6 hover:bg-red-50">
                          <h2 className="font font-semibold xl:text-xl text-center">
                            Cloud VAPT Standards
                          </h2>
                          <ul className="xl:pl-0 lg:pl-36 md:pl-8 pl-0 list-none xl:pt-4 pt-4 grid xl:grid-cols-2 grid-cols-1">
                            <li className="font xl:text-base text-sm flex items-start ">
                              <span className="mr-2 text-red">✔</span> Cloud
                              Infra Design Review
                            </li>
                            <li className="font xl:text-base text-sm flex items-start pb-4">
                              <span className="mr-2 text-red">✔</span> IAM
                              Policy and Roles Audit
                            </li>
                            <li className="font xl:text-base text-sm flex items-start ">
                              <span className="mr-2 ">✔</span> Instance Access
                              Role Review
                            </li>
                            <li className="font xl:text-base text-sm flex items-start pb-4">
                              <span className="mr-2">✔</span> User Roles and
                              Policy Review
                            </li>
                            <li className="font xl:text-base text-sm flex items-start ">
                              <span className="mr-2 text-red">✔</span> Object
                              Storage Leakage Audit
                            </li>
                            <li className="font xl:text-base text-sm flex items-start pb-4">
                              <span className="mr-2 text-red">✔</span>{" "}
                              Compute/Database Config Review
                            </li>
                            <li className="font xl:text-base text-sm flex items-start ">
                              <span className="mr-2 ">✔</span> Security Rules
                              Review
                            </li>
                            <li className="font xl:text-base text-sm flex items-start pb-4">
                              <span className="mr-2">✔</span> VPC & Network
                              Review
                            </li>
                            <li className="font xl:text-base text-sm flex items-start ">
                              <span className="mr-2 text-red">✔</span> Internal
                              & Public IP Scan
                            </li>
                            <li className="font xl:text-base text-sm flex items-start pb-4">
                              <span className="mr-2 text-red">✔</span> Cloud OEM
                              Best Practice Audit
                            </li>
                            <li className="font xl:text-base text-sm flex items-start pb-4">
                              <span className="mr-2">✔</span> Cloud Security
                              Service Review
                            </li>
                          </ul>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Cyber security services  */}

      <div className=" bg-blueGray-100">
        <div className="container mx-auto relative overflow-hidden xl:pt-16 md:pt-0 pt-0 xl:py-4 px-4">
          <p className="font xl:text-3xl lg:text-3xl md:text-3xl text-2xl font-semibold  xl:pb-8 xl:pt-4 md:pt-6 md:pb-4 pb-4 pt-4">
            Our Cyber Security Services
          </p>
          <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 xl:w-12/12 xl:gap-4 md:gap-4 gap-4 xl:pb-12 md:mb-8 mb-8">
            <div className="bg-white xl:p-3 md:p-5 p-5 mb-4 md:mb-4">
              <img
                src="https://i.ibb.co/T0YTfKX/security-audit.png"
                alt="Your browser does not support this part content"
              ></img>
              <p className="font xl:text-2xl md:text-2xl text-xl md:pt-6 xl:pt-8 pt-4 xl:pl-4">
                Security Audit
              </p>
              <p className="font xl:text-base xl:py-2 md:py-0 py-4">
                <ul className="list-inside list-disc xl:pl-8 pl-4 pt-4 font xl:text-base">
                  <li>Web App Security Audit</li>
                  <li className="py-2">Mobile App Security Audit</li>
                  <li>Network Security Audit</li>
                  <li className="py-2">Infrastructure Security Audit</li>
                  <li>Cloud Security Audit</li>
                  <li className="py-2">Secure Code Review Audit</li>
                  <li>Firewall Rules Security Audit</li>
                  <li className="py-2">Active Directory Security Audit</li>
                  <li className="xl:pb-8 pb-0 md:pb-4">IoT Security Audit</li>
                </ul>
              </p>
            </div>
            <div className="bg-white xl:p-3 md:p-5 p-5 mb-4 md:mb-4">
              <img
                src="https://i.ibb.co/h8HWM7J/compliances-services.png"
                alt="Your browser does not support this part content"
              ></img>
              <p className="font xl:text-2xl md:text-2xl text-xl md:pt-6 xl:pt-8 pt-4 xl:pl-4">
                Compliance Services
              </p>
              <p className="font xl:text-base xl:py-2 md:py-0 py-4">
                <ul className="list-inside list-disc xl:pl-8 pt-4 pl-4 font xl:text-base">
                  <li>PCI DSS</li>
                  <li className="pt-2">ISO 27001</li>

                  <li className="py-2">HIPAA</li>
                  <li>GDPR</li>
                  <li className="py-2">NBFC IT Framework</li>
                  <li>CICR Regulation</li>
                  <li className="py-2">SOC 2</li>
                  <li className="xl:pb-8 pb-0 md:pb-4">CCPA</li>
                </ul>
              </p>
            </div>

            <div className="bg-white xl:p-3 md:p-5 p-5 mb-4 md:mb-4">
              <img
                src="https://i.ibb.co/y8s4ydp/securityservicess.png"
                alt="Your browser does not support this part content"
              ></img>
              <p className="font xl:text-2xl md:text-2xl text-xl md:pt-6 xl:pt-8 pt-4 xl:pl-4">
                Security Services
              </p>
              <p className="font xl:text-base xl:py-2 md:py-0 py-4">
                <ul className="list-inside list-disc xl:pl-8 pl-4 pt-4 font xl:text-base">
                  <li>Firewall Management</li>
                  <li className="py-2">EDR Management</li>
                  <li>XDR Management</li>
                  <li className="py-2">MDR Management</li>
                  <li>MS 365 Security</li>
                  <li className="py-2">Google Workspace Security</li>
                  <li className="xl:pb-8 pb-0 md:pb-4">DMARC Security</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 ">
        <div className="xl:px-10">
          <h3 className="font font-semibold xl:text-3xl md:text-3xl text-xl text-center xl:pt-16 pt-4 md:py-4 xl:pb-8">
            Our Penetration Methodology
          </h3>
          <div class="table-container">
            <table className="my-table">
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <h3 className="text-left font font-semibold xl:text-base">
                      Black Box
                    </h3>
                    <p className="font-normal text-sm text-left">
                      Aka close box penetration testing
                    </p>
                  </th>
                  <th>
                    <h3 className="text-left font font-semibold xl:text-base">
                      Grey Box
                    </h3>
                    <p className="font-normal text-sm text-left">
                      Combination of black box and white box testing
                    </p>
                  </th>
                  <th>
                    <h3 className="text-left font font-semibold xl:text-base">
                      White Box
                    </h3>
                    <p className="font-normal text-sm text-left">
                      Aka open box penetration testing
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="font font-semibold text-base text-left pl-2">
                    Goal
                  </td>
                  <td className="text-sm font text-left">
                    Mimic a true cyber attack
                  </td>
                  <td className="text-sm font text-left">
                    Assess the organization's vulnerability to insider threats
                  </td>
                  <td className="text-sm font text-left">
                    Stimulate an attack gain access to a privileged account
                  </td>
                </tr>
                <tr>
                  <td className="font font-semibold text-base text-left wid">
                    Access Level
                  </td>
                  <td className="text-sm font text-left">
                    Zero access or internal information
                  </td>
                  <td className="text-sm font text-left">
                    Some internal access and internal information
                  </td>
                  <td className="text-sm font text-left">
                    Complete open access to applications and systems
                  </td>
                </tr>
                <tr>
                  <td className="font font-semibold text-base text-left pl-2">
                    Pros
                  </td>
                  <td className="text-sm font text-left">
                    Most Realistic
                    <p className="text-red font text-xs font-semibold text-left">
                      Testing is performed from point <br></br>of view of the
                      attacker
                    </p>
                  </td>
                  <td className="text-sm font text-left">
                    Most efficient than black-box and saves on time and money
                    <p className="text-red font text-xs font-semibold text-left">
                      Testing is performed from point <br></br>of view of the
                      attacker
                    </p>
                  </td>
                  <td className="text-sm font text-left">
                    Most Comprehensive less likely to miss a vulnerability and
                    faster
                    <p className="text-red font text-xs font-semibold text-left">
                      Testing is performed from point <br></br>of view of the
                      attacker
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="font font-semibold text-base text-left pl-2">
                    Cons
                  </td>
                  <td className="text-sm font text-left">
                    Time-consuming and more likely to miss a vulnerability
                  </td>
                  <td className="text-sm font text-left">
                    No real cons to this type of testing
                  </td>
                  <td className="text-sm font text-left">
                    More data (ex, source code) is required to be released to
                    the tester and more expensive
                  </td>
                </tr>
                {/* Add more rows as needed */}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 mt-6">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12">
          <div className=" xl:pt-8 pt-4 lg:pt-4 md:pt-2">
            <div className=" text-center font xl:text-3xl lg:text-3xl md:text-3xl text-2xl font-semibold  xl:pt-6 md:pt-6 pt-6">
              What is Standard Compliances?
              <p className="text-center xl:pt-4 xl:text-base text-base font-thin">
                "Standard compliances" is a broad term, so I need more context
                to understand what you're asking. Here are some potential
                interpretations
              </p>
            </div>

            <div className=" pl-3 pt-4">
              <div className="grid xl:grid-cols-3 lg:grid-cols-3 lg:gap-4 xl:gap-6">
                <div className="pt-8 ">
                  <img
                    src="https://i.ibb.co/n7D8JKW/technicalstandard.png"
                    className="w-10"
                  ></img>
                  <h5 className="xl:text-base text-lg font-semibold xl:py-1 font">
                    Compliance with Technical Standards
                  </h5>
                  <div className="font xl:text-base lg:text-lg md:text-lg text-lg md:py-4 lg:py-0 py-4 xl:py-0">
                    These are established specifications or sets of best
                    practices for specific products, processes, or systems such
                    as ISO 9001 for quality management, PCI DSS for payment card
                    security, or HIPAA for healthcare data protection. Companies
                    or organizations aim to "comply" with these standards to
                    ensure their products, services, or operations meet certain
                    requirements.
                  </div>
                </div>

                <div className="pt-8 xl:pl-2 pl-0 md:pl-2 ">
                  <img
                    src="https://i.ibb.co/3czxKs5/legalregulations.png"
                    className="w-10"
                  ></img>
                  <h5 className="xl:text-base text-lg font-semibold xl:py-1 font">
                    Compliance with Legal and Regulatory
                  </h5>
                  <div className="font xl:text-base lg:text-lg md:text-lg text-lg md:py-4 lg:py-0 py-4 xl:py-0">
                    Businesses and individuals often need to comply with various
                    laws and regulations depending on their location and
                    industry. These can cover aspects like data privacy,
                    financial reporting, environmental protection, or
                    occupational safety. Compliance ensures the organizations
                    operate within legal boundaries and avoid penalties or
                    risks.
                  </div>
                </div>

                <div className="pt-8">
                  <img
                    src="https://i.ibb.co/xsFs41J/standardcompliances.png"
                    className="w-10"
                  ></img>
                  <h5 className="xl:text-base text-lg font-semibold xl:py-1 font">
                    Adherence to Standards and Expectations
                  </h5>
                  <div className="font xl:text-base lg:text-lg md:text-lg text-lg md:py-4 lg:py-0 py-4 xl:py-0">
                    In a broader sense, "standard compliances" can refer to
                    simply upholding established norms or expectations within a
                    particular field or context. This could involve following
                    ethical codes, professional guidelines, or internal company
                    policies.
                  </div>
                </div>
              </div>

              <div className="grid xl:grid-cols-2 xl:gap-2">
                <div className="pt-12 pl-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 ">
          <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-4 xl:gap-6 xl:py-6 py-0">
            <div className="xl:pt-16 lg:pt-0">
              <h2 className="font font-semibold xl:text-2xl text-xl  xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                Overview
              </h2>
              <p className="font text-base pt-2">
                Standard compliance is the set guidelines, set forth for
                organizations to be in accordance with standards to secure their
                IT systems, processes and organizational data and strengthen
                their IT governance. There are several recognized standards
                which are widely practiced throughout the globe.
              </p>
              <p className="font text-base pt-2">
                Some of these standards are set forth as per the industry
                standards to secure the Personal Identifiable Information (PII)
                and Personal Health Information (PHI) of the consumers or the
                end-clients. At Kratikal, we specialize in well-recognized
                standard compliances like ISO/IEC 27001 certification, SOC2,
                GDPR, and industry-based compliances like PCI DSS and HIPAA.
              </p>
            </div>

            <div className=" xl:pl-12 xl:mt-0 mt-4 lg:mt-4 md:mt-16">
              <img
                src="https://i.ibb.co/jTTynbS/overview.png"
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:py-0 md:py-0 lg:py-12 lg:pb-0 xl:pb-12 ">
          <div className="grid xl:grid-cols-2  md:grid-cols-2 md:gap-4 xl:gap-6 py-6">
            <div className="xl:block lg:block md:block hidden">
              <img
                src="https://i.ibb.co/gTT8YGH/whychooseus.png"
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600"
              ></img>
            </div>
            <div className="xl:pl-4 xl:pt-20 lg:pt-4">
              <h2 className="font font-semibold xl:text-2xl text-lg xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                Why Choose Us?
              </h2>
              <p className="font text-base pt-2">
                Do you know what distinguishes Marslab from others? We have your
                trust!
              </p>
              <p className="font text-base pt-2">
                We are one of the top 10 cyber security solution provider firms
                in India. We believe in a client-centric approach and dedication
                to ensuring that best practices are adopted for the
                Organizations.
              </p>
              <p className="font text-base pt-2">
                Our strategy? We focus on optimizing our client's chances of
                achieving ISO/IECs-27001 compliance, it is essential to offer
                holistic solutions and complete compliance.
              </p>
            </div>
            <div className="xl:hidden lg:hidden md:hidden block">
              <img
                src="https://i.ibb.co/gTT8YGH/whychooseus.png"
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600 xl:pt-0 pt-4 md:pt-0"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 ">
          <div className="grid xl:grid-cols-2  md:grid-cols-2 md:gap-4 xl:gap-12 xl:py-6 py-3">
            <div className="xl:pt-16 lg:pt-20 pt-4">
              <h2 className="font font-semibold xl:text-2xl text-lg xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                Our Expertise
              </h2>
              <p className="font text-base pt-2">
                Our team of certified cybersecurity compliance experts have
                hands-on experience on best of industry SIEM, network monitoring
                and data loss prevention tools.
              </p>
              <p className="font text-base pt-2">
                Our experts have joined hands with various organizations of a
                wide range of industries and thus, hold expertise in standard,
                industry-based and regulatory compliances.
              </p>
              <p className="font text-base pt-2">
                Marslab's compliance implementers and ISO27001 auditors are
                well-versed in international IT frameworks and act, hence,
                delivering an optimized solution unique to your organization.
              </p>
            </div>

            <div className="pl-12">
              <img
                src="https://i.ibb.co/qgWTp5t/certifiedcyberexpert.png"
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600 xl:mt-0 mt-4 md:mt-16"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 xl:pt-0 md:pt-8">
          <div className="grid xl:grid-cols-2  md:grid-cols-2 md:gap-4 xl:gap-6 xl:py-6 py-3">
            <div className="xl:block lg:block md:block hidden">
              <img
                src="https://i.ibb.co/YdjfRXX/benefitsstandard.png"
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600"
              ></img>
            </div>
            <div className="xl:pl-4 xl:pt-24 lg:pt-12 pt-4">
              <h2 className="font font-semibold xl:text-2xl text-lg xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                Benefits of Standard Compliances
              </h2>
              <p className="font text-base pt-2">
                Enhances the Organization’s safety and effectiveness.
              </p>
              <p className="font text-base pt-2">
                Draft policies and procedures to stay updated with ever-changing
                threat landscape.
              </p>
              <p className="font text-base pt-2">
                Develops a robust Risk management framework.
              </p>
              <p className="font text-base pt-2">
                Identifying and mitigating risks within their business.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto relative overflow-hidden xl:px-16 px-4">
        <div className="xl:px-16">
          <h3 className="font font-semibold xl:text-3xl md:text-2xl text-lg text-center xl:pt-8 pt-6 xl:pb-6">
            Our Security Audit Process
          </h3>
          <div className="grid xl:grid-cols-2  md:grid-cols-2  pt-4">
            <div className="bg-grey-100 p-6">
              <h5 className="font text-left font-semibold ">
                Penetration Test Process
              </h5>
              <ul className="list-none xl:pt-4 pt-4">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Intelligence
                  Gathering
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Scanning
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Threat Modeling
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Vulnerability Analysis
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Exploitation
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Post Explotation
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Reporting
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Pre-engagement Interactions
                </li>
              </ul>{" "}
            </div>

            <div className="bg-red-100 p-6">
              <h5 className="font text-left font-semibold ">
                Penetration Test Process
              </h5>
              <ul className="list-none xl:pt-4 pt-4">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Information Gathering
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Automated VA Scan
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Advanced Manual Pen
                  Testing
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Reporting
                </li>
                <ul className="xl:pl-12 list-disc">
                  <li className="font xl:text-base text-sm flex items-start pt-1">
                    1. Vulnerability Details
                  </li>
                  <li className="font xl:text-base text-sm flex items-start pt-2">
                    2. Criticality Level
                  </li>
                  <li className="font xl:text-base text-sm flex items-start pt-2">
                    3. CVSS Score
                  </li>
                  <li className="font xl:text-base text-sm flex items-start pt-2">
                    4. Proof of Concept
                  </li>
                  <li className="font xl:text-base text-sm flex items-start pt-2 pb-3">
                    5. Fixing Recommendations
                  </li>
                </ul>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Remediation Test
                </li>

                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Audit certificate
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Cyber;
