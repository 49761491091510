import React, { useState, useEffect } from "react";
import Footer from "components/Footers/Footer";
import IndexNavbar from "components/Navbars/IndexNavbar";
import marshlabbannerr from "assets/img/marshlabbannerr.jpg";
import call from "assets/img/icons/call.png";
import mail from "assets/img/icons/mail.png";
import locationnewone from "assets/img/icons/locationnewone.png";
import globee from "assets/img/globee.png";
import { useLocation } from "react-router-dom";
import mailnewone from "assets/img/icons/mailnewone.png";
import callnewone from "assets/img/icons/callnewone.png";
import { useFormik } from "formik";
import * as Yup from "yup";

const Contactus = () => {
  const location = useLocation();
  const [enquiryClicked, setEnquiryClicked] = useState(false);

  const handleTalkToSalesClick = () => {
    window.location.href = "tel:+919176793939";
  };

  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const handleButtonClickMail = () => {
    window.location.href = "mailto:connect@marslab.work";
  };

  const formik = useFormik({
    initialValues: {
      Name_First: "",
      Name_Last: "",
      PhoneNumber_countrycodeval: "+91",
      PhoneNumber_countrycode: "",
      Email: "",
      SingleLine: "",
      Address_AddressLine1: "",
      Address_AddressLine2: "",
      Address_City: "",
      Address_Region: "",
      Address_ZipCode: "",
      Address_Country: "",
      Checkbox: [],
      requirement: "",
    },
    validationSchema: Yup.object({
      Name_First: Yup.string().required("Required"),
      Name_Last: Yup.string().required("Required"),
      PhoneNumber_countrycodeval: Yup.string().required("Required"),
      PhoneNumber_countrycode: Yup.string()
        .matches(/^[0-9]+$/, "Must be a number")
        .required("Required"),
      Email: Yup.string().email("Invalid email address").required("Required"),
      SingleLine: Yup.string().required("Required"),
      Address_AddressLine1: Yup.string().required("Required"),
      Address_City: Yup.string().required("Required"),
      Address_Region: Yup.string().required("Required"),
      Address_ZipCode: Yup.string().required("Required"),
      Address_Country: Yup.string().required("Required"),
      Checkbox: Yup.array().min(1, "Select at least one Service"),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const form = document.getElementById("myForm");
      form.submit();

      // Reset form after submission
      resetForm();
      setSubmitting(false);
    },
  });

  const formik1 = useFormik({
    initialValues: {
      Name_First: "",
      Name_Last: "",
      Date: "",
      PhoneNumber_countrycodeval: "+91",
      PhoneNumber_countrycode: "",
      Email: "",
      SingleLine: "",
      SingleLine1: "",
      Dropdown: "",
      Radio: "",
      Number: "",
      SingleLine2: "",
      MultiLine: "",
      FileUpload: null,
    },
    validationSchema: Yup.object({
      Name_First: Yup.string().required(),
      Name_Last: Yup.string().required(),
      Date: Yup.string()
        .matches(
          /^(0[1-9]|[12][0-9]|3[01])-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-\d{4}$/,
          "Invalid date format. Please use dd-Mon-yyyy"
        )
        .required("Date is required"),
      PhoneNumber_countrycodeval: Yup.string().required("Required"),
      PhoneNumber_countrycode: Yup.string()
        .matches(/^[0-9]+$/, "Must be a number")
        .required("Required"),
      Email: Yup.string().email("Invalid email address").required("Required"),
      SingleLine: Yup.string().required("Required"),
      SingleLine1: Yup.string().required("Required"),
      Dropdown: Yup.string().required("Required"),
      SingleLine2: Yup.string().required("Required"),
      FileUpload: Yup.mixed()
        .required("File is required")
        .test("fileType", "Unsupported file type", (value) => {
          return value && ["application/pdf"].includes(value.type);
        }),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const form1 = document.getElementById("myForm2");
      form1.submit();

      // Reset form after submission
      resetForm();
      setSubmitting(false);
    },
  });

  // Attach file name name will come
  const [fileName, setFileName] = useState("");

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    formik1.setFieldValue("FileUpload", file);
    setFileName(file.name); // Update file name in state
  };
  return (
    <section>
      <IndexNavbar />
      <div className="xl:mt-20 lg:mt-16 mt-16 xl:pb-88">
        <div
          style={{
            backgroundImage: `url(${marshlabbannerr})`,
            width: "100%",
          }}
          className="bg-ivory-black xl:h-80 md:h-60 h-48 relative"
        >
          <p className="font uppercase xl:text-4xl md:text-2ml text-xl text-white text-center xl:pt-32 md:pt-32  pt-20 font-semibold">
            Contact us
          </p>
          <div className="container mx-auto relative overflow-hidden">
            <div className="grid xl:grid-cols-3 xl:gap-4 xl:mx-20 lg:grid-cols-3 md:gap-4 md:grid-cols-3 gap-4 grid-cols-1 xl:py-16 md:px-4 px-4 pt-8">
              <div className="border-bottom-red shadow-xl xl:p-8 md:p-4 p-4 border bg-white rounded-lg font">
                <div className="">
                  <p className="uppercase font-semibold text-red xl:text-lg ">
                    We're here
                  </p>
                  <p className="xl:pt-2 pt-2 xl:text-base md:text-sm text-sm">
                    Our door is always open for a good cup of tea.
                  </p>
                </div>
                <div>
                  <div className="flex xl:pt-3 md:pt-4 pt-4 ">
                    <img
                      src={call}
                      className="xl:h-8 xl:w-8 md:h-6 md:w-6 h-6 w-6"
                    />
                    <button
                      onClick={handleTalkToSalesClick}
                      className="font xl:pl-2 md:pl-2 xl:pt-1 pl-2 xl:text-base md:text-sm text-sm"
                    >
                      +91 9176793939
                    </button>
                  </div>
                  <div className="flex xl:pt-3 xl:pl-1 pt-2">
                    <img
                      src={mail}
                      className="xl:h-6 xl:w-6 md:h-4 md:w-4 h-4 w-4"
                    />
                    <button
                      onClick={handleButtonClickMail}
                      className="font xl:pl-2 md:pl-3 pl-3 xl:text-base md:text-sm text-sm"
                    >
                      connect@marslab.work
                    </button>
                  </div>
                  <div className="flex xl:pt-3 xl:pl-0 pt-2">
                    <img
                      src={globee}
                      className="xl:h-6 xl:w-8 md:h-4 md:w-4 h-4 w-4"
                    />
                    <div className="font xl:pl-2 md:pl-3 pl-3 xl:text-base md:text-sm text-sm">
                      <a href="www.marslab.ai">www.marslab.ai</a>
                    </div>
                  </div>
                  <div className="flex xl:pt-3 pt-2">
                    <img
                      src={locationnewone}
                      className="xl:h-8 xl:ml-1 md:ml-0 ml-0 xl:w-8 md:h-6 md:w-6 h-6 w-6"
                    />
                    <div className="font xl:pl-1 md:pl-2 pl-2 xl:text-base md:text-sm text-sm">
                      No:617, 1st floor, Bharat Kumar Bhavan, Khivraj Campus,
                      Thousand Lights, Chennai, Tamil Nadu - 600006.
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-bottom-red shadow-xl xl:p-8 md:p-4 p-4 border bg-white rounded-lg font">
                <div className="text-center align-items">
                  <div className="center-button">
                    <img
                      src={callnewone}
                      className="xl:h-30 xl:w-30 lg:h-30 xl:mt-4 lg:mt-4 md:h-24 md:w-28 h-24 w-24"
                    />
                  </div>
                  <p className="uppercase font-semibold  text-ivory-black xl:text-lg xl:pt-4 md:pt-2 pt-2">
                    contact support
                  </p>
                  <p className="xl:pt-2 md:pt-1 xl:text-base md:text-sm text-sm px-4 pt-1">
                    Our support team is spread across the world to give you
                    answers fast.
                  </p>
                </div>
                <div className="center-button">
                  <button
                    className="text-red uppercase text-sm border-red rounded-xl xl:mt-4 md:mt-4 mt-4 font-semibold"
                    onClick={handleTalkToSalesClick}
                  >
                    Talk to sales
                  </button>
                </div>
              </div>
              <div className="border-bottom-red shadow-xl xl:p-8 md:p-4 p-4 border bg-white rounded-lg font">
                <div className="text-center align-items">
                  <div className="center-button">
                    <img
                      src={mailnewone}
                      className="xl:h-30 xl:w-30 lg:h-26 lg:w-28 xl:mt-4 lg:mt-4 md:h-24 md:w-28 h-24 w-24"
                    />
                  </div>
                  <p className="uppercase font-semibold cursor-pointer text-ivory-black xl:text-lg xl:pt-4 lg:pt-2 md:pt-2 pt-2">
                    contact sales
                  </p>
                  <p className="xl:pt-2 pt-1 pt-1 xl:text-base md:text-sm text-sm">
                    Get in touch with our sales team to see how we can work
                    together.
                  </p>
                </div>
                <div className="center-button">
                  <button
                    className="z-50 text-red uppercase cursor-pointer text-sm border-red rounded-xl xl:mt-4 md:mt-4 mt-4 font-semibold"
                    onClick={handleButtonClickMail}
                  >
                    {/* <a href="tel:+917026741524"> get support</a> */}
                    get support
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: "white" }} className=""></div>
      </div>

      {/* Google map */}

      <div className="container overflow-hidden mx-auto xl:px-24 px-4">
        <div className="xl:mt-0 lg:mt-64 md:mt-76 mt-100">
          <iframe
            className="w-full rounded-md xl:h-360 lg:h-380 md:h-250 h-250"
            style={{ border: "0" }}
            loading="lazy"
            allowfullscreen
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15546.817828239811!2d80.2518004!3d13.0544746!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267e10b9fcc83%3A0xcbcf7bf32d6b5921!2sMarslab%20Intelligence!5e0!3m2!1sen!2sin!4v1710250050492!5m2!1sen!2sin"
          ></iframe>
        </div>
      </div>

      {/* <div className="container overflow-hidden mx-auto xl:px-24 px-4">
  <div className="xl:mt-0 lg:mt-64 md:mt-72 mt-100">
    <iframe
      className="xl:w-full rounded-md xl:h-360 lg:w-990 lg:h-250 md:w-730 md:h-250 w-350 h-250"
      loading="lazy"
      allowfullscreen
      src="https://www.bing.com/search?pglt=41&q=Marslab+Intelligence&cvid=8e098b6ff6304dee81fa40d32dd5652a&gs_lcrp=EgZjaHJvbWUqBggCEEUYOzIGCAAQRRg8MgYIARBFGDsyBggCEEUYOzIGCAMQRRg5MgYIBBBFGDwyBggFEEUYPDIGCAYQRRg80gEIMzk3MmowajGoAgCwAgA&FORM=ANNTA1&PC=HCTS"
      ></iframe>
  </div>
</div> */}

      <section id="enquiry">
        <div className="font container mx-auto relative overflow-hidden xl:px-16 xl:px-52 lg:px-52 md:px-44 px-4 xl:py-8 lg:py-0 space-y-6 lg:space-y-2 pb-4 xl:pt-0 pt-4">
          <div className="tab-buttons text-center">
            <div className="grid xl:grid-cols-1 md:grid-cols-1 grid-cols-1 border-b-c xl:pt-12 lg:pt-4 md:pt-0 pt-0 xl:gap-0 gap-2 ">
              <button
                className={`${
                  activeTab === 1
                    ? "active xl:text-xl text-xs"
                    : "border-sky-600"
                } font font-semibold mx-4 xl:text-xl text-base xl:pt-2 xl:py-0 py-2 focus:outline-none`}
                onClick={() => handleTabClick(1)}
              >
                <div className="flex center-button">
                  <p className="xl:text-xl xl:mb-2">Enquiry Us</p>
                </div>
              </button>
            </div>
          </div>
        </div>
      </section>

      <div className="font container mx-auto relative overflow-hidden xl:px-16 px-4">
        <div className="tab-content text-center">
          <div className="container mx-auto xl:px-16 md:px-16 px-0">
            <div className="grid xl:grid-cols-1 text-left">
              <form
                action="https://forms.zohopublic.in/marslabprojects/form/DataCollector/formperma/baduHnLCVRQA8GDypRarLIWfpeZ1HQlfA0OKXq8c2do/htmlRecords/submit"
                method="POST"
                onSubmit={formik.handleSubmit}
                id="myForm"
              >
                <input type="hidden" name="zf_referrer_name" value="" />
                <input type="hidden" name="zf_redirect_url" value="" />
                <input type="hidden" name="zc_gad" value="" />
                <div className="grid xl:grid-cols-2 xl:gap-4 lg:gap-3 px-0 md:grid-cols-2 md:gap-2 gap-2 md:pt-8 pt-4 grid-cols-1 xl:px-20 lg:px-4 xl:pt-0 lg:pt-8">
                  <div>
                    {/* <label htmlFor="firstName" className="text-ivory-black">
                        First Name:
                      </label> */}
                    <input
                      type="text"
                      id="firstName"
                      name="Name_First"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Name_First}
                      className="rounded-md xl:w-11/12 lg:w-11/12 md:w-11/12 w-12/12 border-slate-400"
                      placeholder="Enter First Name"
                    />
                    {formik.touched.Name_First && formik.errors.Name_First ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.Name_First}
                      </div>
                    ) : null}
                  </div>
                  <div>
                    {/* <label htmlFor="lastName">Last Name:</label> */}
                    <input
                      type="text"
                      id="lastName"
                      name="Name_Last"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Name_Last}
                      placeholder="Enter Last Name"
                      className="rounded-md xl:w-11/12 lg:w-11/12 md:w-11/12 w-12/12 border-slate-400"
                    />
                    {formik.touched.Name_Last && formik.errors.Name_Last ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.Name_Last}
                      </div>
                    ) : null}
                  </div>
                  <div>
                    {/* <label htmlFor="countryCode">Country Code:</label> */}
                    <input
                      type="text"
                      id="countryCode"
                      name="PhoneNumber_countrycodeval"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.PhoneNumber_countrycodeval}
                      placeholder="Enter Country Code"
                      className="rounded-md xl:w-11/12 lg:w-11/12 md:w-11/12 w-12/12 border-slate-400"
                    />
                    {formik.touched.PhoneNumber_countrycodeval &&
                    formik.errors.PhoneNumber_countrycodeval ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.PhoneNumber_countrycodeval}
                      </div>
                    ) : null}
                  </div>
                  <div>
                    {/* <label htmlFor="phoneNumber">Phone Number:</label> */}
                    <input
                      type="text"
                      id="phoneNumber"
                      name="PhoneNumber_countrycode"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.PhoneNumber_countrycode}
                      placeholder="Enter Phone Number"
                      className="rounded-md xl:w-11/12 lg:w-11/12 md:w-11/12 w-12/12 border-slate-400"
                    />
                    {formik.touched.PhoneNumber_countrycode &&
                    formik.errors.PhoneNumber_countrycode ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.PhoneNumber_countrycode}
                      </div>
                    ) : null}
                  </div>
                  <div>
                    {/* <label htmlFor="email">Email Address:</label> */}
                    <input
                      type="text"
                      id="email"
                      name="Email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Email}
                      placeholder="Enter Email Address"
                      className="rounded-md xl:w-11/12 lg:w-11/12 md:w-11/12 w-12/12 border-slate-400"
                    />
                    {formik.touched.Email && formik.errors.Email ? (
                      <div style={{ color: "red" }}>{formik.errors.Email}</div>
                    ) : null}
                  </div>
                  <div>
                    {/* <label htmlFor="companyName">Company Name:</label> */}
                    <input
                      type="text"
                      id="companyName"
                      name="SingleLine"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.SingleLine}
                      placeholder="Enter Company Name"
                      className="rounded-md xl:w-11/12 lg:w-11/12 md:w-11/12 w-12/12 border-slate-400"
                    />
                    {formik.touched.SingleLine && formik.errors.SingleLine ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.SingleLine}
                      </div>
                    ) : null}
                  </div>
                  <div>
                    {/* <label htmlFor="streetAddress">Street Address:</label> */}
                    <input
                      type="text"
                      id="streetAddress"
                      name="Address_AddressLine1"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Address_AddressLine1}
                      placeholder="Enter Street Address"
                      className="rounded-md xl:w-11/12 lg:w-11/12 md:w-11/12 w-12/12 border-slate-400"
                    />
                    {formik.touched.Address_AddressLine1 &&
                    formik.errors.Address_AddressLine1 ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.Address_AddressLine1}
                      </div>
                    ) : null}
                  </div>
                  <div>
                    {/* <label htmlFor="addressLine2">Address Line 2:</label> */}
                    <input
                      type="text"
                      id="addressLine2"
                      name="Address_AddressLine2"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Address_AddressLine2}
                      placeholder="Enter Address Line 2"
                      className="rounded-md xl:w-11/12 lg:w-11/12 md:w-11/12 w-12/12 border-slate-400"
                    />
                    {formik.touched.Address_AddressLine2 &&
                    formik.errors.Address_AddressLine2 ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.Address_AddressLine2}
                      </div>
                    ) : null}
                  </div>
                  <div>
                    {/* <label htmlFor="city">City:</label> */}
                    <input
                      type="text"
                      id="city"
                      name="Address_City"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Address_City}
                      placeholder="Enter City"
                      className="rounded-md xl:w-11/12 lg:w-11/12 md:w-11/12 w-12/12 border-slate-400"
                    />
                    {formik.touched.Address_City &&
                    formik.errors.Address_City ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.Address_City}
                      </div>
                    ) : null}
                  </div>
                  <div>
                    {/* <label htmlFor="states">State/Region :</label> */}
                    <input
                      type="text"
                      id="states"
                      name="Address_Region"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Address_Region}
                      placeholder="Enter State/Region"
                      className="rounded-md xl:w-11/12 lg:w-11/12 md:w-11/12 w-12/12 border-slate-400"
                    />
                    {formik.touched.Address_Region &&
                    formik.errors.Address_Region ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.Address_Region}
                      </div>
                    ) : null}
                  </div>
                  <div>
                    {/* <label htmlFor="postal_code">Postal / Zip Code:</label> */}
                    <input
                      type="text"
                      id="postal_code"
                      name="Address_ZipCode"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Address_ZipCode}
                      placeholder="Enter Postal Code"
                      className="rounded-md xl:w-11/12 lg:w-11/12 md:w-11/12 w-12/12 border-slate-400"
                    />
                    {formik.touched.Address_ZipCode &&
                    formik.errors.Address_ZipCode ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.Address_ZipCode}
                      </div>
                    ) : null}
                  </div>
                  <div>
                    {/* <label htmlFor="country">Country:</label> */}
                    <select
                      id="country"
                      name="Address_Country"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Address_Country}
                      placeholder="Enter Country"
                      className="rounded-md xl:w-11/12 lg:w-11/12 md:w-11/12 w-12/12 border-slate-400"
                    >
                      <option value="&Aring;land Islands">
                        &Aring;land Islands
                      </option>
                      <option value="Afghanistan">Afghanistan</option>
                      <option value="Akrotiri">Akrotiri</option>
                      <option value="Albania">Albania</option>
                      <option value="Algeria">Algeria</option>
                      <option value="American Samoa">American Samoa</option>
                      <option value="Andorra">Andorra</option>
                      <option value="Angola">Angola</option>
                      <option value="Anguilla">Anguilla</option>
                      <option value="Antarctica">Antarctica</option>
                      <option value="Antigua and Barbuda">
                        Antigua and Barbuda
                      </option>
                      <option value="Argentina">Argentina</option>
                      <option value="Armenia">Armenia</option>
                      <option value="Aruba">Aruba</option>
                      <option value="Ashmore and Cartier Islands">
                        Ashmore and Cartier Islands
                      </option>
                      <option value="Australia">Australia</option>
                      <option value="Austria">Austria</option>
                      <option value="Azerbaijan">Azerbaijan</option>
                      <option value="Bahrain">Bahrain</option>
                      <option value="Bangladesh">Bangladesh</option>
                      <option value="Barbados">Barbados</option>
                      <option value="Bassas Da India">Bassas Da India</option>
                      <option value="Belarus">Belarus</option>
                      <option value="Belgium">Belgium</option>
                      <option value="Belize">Belize</option>
                      <option value="Benin">Benin</option>
                      <option value="Bermuda">Bermuda</option>
                      <option value="Bhutan">Bhutan</option>
                      <option value="Bolivia">Bolivia</option>
                      <option value="Bosnia and Herzegovina">
                        Bosnia and Herzegovina
                      </option>
                      <option value="Botswana">Botswana</option>
                      <option value="Bouvet Island">Bouvet Island</option>
                      <option value="Brazil">Brazil</option>
                      <option value="British Indian Ocean Territory">
                        British Indian Ocean Territory
                      </option>
                      <option value="British Virgin Islands">
                        British Virgin Islands
                      </option>
                      <option value="Brunei">Brunei</option>
                      <option value="Bulgaria">Bulgaria</option>
                      <option value="Burkina Faso">Burkina Faso</option>
                      <option value="Burma">Burma</option>
                      <option value="Burundi">Burundi</option>
                      <option value="Cambodia">Cambodia</option>
                      <option value="Cameroon">Cameroon</option>
                      <option value="Canada">Canada</option>
                      <option value="Cape Verde">Cape Verde</option>
                      <option value="Caribbean Netherlands">
                        Caribbean Netherlands
                      </option>
                      <option value="Cayman Islands">Cayman Islands</option>
                      <option value="Central African Republic">
                        Central African Republic
                      </option>
                      <option value="Chad">Chad</option>
                      <option value="Chile">Chile</option>
                      <option value="China">China</option>
                      <option value="Christmas Island">Christmas Island</option>
                      <option value="Clipperton Island">
                        Clipperton Island
                      </option>
                      <option value="Cocos (Keeling) Islands">
                        Cocos (Keeling) Islands
                      </option>
                      <option value="Colombia">Colombia</option>
                      <option value="Comoros">Comoros</option>
                      <option value="Cook Islands">Cook Islands</option>
                      <option value="Coral Sea Islands">
                        Coral Sea Islands
                      </option>
                      <option value="Costa Rica">Costa Rica</option>
                      <option value="Cote D&#x27;Ivoire">
                        Cote D&#x27;Ivoire
                      </option>
                      <option value="Croatia">Croatia</option>
                      <option value="Cuba">Cuba</option>
                      <option value="Cura&ccedil;ao">Cura&ccedil;ao</option>
                      <option value="Cyprus">Cyprus</option>
                      <option value="Czech Republic">Czech Republic</option>
                      <option value="Democratic Republic of the Congo">
                        Democratic Republic of the Congo
                      </option>
                      <option value="Denmark">Denmark</option>
                      <option value="Dhekelia">Dhekelia</option>
                      <option value="Djibouti">Djibouti</option>
                      <option value="Dominica">Dominica</option>
                      <option value="Dominican Republic">
                        Dominican Republic
                      </option>
                      <option value="Ecuador">Ecuador</option>
                      <option value="Egypt">Egypt</option>
                      <option value="El Salvador">El Salvador</option>
                      <option value="Equatorial Guinea">
                        Equatorial Guinea
                      </option>
                      <option value="Eritrea">Eritrea</option>
                      <option value="Estonia">Estonia</option>
                      <option value="Ethiopia">Ethiopia</option>
                      <option value="Europa Island">Europa Island</option>
                      <option value="Falkland Islands (Islas Malvinas)">
                        Falkland Islands (Islas Malvinas)
                      </option>
                      <option value="Faroe Islands">Faroe Islands</option>
                      <option value="Federated States of Micronesia">
                        Federated States of Micronesia
                      </option>
                      <option value="Fiji">Fiji</option>
                      <option value="Finland">Finland</option>
                      <option value="France">France</option>
                      <option value="French Guiana">French Guiana</option>
                      <option value="French Polynesia">French Polynesia</option>
                      <option value="French Southern and Antarctic Lands">
                        French Southern and Antarctic Lands
                      </option>
                      <option value="Gabon">Gabon</option>
                      <option value="Gaza Strip">Gaza Strip</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Germany">Germany</option>
                      <option value="Ghana">Ghana</option>
                      <option value="Gibraltar">Gibraltar</option>
                      <option value="Glorioso Islands">Glorioso Islands</option>
                      <option value="Greece">Greece</option>
                      <option value="Greenland">Greenland</option>
                      <option value="Grenada">Grenada</option>
                      <option value="Guadeloupe">Guadeloupe</option>
                      <option value="Guam">Guam</option>
                      <option value="Guatemala">Guatemala</option>
                      <option value="Guernsey">Guernsey</option>
                      <option value="Guinea">Guinea</option>
                      <option value="Guinea-bissau">Guinea-bissau</option>
                      <option value="Guyana">Guyana</option>
                      <option value="Haiti">Haiti</option>
                      <option value="Heard Island and Mcdonald Islands">
                        Heard Island and Mcdonald Islands
                      </option>
                      <option value="Holy See (Vatican City)">
                        Holy See (Vatican City)
                      </option>
                      <option value="Honduras">Honduras</option>
                      <option value="Hong Kong">Hong Kong</option>
                      <option value="Hungary">Hungary</option>
                      <option value="Iceland">Iceland</option>
                      <option value="India" selected>
                        India
                      </option>
                      <option value="Indonesia">Indonesia</option>
                      <option value="Iran">Iran</option>
                      <option value="Iraq">Iraq</option>
                      <option value="Ireland">Ireland</option>
                      <option value="Isle of Man">Isle of Man</option>
                      <option value="Israel">Israel</option>
                      <option value="Italy">Italy</option>
                      <option value="Jamaica">Jamaica</option>
                      <option value="Jan">Jan Mayen</option>
                      <option value="Japan">Japan</option>
                      <option value="Jersey">Jersey</option>
                      <option value="Jordan">Jordan</option>
                      <option value="Juan De Nova Island">
                        Juan De Nova Island
                      </option>
                      <option value="Kazakhstan">Kazakhstan</option>
                      <option value="Kenya">Kenya</option>
                      <option value="Kiribati">Kiribati</option>
                      <option value="Kosovo">Kosovo</option>
                      <option value="Kuwait">Kuwait</option>
                      <option value="Kyrgyzstan">Kyrgyzstan</option>
                      <option value="Laos">Laos</option>
                      <option value="Latvia">Latvia</option>
                      <option value="Lebanon">Lebanon</option>
                      <option value="Lesotho">Lesotho</option>
                      <option value="Liberia">Liberia</option>
                      <option value="Libya">Libya</option>
                      <option value="Liechtenstein">Liechtenstein</option>
                      <option value="Lithuania">Lithuania</option>
                      <option value="Luxembourg">Luxembourg</option>
                      <option value="Macau">Macau</option>
                      <option value="Macedonia">Macedonia</option>
                      <option value="Madagascar">Madagascar</option>
                      <option value="Malawi">Malawi</option>
                      <option value="Malaysia">Malaysia</option>
                      <option value="Maldives">Maldives</option>
                      <option value="Mali">Mali</option>
                      <option value="Malta">Malta</option>
                      <option value="Marshall">Marshall Islands</option>
                      <option value="Martinique">Martinique</option>
                      <option value="Mauritania">Mauritania</option>
                      <option value="Mauritius">Mauritius</option>
                      <option value="Mayotte">Mayotte</option>
                      <option value="Mexico">Mexico</option>
                      <option value="Moldova">Moldova</option>
                      <option value="Monaco">Monaco</option>
                      <option value="Mongolia">Mongolia</option>
                      <option value="Montenegro">Montenegro</option>
                      <option value="Montserrat">Montserrat</option>
                      <option value="Morocco">Morocco</option>
                      <option value="Mozambique">Mozambique</option>
                      <option value="Myanmar">Myanmar</option>
                      <option value="Namibia">Namibia</option>
                      <option value="Nauru">Nauru</option>
                      <option value="Navassa Island">Navassa Island</option>
                      <option value="Nepal">Nepal</option>
                      <option value="Netherlands">Netherlands</option>
                      <option value="Netherlands Antilles">
                        Netherlands Antilles
                      </option>
                      <option value="New Caledonia">New Caledonia</option>
                      <option value="New Zealand">New Zealand</option>
                      <option value="Nicaragua">Nicaragua</option>
                      <option value="Niger">Niger</option>
                      <option value="Nigeria">Nigeria</option>
                      <option value="Niue">Niue</option>
                      <option value="Norfolk Island">Norfolk Island</option>
                      <option value="North Korea">North Korea</option>
                      <option value="Northern Mariana Islands">
                        Northern Mariana Islands
                      </option>
                      <option value="Norway">Norway</option>
                      <option value="Oman">Oman</option>
                      <option value="Pakistan">Pakistan</option>
                      <option value="Palau">Palau</option>
                      <option value="Palestine">Palestine</option>
                      <option value="Panama">Panama</option>
                      <option value="Papua New Guinea">Papua New Guinea</option>
                      <option value="Paracel Islands">Paracel Islands</option>
                      <option value="Paraguay">Paraguay</option>
                      <option value="Peru">Peru</option>
                      <option value="Philippines">Philippines</option>
                      <option value="Pitcairn Islands">Pitcairn Islands</option>
                      <option value="Poland">Poland</option>
                      <option value="Portugal">Portugal</option>
                      <option value="Puerto Rico">Puerto Rico</option>
                      <option value="Qatar">Qatar</option>
                      <option value="Republic of the Congo">
                        Republic of the Congo
                      </option>
                      <option value="Reunion">Reunion</option>
                      <option value="Romania">Romania</option>
                      <option value="Russia">Russia</option>
                      <option value="Rwanda">Rwanda</option>
                      <option value="Saint Barth&Atilde;&copy;lemy">
                        Saint Barth&Atilde;&copy;lemy
                      </option>
                      <option value="Saint Helena">Saint Helena</option>
                      <option value="Saint Kitts and Nevis">
                        Saint Kitts and Nevis
                      </option>
                      <option value="Saint Lucia">Saint Lucia</option>
                      <option value="Saint Martin">Saint Martin</option>
                      <option value="Saint Pierre and Miquelon">
                        Saint Pierre and Miquelon
                      </option>
                      <option value="Saint Vincent and the Grenadines">
                        Saint Vincent and the Grenadines
                      </option>
                      <option value="Samoa">Samoa</option>
                      <option value="San Marino">San Marino</option>
                      <option value="Sao Tome and Principe">
                        Sao Tome and Principe
                      </option>
                      <option value="Saudi Arabia">Saudi Arabia</option>
                      <option value="Senegal">Senegal</option>
                      <option value="Serbia">Serbia</option>
                      <option value="Seychelles">Seychelles</option>
                      <option value="Sierra Leone">Sierra Leone</option>
                      <option value="Singapore">Singapore</option>
                      <option value="Sint Maarten">Sint Maarten</option>
                      <option value="Slovakia">Slovakia</option>
                      <option value="Slovenia">Slovenia</option>
                      <option value="Solomon Islands">Solomon Islands</option>
                      <option value="Somalia">Somalia</option>
                      <option value="South Africa">South Africa</option>
                      <option value="South Georgia and the South Sandwich Islands">
                        South Georgia and the South Sandwich Islands
                      </option>
                      <option value="South Korea">South Korea</option>
                      <option value="South Sudan">South Sudan</option>
                      <option value="Spain">Spain</option>
                      <option value="Spratly Islands">Spratly Islands</option>
                      <option value="Sri Lanka">Sri Lanka</option>
                      <option value="Sudan">Sudan</option>
                      <option value="Suriname">Suriname</option>
                      <option value="Svalbard">Svalbard</option>
                      <option value="Swaziland">Swaziland</option>
                      <option value="Sweden">Sweden</option>
                      <option value="Switzerland">Switzerland</option>
                      <option value="Syria">Syria</option>
                      <option value="Taiwan">Taiwan</option>
                      <option value="Tajikistan">Tajikistan</option>
                      <option value="Tanzania">Tanzania</option>
                      <option value="Thailand">Thailand</option>
                      <option value="The Bahamas">The Bahamas</option>
                      <option value="The Gambia">The Gambia</option>
                      <option value="Timor-leste">Timor-leste</option>
                      <option value="Togo">Togo</option>
                      <option value="Tokelau">Tokelau</option>
                      <option value="Tonga">Tonga</option>
                      <option value="Trinidad and Tobago">
                        Trinidad and Tobago
                      </option>
                      <option value="Tromelin Island">Tromelin Island</option>
                      <option value="Tunisia">Tunisia</option>
                      <option value="Turkey">Turkey</option>
                      <option value="Turkmenistan">Turkmenistan</option>
                      <option value="Turks and Caicos Islands">
                        Turks and Caicos Islands
                      </option>
                      <option value="Tuvalu">Tuvalu</option>
                      <option value="Uganda">Uganda</option>
                      <option value="Ukraine">Ukraine</option>
                      <option value="United Arab Emirates">
                        United Arab Emirates
                      </option>
                      <option value="United Kingdom">United Kingdom</option>
                      <option value="United States">United States</option>
                      <option value="Uruguay">Uruguay</option>
                      <option value="Uzbekistan">Uzbekistan</option>
                      <option value="Vanuatu">Vanuatu</option>
                      <option value="Venezuela">Venezuela</option>
                      <option value="Vietnam">Vietnam</option>
                      <option value="Virgin Islands">Virgin Islands</option>
                      <option value="Wake Island">Wake Island</option>
                      <option value="Wallis and Futuna">
                        Wallis and Futuna
                      </option>
                      <option value="West Bank">West Bank</option>
                      <option value="Western Sahara">Western Sahara</option>
                      <option value="Yemen">Yemen</option>
                      <option value="Zambia">Zambia</option>
                      <option value="Zimbabwe">Zimbabwe</option>
                    </select>
                    {formik.touched.Address_Country &&
                    formik.errors.Address_Country ? (
                      <div className="text-red">
                        {formik.errors.Address_Country}
                      </div>
                    ) : null}
                  </div>
                  {/* <div></div> */}
                  <label className="xl:pt-4 pt-4 xl:text-lg text-danger font-semibold">
                    Services:
                  </label>
                  {/* <div></div> */}
                  <div className="xl:pt-4 ">
                    <label>
                      <input
                        type="checkbox"
                        name="Checkbox"
                        value="Application Delivery Service (Citrix /TSPlus /Winflector)"
                        checked={formik.values.Checkbox.includes(
                          "Application Delivery Service (Citrix /TSPlus /Winflector)"
                        )}
                        onChange={formik.handleChange}
                      />
                      <span class="pl-2">
                        Application Delivery Service (Citrix /TSPlus
                        /Winflector)
                      </span>
                    </label>
                  </div>

                  <div>
                    <label>
                      <input
                        type="checkbox"
                        name="Checkbox"
                        value="Application Migration to Hybrid Cloud"
                        checked={formik.values.Checkbox.includes(
                          "Application Migration to Hybrid Cloud"
                        )}
                        onChange={formik.handleChange}
                      />
                      <span class="pl-2">
                        Application Migration to Hybrid Cloud
                      </span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        name="Checkbox"
                        value="Application Migration to Private Cloud"
                        checked={formik.values.Checkbox.includes(
                          "Application Migration to Private Cloud"
                        )}
                        onChange={formik.handleChange}
                      />
                      <span class="pl-2">
                        Application Migration to Private Cloud
                      </span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        name="Checkbox"
                        value="Application Migration to Public Cloud"
                        checked={formik.values.Checkbox.includes(
                          "Application Migration to Public Cloud"
                        )}
                        onChange={formik.handleChange}
                      />
                      <span class="pl-2">
                        Application Migration to Public Cloud
                      </span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        name="Checkbox"
                        value="Artificial Intelligence Solutions"
                        checked={formik.values.Checkbox.includes(
                          "Artificial Intelligence Solutions"
                        )}
                        onChange={formik.handleChange}
                      />
                      <span class="pl-2">
                        Artificial Intelligence Solutions
                      </span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        name="Checkbox"
                        value="DevOps Solutions (Micro Services)"
                        checked={formik.values.Checkbox.includes(
                          "DevOps Solutions (Micro Services)"
                        )}
                        onChange={formik.handleChange}
                      />
                      <span class="pl-2">
                        DevOps Solutions (Micro Services)
                      </span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        name="Checkbox"
                        value="Disaster Recovery Management Service"
                        checked={formik.values.Checkbox.includes(
                          "Disaster Recovery Management Service"
                        )}
                        onChange={formik.handleChange}
                      />
                      <span class="pl-2">
                        Disaster Recovery Management Service
                      </span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        name="Checkbox"
                        value="EDR &amp; XDR Solutions (AntiVirus Endpoint)"
                        checked={formik.values.Checkbox.includes(
                          "EDR &amp; XDR Solutions (AntiVirus Endpoint)"
                        )}
                        onChange={formik.handleChange}
                      />
                      <span class="pl-2">
                        EDR &amp; XDR Solutions (AntiVirus Endpoint)
                      </span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        name="Checkbox"
                        value="Facility Management Service Information Technology"
                        checked={formik.values.Checkbox.includes(
                          "Facility Management Service Information Technology"
                        )}
                        onChange={formik.handleChange}
                      />
                      <span class="pl-2">
                        Facility Management Service Information Technology
                      </span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        name="Checkbox"
                        value="Firewall Management"
                        checked={formik.values.Checkbox.includes(
                          "Firewall Management"
                        )}
                        onChange={formik.handleChange}
                      />
                      <span class="pl-2">Firewall Management</span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        name="Checkbox"
                        value="Governance Compliances (HIPAA /PCI-DSS /GDPR /ISO)"
                        checked={formik.values.Checkbox.includes(
                          "Governance Compliances (HIPAA /PCI-DSS /GDPR /ISO)"
                        )}
                        onChange={formik.handleChange}
                      />
                      <span class="pl-2">
                        Governance Compliances (HIPAA /PCI-DSS /GDPR /ISO)
                      </span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        name="Checkbox"
                        value="Hybrid Cloud Service"
                        checked={formik.values.Checkbox.includes(
                          "Hybrid Cloud Service"
                        )}
                        onChange={formik.handleChange}
                      />
                      <span class="pl-2">Hybrid Cloud Service</span>
                    </label>
                  </div>

                  <div>
                    <label>
                      <input
                        type="checkbox"
                        name="Checkbox"
                        value="Public Cloud Services (AWS /Azure /Google)"
                        checked={formik.values.Checkbox.includes(
                          "Public Cloud Services (AWS /Azure /Google)"
                        )}
                        onChange={formik.handleChange}
                      />
                      <span class="pl-2">
                        Public Cloud Services (AWS /Azure /Google)
                      </span>
                    </label>
                  </div>

                  <div>
                    <label>
                      <input
                        type="checkbox"
                        name="Checkbox"
                        value="Server Deployment (On-Prem /Colocation)"
                        checked={formik.values.Checkbox.includes(
                          "Server Deployment (On-Prem /Colocation)"
                        )}
                        onChange={formik.handleChange}
                      />
                      <span class="pl-2">
                        Server Deployment (On-Prem /Colocation)
                      </span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        name="Checkbox"
                        value="Storage Solutions (Offline /Online)"
                        checked={formik.values.Checkbox.includes(
                          "Storage Solutions (Offline /Online)"
                        )}
                        onChange={formik.handleChange}
                      />
                      <span class="pl-2">
                        Storage Solutions (Offline /Online)
                      </span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        name="Checkbox"
                        value="Virtual Desktop Delivery"
                        checked={formik.values.Checkbox.includes(
                          "Virtual Desktop Delivery"
                        )}
                        onChange={formik.handleChange}
                      />
                      <span class="pl-2">Virtual Desktop Delivery</span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        name="Checkbox"
                        value="Vulnerable Assessment Pen Testing (VAPT)"
                        checked={formik.values.Checkbox.includes(
                          "Vulnerable Assessment Pen Testing (VAPT)"
                        )}
                        onChange={formik.handleChange}
                      />
                      <span class="pl-2">
                        Vulnerable Assessment Pen Testing (VAPT)
                      </span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        name="Checkbox"
                        value="Mail Service (Google Workspace /MS 365 /Affordable Price Mail)"
                        checked={formik.values.Checkbox.includes(
                          "Mail Service (Google Workspace /MS 365 /Affordable Price Mail)"
                        )}
                        onChange={formik.handleChange}
                      />
                      <span class="pl-2">
                        Mail Service (Google Workspace /MS 365 /Affordable Price
                        Mail)
                      </span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        name="Checkbox"
                        value="Private Cloud Service (Vmware /Xen /KVM /Microsoft Hyper-V)"
                        checked={formik.values.Checkbox.includes(
                          "Private Cloud Service (Vmware /Xen /KVM /Microsoft Hyper-V)"
                        )}
                        onChange={formik.handleChange}
                      />
                      <span class="pl-2">
                        Private Cloud Service (Vmware /Xen /KVM /Microsoft
                        Hyper-V)
                      </span>
                    </label>
                  </div>
                  {formik.touched.Checkbox && formik.errors.Checkbox ? (
                    <div>{formik.errors.Checkbox}</div>
                  ) : null}
                </div>

                <div className="xl:px-20 xl:pt-8 md:pt-8 xl:ml-0 pt-4 ">
                  <label htmlFor="message">
                    Brief Your Requirement (if any):
                  </label>
                  <br></br>
                  <textarea
                    id="message"
                    name="MultiLine"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.MultiLine}
                    className="rounded-md xl:w-111/12 xl:mt-2 border-slate-400 md:w-full w-full"
                  />
                  {formik.touched.MultiLine && formik.errors.MultiLine ? (
                    <div>{formik.errors.MultiLine}</div>
                  ) : null}
                </div>
                <button className="font font-semibold uppercase xl:text-sm md:text-xs text-white buttonWithGradient rounded-lg xl:w-100/12 xl:ml-20 md:ml-0 mt-4 py-1 px-2 xl:mt-8 xl:mb-16 ml-0 w-40 md:w-full w-full mb-8 xl:py-4 md:py-2 md:mt-4">
                  submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Contactus;
