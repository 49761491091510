import Footer from "components/Footers/Footer";
import IndexNavbar from "components/Navbars/IndexNavbar";
import ApplicationContent2 from "./ApplicationContent2";
import ApplicationContent3 from "./ApplicationContent3";

const Applications = () => {
  return (
    <section>
      <IndexNavbar />
      <div className="xl:mt-20 lg:mt-22 mt-16">
        <div className="bg-stone-200 ">
          <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:py-4 lg:py-4 py-4">
            <a href="index" className="link">
              Home {"  "} {/* space purpose */}
            </a>
            {"  "}― {"  "}
            <a href="aboutt" className="link">
              Services {"  "}
            </a>
            {"  "} ― {"  "}
            Applications
          </div>
        </div>
      </div>

      {/* section */}

      <div className="xl:pt-16 md:px-4 px-4">
        <div className="xl:block lg:block md:block block font">
          <div className="container mx-auto relative overflow-hidden">
            <div className="xl:p-12 lg:p-0 md:p-0 lg:p-0">
              <p className="font xl:pt-2 lg:pt-20 md:pt-8 xl:text-5xl md:text-5xl lg:text-5xl text-3xl pt-4 md:pt-16 font-semibold md:text-xl text-slate-700 pb-2">
                Application Services
              </p>
              <div className="">
                <p className="font font-thin xl:text-xl lg:text-xl text-black  text-lg pb-4 xl:pt-4 md:pt-0">
                  Transform your applications to enable business agility and
                  innovation at scale
                </p>
              </div>
              <div>
                <button className="buttoncoffeesmall mb-8 md:mb-12 lg:mb-8">
                  Read the solution brief ⇥
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* section */}

      <div className="xl:block lg:block md:hidden hidden">
        <div className="bg-stone-200">
          <div className="container mx-auto relative overflow-hidden">
            <div className="font xl:px-16 xl:py-8 md:py-8 px-4  flex justify-between xl:mb-12 md:mb-8">
              <p className="xl:pt-4 md:pt-4 lg:pt-0">
                Kyndryl and Dynatrace forge a dynamic alliance for unrivalled
                observability and security dominance globally.
              </p>
              <button className="xl:buttoncoffeesmallone buttoncoffeesmallone">
                Read the press release →
              </button>
            </div>
          </div>
        </div>
      </div>
      
      <div className="xl:hidden lg:hidden md:block block">
        <div className="grid xl:grid-cols-0 md:grid-cols-1  font xl:px-16 xl:py-8 px-4 py-8 md:py-8 px-4 bg-stone-200 xl:mb-12 md:mb-8 mb-8">
          <p className="xl:pt-4 md:pt-0 pb-4">
            Kyndryl and Dynatrace forge a dynamic alliance for unrivalled
            observability and security dominance globally.
          </p>
          <button className="xl:buttoncoffeesmallone buttoncoffeesmallone text-left">
            Read the press release →
          </button>
        </div>
      </div>

      {/* section */}

      <ApplicationContent2 />
      <ApplicationContent3 />
      <Footer />
    </section>
  );
};

export default Applications;
