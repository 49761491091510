import React, { useState, useEffect } from "react";
import Footer from "components/Footers/Footer";
import IndexNavbar from "components/Navbars/IndexNavbar";
import marshlabbannerr from "assets/img/marshlabbannerr.jpg";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

const Careers = () => {
  const location = useLocation();
  const [enquiryClicked, setEnquiryClicked] = useState(false);

  const handleTalkToSalesClick = () => {
    window.location.href = "tel:+919176793939";
  };

  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const handleButtonClickMail = () => {
    window.location.href = "mailto:connect@marslab.work";
  };

  const formik = useFormik({
    initialValues: {
      Name_First: "",
      Name_Last: "",
      PhoneNumber_countrycodeval: "+91",
      PhoneNumber_countrycode: "",
      Email: "",
      SingleLine: "",
      Address_AddressLine1: "",
      Address_AddressLine2: "",
      Address_City: "",
      Address_Region: "",
      Address_ZipCode: "",
      Address_Country: "",
      Checkbox: [],
      requirement: "",
    },
    validationSchema: Yup.object({
      Name_First: Yup.string().required("Required"),
      Name_Last: Yup.string().required("Required"),
      PhoneNumber_countrycodeval: Yup.string().required("Required"),
      PhoneNumber_countrycode: Yup.string()
        .matches(/^[0-9]+$/, "Must be a number")
        .required("Required"),
      Email: Yup.string().email("Invalid email address").required("Required"),
      SingleLine: Yup.string().required("Required"),
      Address_AddressLine1: Yup.string().required("Required"),
      Address_City: Yup.string().required("Required"),
      Address_Region: Yup.string().required("Required"),
      Address_ZipCode: Yup.string().required("Required"),
      Address_Country: Yup.string().required("Required"),
      Checkbox: Yup.array().min(1, "Select at least one Service"),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const form = document.getElementById("myForm");
      form.submit();

      // Reset form after submission
      resetForm();
      setSubmitting(false);
    },
  });

  const formik1 = useFormik({
    initialValues: {
      Name_First: "",
      Name_Last: "",
      Date: "",
      PhoneNumber_countrycodeval: "+91",
      PhoneNumber_countrycode: "",
      Email: "",
      SingleLine: "",
      SingleLine1: "",
      Dropdown: "",
      Radio: "",
      Number: "",
      SingleLine2: "",
      MultiLine: "",
      FileUpload: null,
    },
    validationSchema: Yup.object({
      Name_First: Yup.string().required(),
      Name_Last: Yup.string().required(),
      Date: Yup.string()
        .matches(
          /^(0[1-9]|[12][0-9]|3[01])-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-\d{4}$/,
          "Invalid date format. Please use dd-Mon-yyyy"
        )
        .required("Date is required"),
      PhoneNumber_countrycodeval: Yup.string().required("Required"),
      PhoneNumber_countrycode: Yup.string()
        .matches(/^[0-9]+$/, "Must be a number")
        .required("Required"),
      Email: Yup.string().email("Invalid email address").required("Required"),
      SingleLine: Yup.string().required("Required"),
      SingleLine1: Yup.string().required("Required"),
      Dropdown: Yup.string().required("Required"),
      SingleLine2: Yup.string().required("Required"),
      FileUpload: Yup.mixed()
        .required("File is required")
        .test("fileType", "Unsupported file type", (value) => {
          return value && ["application/pdf"].includes(value.type);
        }),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const form1 = document.getElementById("myForm2");
      form1.submit();

      // Reset form after submission
      resetForm();
      setSubmitting(false);
    },
  });

  // Attach file name name will come
  const [fileName, setFileName] = useState("");

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    formik1.setFieldValue("FileUpload", file);
    setFileName(file.name); // Update file name in state
  };
  return (
    <section>
      <IndexNavbar />
      <div className="xl:mt-20 lg:mt-16 mt-16 xl:pb-8">
        <div
          style={{
            backgroundImage: `url(${marshlabbannerr})`,
            width: "100%",
          }}
          className="bg-ivory-black xl:h-60 md:h-60 h-48 relative"
        >
          <p className="font uppercase xl:text-4xl md:text-2xl text-xl text-white text-center xl:pt-28 md:pt-28  pt-20 font-semibold">
            Careers
          </p>
          <div className="container mx-auto relative overflow-hidden">
            
          </div>
        </div>
        <div style={{ backgroundColor: "white" }} className=""></div>
      </div>

      <section id="enquiry">
        <div className="font container mx-auto relative overflow-hidden xl:px-16 xl:px-52 lg:px-52 md:px-44 px-4 xl:py-8 lg:py-0 space-y-6 lg:space-y-2 pb-4 xl:pt-0 pt-8">
          <div className="tab-buttons text-center">
            
          </div>
        </div>
      </section>

      <div className="font container mx-auto relative overflow-hidden xl:px-16 px-4">
        <div className="tab-content text-center">
          
         
            <div className="container mx-auto xl:px-10  md:px-16 px-0">
              {/* <p className="font xl:text-3xl md:text-xl text-xl text-red text-center xl:mt-0 lg:mt-8 md:mt-0 mt-4 font-semibold">
                Drop us a line
              </p> */}
              <div className="grid xl:grid-cols-1 xl:px-20 text-left  xl:gap-4">
                <form
                  className="xl:mb-0 md:mb-20 mb-10"
                  action="https://forms.zohopublic.in/sales147/form/MarslabCareer/formperma/7KSgUE_bjq-3VtN8zHobgiGbJ2xCfAsyUYR4_c853ig/htmlRecords/submit"
                  method="POST"
                  id="myForm2"
                  enctype="multipart/form-data"
                  onSubmit={formik1.handleSubmit}
                >
                  <div className="grid xl:grid-cols-2 lg:grid-cols-2 grid-cols-1 md:grid-cols-2 md:gap-4 lg:gap-3 lg:pt-8 md:pt-8 pt-4  gap-2 xl:gap-4">
                    <div>
                      {/* <label htmlFor="firstName">First Name:</label> */}
                      <input
                        type="text"
                        id="firstName"
                        name="Name_First"
                        onChange={formik1.handleChange}
                        onBlur={formik1.handleBlur}
                        value={formik1.values.Name_First}
                        placeholder="Enter First Name"
                        className="rounded-md xl:w-11/12 lg:w-11/12 md:w-11/12 w-12/12 border-slate-400"
                      />
                      {formik1.touched.Name_First &&
                      formik1.errors.Name_First ? (
                        <div style={{ color: "red" }}>
                          {formik1.errors.Name_First}
                        </div>
                      ) : null}
                    </div>
                    <div>
                      {/* <label htmlFor="lastName">Last Name:</label> */}
                      <input
                        type="text"
                        id="lastName"
                        name="Name_Last"
                        onChange={formik1.handleChange}
                        onBlur={formik1.handleBlur}
                        value={formik1.values.Name_Last}
                        placeholder="Enter Last Name"
                        className="rounded-md xl:w-11/12 lg:w-11/12 md:w-11/12 w-12/12 border-slate-400"
                      />
                      {formik1.touched.Name_Last && formik1.errors.Name_Last ? (
                        <div style={{ color: "red" }}>
                          {formik1.errors.Name_Last}
                        </div>
                      ) : null}
                    </div>
                    <div>
                      {/* <label htmlFor="dateOfBirth">Date of Birth:</label> */}
                      <input
                        type="text"
                        id="dateOfBirth"
                        name="Date"
                        onChange={formik1.handleChange}
                        onBlur={formik1.handleBlur}
                        value={formik1.values.Date}
                        placeholder="Enter Date of Birth Eg: 01-Jan-2000"
                        className="rounded-md xl:w-11/12 lg:w-11/12 md:w-11/12 w-12/12 border-slate-400"
                      />
                      {formik1.touched.Date && formik1.errors.Date ? (
                        <div style={{ color: "red" }}>
                          {formik1.errors.Date}
                        </div>
                      ) : null}
                    </div>
                    <div>
                      {/* <label htmlFor="countryCode">Country Code:</label> */}
                      <input
                        type="text"
                        id="countryCode"
                        name="PhoneNumber_countrycodeval"
                        onChange={formik1.handleChange}
                        onBlur={formik1.handleBlur}
                        value={formik1.values.PhoneNumber_countrycodeval}
                        placeholder="Enter Country Code"
                        className="rounded-md xl:w-11/12 lg:w-11/12 md:w-11/12 w-12/12 border-slate-400"
                      />
                      {formik1.touched.PhoneNumber_countrycodeval &&
                      formik1.errors.PhoneNumber_countrycodeval ? (
                        <div style={{ color: "red" }}>
                          {formik1.errors.PhoneNumber_countrycodeval}
                        </div>
                      ) : null}
                    </div>
                    <div>
                      {/* <label htmlFor="phoneNumber">Phone Number:</label> */}
                      <input
                        type="text"
                        id="phoneNumber"
                        name="PhoneNumber_countrycode"
                        onChange={formik1.handleChange}
                        onBlur={formik1.handleBlur}
                        value={formik1.values.PhoneNumber_countrycode}
                        placeholder="Enter Phone Number"
                        className="rounded-md xl:w-11/12 lg:w-11/12 md:w-11/12 w-12/12 border-slate-400"
                      />
                      {formik1.touched.PhoneNumber_countrycode &&
                      formik1.errors.PhoneNumber_countrycode ? (
                        <div style={{ color: "red" }}>
                          {formik1.errors.PhoneNumber_countrycode}
                        </div>
                      ) : null}
                    </div>
                    <div>
                      {/* <label htmlFor="email">Email Address:</label> */}
                      <input
                        type="text"
                        id="email"
                        name="Email"
                        onChange={formik1.handleChange}
                        onBlur={formik1.handleBlur}
                        value={formik1.values.Email}
                        placeholder="Enter Email Address"
                        className="rounded-md xl:w-11/12 lg:w-11/12 md:w-11/12 w-12/12 border-slate-400"
                      />
                      {formik1.touched.Email && formik1.errors.Email ? (
                        <div style={{ color: "red" }}>
                          {formik1.errors.Email}
                        </div>
                      ) : null}
                    </div>
                    <div>
                      {/* <label htmlFor="country">Country:</label> */}
                      <input
                        type="text"
                        id="country"
                        name="SingleLine"
                        onChange={formik1.handleChange}
                        onBlur={formik1.handleBlur}
                        value={formik1.values.SingleLine}
                        placeholder="Enter Country"
                        className="rounded-md xl:w-11/12 lg:w-11/12 md:w-11/12 w-12/12 border-slate-400"
                      />
                      {formik1.touched.SingleLine &&
                      formik1.errors.SingleLine ? (
                        <div style={{ color: "red" }}>
                          {formik1.errors.SingleLine}
                        </div>
                      ) : null}
                    </div>
                    <div>
                      {/* <label htmlFor="state">State:</label> */}
                      <input
                        type="text"
                        id="state"
                        name="SingleLine1"
                        onChange={formik1.handleChange}
                        onBlur={formik1.handleBlur}
                        value={formik1.values.SingleLine1}
                        placeholder="Enter State"
                        className="rounded-md xl:w-11/12 lg:w-11/12 md:w-11/12 w-12/12 border-slate-400"
                      />
                      {formik1.touched.SingleLine1 &&
                      formik1.errors.SingleLine1 ? (
                        <div style={{ color: "red" }}>
                          {formik1.errors.SingleLine1}
                        </div>
                      ) : null}
                    </div>
                    <div>
                      {/* <label htmlFor="qualification">
                        Educational Qualification:
                      </label> */}
                      <select
                        id="qualification"
                        name="Dropdown"
                        onChange={formik1.handleChange}
                        onBlur={formik1.handleBlur}
                        value={formik1.values.Dropdown}
                        className="rounded-md xl:w-11/12 lg:w-11/12 md:w-11/12 w-12/12 border-slate-400"
                      >
                        <option selected value="">
                          Select Educational Qualification
                        </option>
                        <option value="Diploma">Diploma</option>
                        <option value="B.Sc">B.Sc</option>
                        <option value="B.E">B.E</option>
                        <option value="B.A">B.A</option>
                        <option value="B.Com">B.Com</option>
                        <option value="BCA">BCA</option>
                        <option value="M.Sc">M.Sc</option>
                        <option value="M.E">M.E</option>
                        <option value="M.A">M.A</option>
                        <option value="M.Com">M.Com</option>
                        <option value="MCA">MCA</option>
                        <option value="MBA">MBA</option>
                      </select>
                      {formik1.touched.Dropdown && formik1.errors.Dropdown ? (
                        <div>{formik1.errors.Dropdown}</div>
                      ) : null}
                    </div>

                    <div>
                      {/* <label htmlFor="experience">
                        Total Years of Experience:
                      </label> */}
                      <input
                        type="number"
                        id="experience"
                        name="Number"
                        onChange={formik1.handleChange}
                        onBlur={formik1.handleBlur}
                        value={formik1.values.Number}
                        placeholder="Enter Total Number of Experience"
                        className="rounded-md xl:w-11/12 lg:w-11/12 md:w-11/12 w-12/12 border-slate-400"
                      />
                      {formik1.touched.Number && formik1.errors.Number ? (
                        <div style={{ color: "red" }}>
                          {formik1.errors.Number}
                        </div>
                      ) : null}
                    </div>
                    <div>
                      {/* <label htmlFor="position">
                        Position You are Looking for:
                      </label> */}
                      {/* <label htmlFor="position">
                        Job Role are Looking for:
                      </label> */}
                      <input
                        type="text"
                        id="position"
                        name="SingleLine2"
                        onChange={formik1.handleChange}
                        onBlur={formik1.handleBlur}
                        value={formik1.values.SingleLine2}
                        placeholder="Enter Position You are Looking for"
                        className="rounded-md xl:w-11/12 lg:w-11/12 md:w-11/12 w-12/12 border-slate-400"
                      />
                      {formik1.touched.SingleLine2 &&
                      formik1.errors.SingleLine2 ? (
                        <div style={{ color: "red" }}>
                          {formik1.errors.SingleLine2}
                        </div>
                      ) : null}
                    </div>
                    <div className="xl:pt-0 lg:pt-0 md:pt-0 pt-2">
                      <label>Work Experience:</label>
                      <div>
                        <label>
                          <input
                            type="radio"
                            name="Radio"
                            value="Fresher"
                            checked={formik1.values.Radio === "Fresher"}
                            onChange={formik1.handleChange}
                            className="rounded-md xl:mt-4 lg:mt-4 md:mt-4 mt-4 border-slate-400"
                          />
                          <span className="pl-2">Fresher</span>
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="Radio"
                            value="Experienced"
                            checked={formik1.values.Radio === "Experienced"}
                            onChange={formik1.handleChange}
                            className="rounded-md xl:ml-17 border-slate-400"
                          />
                          <span className="pl-2">Experienced</span>
                        </label>
                      </div>
                      {formik1.touched.Radio && formik1.errors.Radio ? (
                        <div>{formik1.errors.Radio}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="xl:mt-5 lg:mt-0 md:mt-0 mt-4  xl:ml-0 lg:ml-4">
                    <label htmlFor="message">Tell About Yourself</label>
                    <br></br>
                    <textarea
                      id="message"
                      name="MultiLine"
                      onChange={formik1.handleChange}
                      onBlur={formik1.handleBlur}
                      value={formik1.values.MultiLine}
                      className="rounded-md  xl:mt-2 xl:w-111/12 lg:w-11/12 md:w-full w-full border-slate-400"
                    />
                    {formik1.touched.MultiLine && formik1.errors.MultiLine ? (
                      <div>{formik1.errors.MultiLine}</div>
                    ) : null}
                  </div>
                  <div className="xl:mt-8 xl:ml-0 lg:ml-4 mt-4 ml-4 lg:mt-8">
                    <label for="file" className="custom-file-upload">
                      Attach your updated resume
                    </label>
                    <input
                      type="file"
                      id="file"
                      name="FileUpload"
                      onChange={handleFileChange} // Call handleFileChange when file input changes
                      onBlur={formik1.handleBlur}
                    />
                    {/* Display file name if it exists */}
                    {fileName && <div>{fileName}</div>}
                    {/* Display formik error message if FileUpload field is touched and has errors */}
                    {formik1.touched.FileUpload && formik1.errors.FileUpload ? (
                      <div>{formik1.errors.FileUpload}</div>
                    ) : null}
                  </div>
                  <button className="font font-semibold uppercase xl:text-sm md:text-xs text-white buttonWithGradient rounded-lg xl:w-111/12 lg:w-11/12 w-full xl:ml-0 md:ml-0 lg:ml-2 lg:mt-8 mt-8 py-1 px-2 xl:mt-8 xl:mb-28 ml-0 w-40 md:w-full xl:py-4 md:py-2 md:mt-4">
                    Submit
                  </button>
                </form>
              </div>
            </div>
        
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Careers;
