import React, { useState } from "react";
import Footer from "components/Footers/Footer";
import IndexNavbar from "components/Navbars/IndexNavbar";
import marshlabbannerr from "assets/img/marshlabbannerr.jpg";
import saas1 from "assets/img/saas1.jpg";
import saas2 from "assets/img/saas2.png";
import saas3 from "assets/img/saas3.jpg";
import linux3 from "assets/img/linux3.png";
import database from "assets/img/database.jpg";
import cd1 from "assets/img/cd1.jpg";
import dba from "assets/img/dba.jpg";
import transfer from "assets/img/transfer.png";
import migrate from "assets/img/migrate.jpg";
import partner from "assets/img/partner.jpg";
import vm from "assets/img/vm.jpg";
import choosingmarslab from "assets/img/choosingmarslab.jpg";
import serverless from "assets/img/serverless.png";
import serverless2 from "assets/img/serverless2.png";
import container from "assets/img/container.jpg";
import k8 from "assets/img/k8.png";
import devops from "assets/img/devops.jpg";
import clientcentric from "assets/img/clientcentric.jpg";
import cloudnetworking from "assets/img/cloudnetworking.jpg";
import whymarslabnew from "assets/img/whymarslabnew.png";
import componentsofcloud from "assets/img/componentsofcloud.webp";
import networkingnew from "assets/img/networkingnew.jpeg";
import linuxnew from "assets/img/linuxnew.png";
import linuxdistributions from "assets/img/linuxdistributions.webp";
import SAP from "assets/img/SAP.jpg";
import SAP1 from "assets/img/SAP1.png";
import Dbaas from "assets/img/Dbaas.webp";
import keyconsider from "assets/img/keyconsider.png";
import cloudmigration from "assets/img/cloudmigration.jpg";
import linuxdist from "assets/img/linuxdist.png";
import whypartners from "assets/img/whypartners.jpg";

const Cloud = () => {
  const totalSteps = 3;
  const [currentStep, setCurrentStep] = useState(1);

  const handleNext = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrev = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const stepsContent = [
    {
      title: "Our Mission",
      content: (
        <div>
          <p>
            We are committed to providing comprehensive IT solutions that align
            with our customers' unique needs and aspirations. We strive to:
          </p>
        </div>
      ),
    },
    {
      title: "Application Security Audit Frameworks",
      content: (
        <div>
          <p>
            To be the premier IT solutions provider, recognized for our
            exceptional expertise, unwavering commitment to customer success,
            and dedication to ethical practices.
          </p>
        </div>
      ),
    },
    {
      title: "Our Values",
      content: (
        <div>
          <p>
            Our core values guide our every action and decision, ensuring that
            we remain a trusted partner for our customers:
          </p>
        </div>
      ),
    },
    {
      title: "Excellence",
      content: (
        <div>
          <p>
            Our core values guide our every action and decision, ensuring that
            we remain a trusted partner for our customers:
          </p>
        </div>
      ),
    },
  ];

  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <section>
      <IndexNavbar />
      <div className="xl:mt-20 lg:mt-22 mt-16">
        <div className="bg-stone-200 ">
          <div className="container mx-auto relative text-danger overflow-hidden xl:px-16 px-4 xl:py-4 lg:py-4 py-4">
            <a href="index" className="link text-ivory-black">
              Home {"  "} {/* space purpose */}
            </a>
            {"  "}― {"  "}
            <a href="aboutt" className="link">
              Services {"  "}
            </a>
            {"  "} ― {"  "}
            Cloud Engineering
          </div>
        </div>
      </div>

      {/* section */}

      <div className="">
        <div className="xl:block lg:block md:block block font">
          <div
            style={{
              backgroundImage: `url(${marshlabbannerr})`,
              width: "100%",
            }}
            className="bg-ivory-black xl:h-80 md:h-60 h-48 relative"
          >
            <p className="font uppercase xl:text-5xl md:text-2xl text-xl text-white text-center xl:pt-32 md:pt-28 pt-20 font-semibold">
              Cloud Engineering
            </p>
          </div>
        </div>
      </div>

      {/* Cyber threat awarness */}

      <div className="container mx-auto relative overflow-hidden xl:px-16 xl:py-16 px-4">
        <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-4 xl:gap-4">
          <div className="">
            <h1 className="font font-semibold xl:text-2xl pt-4 xl:pt-0 md:pt-4 lg:pt-4 text-red xl:pb-2 md:pb-2 lg:pb-2 pb-2">
              Windows Workloads (SaaS)
            </h1>
            <p className="font pt-2">
              MARSLAB specializes in delivering robust and tailored solutions
              for Windows workloads across diverse environments. Whether you're
              a small business, enterprise, or individual user, our team is
              dedicated to optimizing your computing experience with Windows
              operating systems.
            </p>
            <p className="font py-2">
              <ul className="list-none xl:pt-4 pt-4">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Applications and
                  services designed to run on Windows operating systems.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Known for their
                  user-friendliness, familiar interface, and wide range of
                  compatible software.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Databases: Microsoft
                  SQL Server.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2 ">✔</span> Business applications: SAP,
                  Oracle, Salesforce.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Web servers: Internet
                  Information Services (IIS).
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2 ">✔</span>File and print services:
                  Active Directory, File Server Resource Manager.
                </li>
              </ul>
            </p>
          </div>
          <div className="xl:pl-12 md:mt-8 xl:mt-0 mt-0">
            <img
              src={saas1}
              className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600"
              alt="Your browser does not support this part content"
            />
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 ">
          <div className="grid xl:grid-cols-2 xl:gap-6  md:grid-cols-2 md:gap-4 py-6">
            <div className="xl:block lg:block md:block hidden">
              <img
                src={saas2}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600 mt-12"
              ></img>
            </div>
            <div className="pt-4 xl:pl-12">
              <h2 className="font font-semibold xl:text-2xl xl:pt-14 xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                Industries Using Windows Workloads
              </h2>
              <ul className="list-none xl:pt-2 pt-2">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Finance: Banking,
                  insurance, financial services.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2 ">✔</span>Healthcare: Electronic health
                  records, medical imaging, patient management.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Manufacturing:
                  Production planning, supply chain management, quality control.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2 ">✔</span> Retail: Point-of-sale
                  systems, inventory management, customer relationship
                  management.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Education: Learning
                  systems, student information systems, classroom tools.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Government: Public
                  administration, law enforcement, social services.
                </li>
              </ul>
            </div>

            <div className="xl:hidden lg:hidden md:hidden block">
              <img
                src={saas2}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600 mt-12"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto relative overflow-hidden xl:px-16 xl:py-12 px-4 xl:pb-16">
        <div className="grid xl:grid-cols-2 xl:gap-4  md:grid-cols-2 md:gap-4">
          <div className="">
            <h1 className="font font-semibold xl:text-2xl xl:pt-20 ">
              Benefits of Windows Workloads
            </h1>

            <p className="font py-2">
              <ul className="list-none xl:pt-2 pt-2">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> User familiarity:
                  Most users are comfortable with Windows interfaces.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Wide software compatibility:
                  Extensive range of Windows-compatible applications.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Integration with
                  Microsoft technologies: Seamless interoperability with Office
                  365, Azure, and other Microsoft services.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2 ">✔</span>Strong vendor support:
                  Microsoft provides comprehensive support and updates.
                </li>
              </ul>
            </p>
          </div>
          <div className="xl:pl-12 md:pb-4 xl:pb-0 pb-0">
            <img
              src={saas3}
              className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600 xl:mt-4 mt-10"
              alt="Your browser does not support this part content"
            />
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 ">
          <div className="grid xl:grid-cols-2 xl:gap-6  md:grid-cols-2 md:gap-4 py-6">
            <div className="xl:block lg:block md:block hidden">
              <img
                src={linuxnew}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600 md:mt-12 xl:mt-20"
              ></img>
            </div>
            <div className="pt-4 xl:pl-12">
              <h2 className="text-red font font-semibold xl:text-2xl xl:pt-12 xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                Linux Workloads
              </h2>
              <p className="font pt-2">
                MARSLAB specializes in delivering robust and tailored solutions
                for Windows workloads across diverse environments. Whether
                you're a small business, enterprise, or individual user, our
                team is dedicated to optimizing your computing experience with
                Windows operating systems.
              </p>
              <p className="font py-2">
                <ul className="list-none xl:pt-2 pt-4">
                  <li className="font xl:text-base text-sm flex items-start ">
                    <span className="mr-2 text-red">✔</span> Applications and
                    services designed to run on Linux operating systems.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start py-3">
                    <span className="mr-2">✔</span> Known for their stability,
                    flexibility, open-source nature, and cost-effectiveness.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start ">
                    <span className="mr-2 text-red">✔</span> Web Servers:
                    Apache, Nginx.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start py-3">
                    <span className="mr-2 ">✔</span> Databases: MySQL,
                    PostgreSQL.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start ">
                    <span className="mr-2 text-red">✔</span> Content management
                    systems: WordPress, Drupal.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start py-3">
                    <span className="mr-2 ">✔</span>Business applications: ERP,
                    CRM.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start ">
                    <span className="mr-2 text-red">✔</span>Development Tools:
                    Git, Jenkins.
                  </li>
                </ul>
              </p>
            </div>
            <div className="xl:hidden lg:hidden md:hidden block">
              <img
                src={linuxnew}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600 xl:mt-20"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto relative overflow-hidden xl:px-16 xl:py-16 px-4">
        <div className="grid xl:grid-cols-2 xl:gap-4  md:grid-cols-2 md:gap-4">
          <div className="">
            <h1 className="font font-semibold xl:text-2xl pt-10 xl:pb-2 md:pb-2 lg:pb-2 pb-2">
              Industries Embracing Linux Workloads
            </h1>

            <p className="font py-2">
              <ul className="list-none xl:pt-2 pt-2">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Technology: Cloud
                  computing, web development, software engineering.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Finance: Trading systems, risk
                  management, financial modeling.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Healthcare:
                  Electronic health records, medical imaging, patient data
                  management.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2 ">✔</span>Manufacturing: Industrial
                  automation, robotics, supply chain management.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span>Retail: E-commerce
                  platforms, inventory management, customer analytics.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2 ">✔</span> Education: Learning
                  management systems, online courses, research tools.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Government: Public
                  services, data management, cybersecurity.
                </li>
              </ul>
            </p>
          </div>
          <div className="xl:pl-12">
            <img
              src={linux3}
              className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 xl:mt-0 mt-0 md:mt-12 md:h-300 md:w-550 h-250 w-600"
              alt="Your browser does not support this part content"
            />
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 ">
          <div className="grid xl:grid-cols-2 xl:gap-6  md:grid-cols-2 md:gap-4 py-6">
            <div className="xl:block lg:block md:block hidden">
              <img
                src={linuxdist}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600 mt-16"
              ></img>
            </div>
            <div className="pt-4 xl:pl-12">
              <h2 className="font font-semibold xl:text-2xl xl:pt-12 xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                Benefits of Using Linux Workloads
              </h2>
              <p className="font py-2">
                <ul className="list-none xl:pt-2 pt-2">
                  <li className="font xl:text-base text-sm flex items-start ">
                    <span className="mr-2 text-red">✔</span> Cost-effectiveness:
                    Often free or lower-cost than proprietary operating systems.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start py-3">
                    <span className="mr-2">✔</span> Open-source nature: Access
                    to source code for customization and innovation.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start ">
                    <span className="mr-2 text-red">✔</span> Stability and
                    reliability: Known for uptime and security.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start py-3">
                    <span className="mr-2 ">✔</span> Flexibility: Runs on
                    various hardware and supports diverse applications.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start ">
                    <span className="mr-2 text-red">✔</span> Strong community
                    support: Vast resources, forums, and active development.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start py-3">
                    <span className="mr-2 ">✔</span> Popular Platforms for
                    Deploying Linux Workloads.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start ">
                    <span className="mr-2 text-red">✔</span> Cloud providers:
                    Amazon Web Services (AWS), Microsoft Azure, Google Cloud
                    Platform (GCP).
                  </li>
                </ul>
              </p>
            </div>
            <div className="xl:hidden lg:hidden md:hidden block">
              <img
                src={linuxdistributions}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600 xl:mt-16"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 ">
          <div className="grid xl:grid-cols-2 xl:gap-6  md:grid-cols-2 md:gap-4 py-6">
            <div className="pt-4">
              <h2 className="font font-semibold xl:text-2xl text-red xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                SAP Workloads
              </h2>
              <p className="font text-base pt-2">
                SAP workloads encompass applications and services designed to
                run on the SAP software platform, primarily focusing on
                enterprise resource planning (ERP) and related tools. These
                programs handle critical business functions like finance,
                accounting, human resources, supply chain management, and
                customer relationship management (CRM).
              </p>
              <p className="font text-base pt-2">
                Benefits of Running SAP Workloads
              </p>
              <p className="font text-base pt-2">
                <ul className="list-none xl:pt-4 pt-4">
                  <li className="font xl:text-base text-sm flex items-start ">
                    <span className="mr-2 text-red">✔</span>Streamlined business
                    processes: Centralized system improves efficiency and data
                    accuracy.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start py-3">
                    <span className="mr-2">✔</span> Real-time insights: HANA
                    platform enables faster decision-making based on immediate
                    data analysis.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start ">
                    <span className="mr-2 text-red">✔</span> Improved
                    collaboration: Unified platform facilitates communication
                    and data sharing across departments.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start py-3">
                    <span className="mr-2 ">✔</span> Scalability and
                    flexibility: Cloud options cater to fluctuating business
                    needs and growth.
                  </li>
                  <li className="font xl:text-base text-sm fitems-start flex ">
                    <span className="mr-2 text-red">✔</span> Compliance and
                    security: SAP offers robust security features and regulatory
                    compliance options.
                  </li>
                </ul>
              </p>
            </div>
            <div className="xl:pl-12 ">
              <img
                src={SAP}
                className=" rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600 mt-16"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 ">
          <div className="grid xl:grid-cols-2 xl:gap-6  md:grid-cols-2 md:gap-4 py-6">
            <div className="xl:block lg:block md:block hidden ">
              <img
                src={SAP1}
                className="rounded-md mt-4 xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 xl:mt-12 md:mt-8 h-250 w-600"
              ></img>
            </div>
            <div className="pt-0 xl:pl-12">
              <h2 className="font font-semibold xl:text-2xl xl:pt-12 xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                Popular Platforms for SAP Workloads
              </h2>
              <ul className="list-none xl:pt-4 pt-4">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> SAP HANA Enterprise
                  Cloud: SAP's own cloud platform tailored for SAP workloads.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Microsoft Azure: Robust cloud
                  infrastructure with dedicated SAP solutions and expertise.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Amazon Web Services
                  (AWS): Wide range of services and tools for hybrid and
                  cloud-based SAP deployments.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2 ">✔</span> Google Cloud Platform (GCP):
                  Scalable and secure cloud platform with growing SAP
                  capabilities.
                </li>
              </ul>
              <p className="font text-base ">
                MARSLAB provides a solid foundation for understanding SAP
                workloads. With careful planning and the right approach, you can
                leverage their power to streamline your business, gain real-time
                insights, and unlock operational excellence.
              </p>
            </div>
            <div className="xl:hidden lg:hidden md:hidden block">
              <img
                src={SAP1}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 pt-4 xl:pt-12 h-250 w-600"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 ">
          <div className="grid xl:grid-cols-2 xl:gap-6  md:grid-cols-2 md:gap-4 py-6">
            <div className="pt-4">
              <h2 className="font font-semibold xl:text-2xl text-red xl:pt-24 xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                Database As A Service
              </h2>
              <p className="font  xl:text-base">
                What is Database as a Service (DBaaS)?
              </p>
              <ul className="list-none xl:pt-4 pt-4 xl:pt-8">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> A cloud-based
                  database management service that allows organizations to
                  access and manage databases without the need to purchase,
                  install, or maintain physical hardware or software.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Providers manage the
                  underlying infrastructure, including hardware, software,
                  setup, configuration, maintenance, updates, and security.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Users access and
                  manage databases through a web interface or APIs.
                </li>
              </ul>
            </div>
            <div className="xl:pl-8">
              <img
                src={database}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600 mt-12"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-8">
          <div className="grid xl:grid-cols-2 xl:gap-6  md:grid-cols-2 md:gap-4 py-6">
            <div className="xl:block lg:block md:block hidden">
              <img
                src={Dbaas}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:mt-12 xl:mt-0 mt-0 md:h-300 md:w-550 h-250 xl:mt-16 w-600"
              ></img>
            </div>
            <div className="pt-4 xl:pl-12">
              <h2 className="font font-semibold xl:text-2xl xl:pt-4 xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                Key Benefits of DBaaS
              </h2>
              <ul className="list-none xl:pt-2 pt-2">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Cost savings:
                  Eliminates upfront hardware and software costs, as well as
                  ongoing maintenance expenses.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Scalability: Easily add or
                  remove resources to match changing business needs.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Accessibility:
                  Databases are accessible from anywhere with an internet
                  connection.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2 ">✔</span> Flexibility: Choose from
                  various database types and configurations to suit specific
                  requirements.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Faster deployment:
                  Set up databases quickly, without lengthy installation and
                  configuration processes.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Simplified management:
                  Providers handle tasks like patching, backups, and security,
                  freeing up IT resources.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> High availability:
                  Built-in redundancy ensures databases are always available.
                </li>
              </ul>
            </div>
            <div className="xl:hidden lg:hidden md:hidden block">
              <img
                src={Dbaas}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 pt-4 xl:pt-20 w-600"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-0">
          <div className="grid xl:grid-cols-2 xl:gap-6  md:grid-cols-2 md:gap-4 xl:py-12 py-6 xl:pl-48 xl:px-16">
            <div className="pt-4">
              <h2 className="font font-semibold xl:text-2xl text-red xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                Popular DBaaS Providers
              </h2>

              <ul className="list-none xl:pt-2 pt-2">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span>Amazon Relational
                  Database Service (RDS)
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Microsoft Azure SQL Database
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Google Cloud SQL
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Oracle Database Cloud Service
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> IBM Db2 on Cloud
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> MongoDB Atlas
                </li>
                <li className="font xl:text-base text-sm flex items-start">
                  <span className="mr-2 text-red">✔</span> Amazon Aurora
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> SAP HANA Cloud
                </li>
              </ul>
            </div>

            <div className="pt-4">
              <h2 className="font font-semibold xl:text-2xl text-red xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                Common Use Cases
              </h2>

              <ul className="list-none xl:pt-2 pt-2">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span>Web and mobile
                  applications.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Data analytics and business
                  intelligence.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> E-commerce platforms.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span>Content management systems.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Customer relationship
                  management (CRM) systems.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Enterprise resource planning
                  (ERP) systems.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 ">
          <div className="grid xl:grid-cols-2 xl:gap-6  md:grid-cols-2 md:gap-4 py-6">
            <div className="xl:pt-16 pt-8">
              <img
                src={keyconsider}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600"
              ></img>
            </div>
            <div className="pt-4 xl:pl-12">
              <h2 className="font font-semibold xl:text-2xl xl:pt-16 xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                Key Considerations
              </h2>
              <ul className="list-none xl:pt-2 pt-2">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Security: Ensure the
                  provider has robust security measures to protect sensitive
                  data.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Compliance: Verify compliance
                  with industry regulations, such as GDPR and HIPAA.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Data migration: Plan
                  for potential data migration to or from the DBaaS platform.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2 ">✔</span> Vendor lock-in: Assess the
                  ease of switching providers if needed.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Performance: Evaluate
                  performance levels for your specific workloads.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Cost: Compare pricing models
                  and consider factors like storage, compute, and data transfer
                  costs.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 ">
          <div className="grid xl:grid-cols-2 xl:gap-6  md:grid-cols-2 md:gap-4 py-6">
            <div className="pt-4">
              <h2 className="font font-semibold xl:text-2xl text-red xl:pt-16 xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                When to Consider MARSLAB on DBaaS
              </h2>

              <ul className="list-none xl:pt-2 pt-2">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Need to quickly
                  deploy databases without infrastructure investment.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Require scalability to handle
                  fluctuating workloads.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Lack in-house
                  database expertise or want to offload management tasks.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Desire high availability and
                  disaster recovery capabilities.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Value pay-as-you-go
                  pricing models for cost predictability.
                </li>
              </ul>
              <p className="font  xl:text-base xl:pt-4 md:pt-4">
                MARSLAB offers a convenient, cost-effective, and scalable
                solution for organizations seeking to simplify database
                management and focus on core business operations.
              </p>
            </div>
            <div className="xl:pl-12">
              <img
                src={dba}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600 xl:mt-12 md:mt-8"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 ">
          <div className="grid xl:grid-cols-2 xl:gap-6  md:grid-cols-2 md:gap-4 py-6">
            <div className="xl:block lg:block md:block hidden">
              <img
                src={cd1}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600 xl:mt-32 mt-12"
              ></img>
            </div>
            <div className="pt-4 xl:pl-12">
              <h2 className="font font-semibold xl:text-2xl text-red xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                Migration
              </h2>
              <p className="font  xl:text-base xl:pt-2 pt-2">
                Cloud migration refers to the process of moving your digital
                assets, including applications, data, workloads, and
                infrastructure, from on-premises servers to a cloud computing
                platform. It's like packing your IT suitcase and heading to a
                brand-new, dynamic data center in the sky.
              </p>
              <ul className="list-none xl:pt-2 pt-2">
                <li className="font xl:text-base text-sm  items-start flex">
                  <span className="mr-2 text-red">✔</span> Data migration:
                  Moving data from one storage system to another (e.g.,
                  on-premises database to cloud storage).
                </li>
                <li className="font xl:text-base text-sm  items-start py-3 flex">
                  <span className="mr-2">✔</span> Application migration:
                  Transferring an application from one platform to another
                  (e.g., on-premises server to cloud).
                </li>
                <li className="font xl:text-base text-sm  items-start flex">
                  <span className="mr-2 text-red">✔</span> System migration:
                  Moving an entire computing system to a new environment (e.g.,
                  physical servers to virtual machines).
                </li>
                <li className="font xl:text-base text-sm  items-start py-3 flex">
                  <span className="mr-2 ">✔</span> Software migration: Changing
                  software versions or platforms (e.g., upgrading from Windows 7
                  to Windows 10).
                </li>
                <li className="font xl:text-base text-sm  items-start flex">
                  <span className="mr-2 text-red">✔</span> Cloud migration:
                  Shifting IT infrastructure and resources to a cloud computing
                  platform (e.g., moving from AWS to Azure).
                </li>
                <li className="font xl:text-base text-sm  items-start py-3 flex">
                  <span className="mr-2">✔</span> Simplified management:
                  Providers handle tasks like patching, backups, and security,
                  freeing up IT resources.
                </li>
                <li className="font xl:text-base text-sm  items-start flex">
                  <span className="mr-2 text-red">✔</span> High availability:
                  Built-in redundancy ensures databases are always available.
                </li>
              </ul>
            </div>
            <div className="xl:hidden lg:hidden md:hidden block">
              <div className="xl:mt-8">
                <img
                  src={cd1}
                  className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600 "
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 ">
          <div className="grid xl:grid-cols-2 xl:gap-6  md:grid-cols-2 md:gap-4 py-6">
            <div className="pt-4">
              <h2 className="font font-semibold xl:text-2xl text-red xl:pt-20 xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                Transfer
              </h2>

              <ul className="list-none xl:pt-2 pt-2">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> File transfer:
                  Sharing files between devices or individuals.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Domain transfer: Changing the
                  ownership of a web domain.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Account transfer:
                  Moving funds or assets from one account to another.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Knowledge transfer: Sharing
                  skills and expertise from one person to another.
                </li>
              </ul>
              <p className="font  xl:text-base">
                May involve less complex procedures than migration and can be a
                one-time action.
              </p>
            </div>
            <div className="xl:pl-12 rounded-md">
              <img
                src={transfer}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600 mt-12"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 ">
          <div className="grid xl:grid-cols-2 xl:gap-6  md:grid-cols-2 md:gap-4 py-6">
            <div className="xl:block lg:block md:block hidden">
              <img
                src={migrate}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 xl:mt-16 md:mt-12 pt-0  w-600"
              ></img>
            </div>
            <div className="pt-4 xl:pl-12">
              <h2 className="font font-semibold xl:text-2xl xl:pt-8 xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                Why Migrate to the Cloud?
              </h2>

              <ul className="list-none xl:pt-2 pt-2">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Cost savings:
                  Eliminate expensive hardware and software maintenance,
                  potentially downsize your physical infrastructure, and enjoy
                  pay-as-you-go flexibility.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Scalability and agility:
                  Effortlessly adjust resources (think servers, storage) up or
                  down to meet business demands, enabling rapid growth and
                  innovation.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Accessibility and
                  mobility: Access your data and applications from anywhere with
                  an internet connection, empowering remote work and global
                  collaboration.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2 ">✔</span> Increased security: Leverage
                  enterprise-grade security features and disaster recovery
                  options available in most cloud platforms.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Enhanced performance:
                  Benefit from the robust infrastructure and high availability
                  offered by cloud providers, leading to faster processing and
                  fewer outages.
                </li>
              </ul>
            </div>
            <div className="xl:hidden lg:hidden md:hidden block">
              <img
                src={migrate}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 mt-4 xl:mt-16  w-600"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 ">
          <div className="grid xl:grid-cols-2 xl:gap-6  md:grid-cols-2 md:gap-4 py-6">
            <div className="pt-4">
              <h2 className="font font-semibold xl:text-2xl text-red xl:pb-2 xl:pt-4 md:pb-2 lg:pb-2 pb-2">
                Consulting
              </h2>
              <p className="font  xl:text-base">
                Cloud consulting involves leveraging the expertise of
                experienced professionals to help organizations adopt, migrate,
                optimize, and manage their cloud journey. They act as trusted
                advisors and strategic partners, offering a spectrum of
                services.
              </p>
              <ul className="list-none xl:pt-2 pt-2">
                <li className="font xl:text-base text-sm items-start flex">
                  <span className="mr-2 text-red">✔</span> Cloud strategy and
                  planning: Defining your cloud vision, goals, and roadmap for
                  maximizing its benefits.
                </li>
                <li className="font xl:text-base text-sm items-start py-3 flex">
                  <span className="mr-2">✔</span> Cloud migration: Planning,
                  executing, and managing the seamless transition of your
                  applications, data, and infrastructure to the cloud.
                </li>
                <li className="font xl:text-base text-sm items-start flex">
                  <span className="mr-2 text-red">✔</span> Cloud architecture
                  and design: Creating a secure, scalable, and cost-effective
                  cloud architecture tailored to your specific needs.
                </li>
                <li className="font xl:text-base text-sm items-start py-3 flex">
                  <span className="mr-2">✔</span>Cloud security:Implementing
                  robust security measures and compliance solutions to protect
                  your data and applications.
                </li>
                <li className="font xl:text-base text-sm items-start flex">
                  <span className="mr-2 text-red">✔</span> Cloud cost
                  optimization: Analyzing and optimizing your cloud spend to
                  ensure you're getting the most value out of your investment.
                </li>
                <li className="font xl:text-base text-sm items-start py-3 flex">
                  <span className="mr-2">✔</span> Cloud governance and
                  management: Establishing clear cloud governance frameworks and
                  ongoing management practices for sustained success.
                </li>
                <li className="font xl:text-base text-sm items-start ">
                  <span className="mr-2 text-red">✔</span> Cloud training and
                  enablement Empowering your team with the necessary skills and
                  knowledge to thrive in the cloud environment.
                </li>
              </ul>
            </div>
            <div className="xl:pl-12 xl:mt-28 mt-4">
              <img
                src={cloudmigration}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 ">
          <div className="grid xl:grid-cols-2 xl:gap-6  md:grid-cols-2 md:gap-4 py-6 ">
            <div className="xl:block lg:block md:block hidden ">
              <div className=" xl:mt-8">
                <img
                  src={whypartners}
                  className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600 xl:mt-16"
                ></img>
              </div>
            </div>
            <div className="pt-4 xl:pl-12">
              <h2 className="font font-semibold xl:text-2xl xl:pt-12 xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                Why Partner with a MARSLAB?
              </h2>

              <ul className="list-none xl:pt-4 pt-4">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Reduced risk and
                  complexity: Avoid potential pitfalls and navigate technical
                  challenges with expert guidance.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Faster time to value: Achieve
                  your cloud goals more quickly and efficiently with expert
                  planning and execution.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Increased cost
                  savings: Optimize your cloud infrastructure and utilization to
                  save money and avoid unnecessary expenses.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2 ">✔</span> Improved security and
                  compliance: Implement robust security measures and ensure
                  compliance with relevant regulations.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Enhanced scalability
                  and agility: Adapt to changing business needs with an
                  adaptable and scalable cloud architecture outages.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2 ">✔</span> Greater innovation and
                  competitive edge: Unlock the full potential of the cloud to
                  innovate and outperform the competition.
                </li>
              </ul>
            </div>
            <div className="xl:hidden lg:hidden md:hidden block">
              <img
                src={partner}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600 xl:pt-10 mt-8"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 ">
          <div className="grid xl:grid-cols-2 xl:gap-6  md:grid-cols-2 md:gap-4 py-6">
            <div className="pt-4">
              <h2 className="font font-semibold xl:text-2xl text-red xl:pt-12 xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                Virtual Machine
              </h2>
              <p className="font  xl:text-base">
                They're essentially software versions of computers, complete
                with their own operating systems, applications, and resources.
                Think of them as mini-universes running within your actual
                computer, granting you the power.
              </p>
              <ul className="list-none xl:pt-2 pt-2">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Need to run Windows
                  software while being a staunch macOS devotee? No problem! VMs
                  create self-contained environments, preventing software
                  conflicts and protecting your main system from unforeseen
                  glitches.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Worried about a rogue
                  application wreaking havoc? Launch it in a VM, and even if it
                  goes haywire, your main system stays safe.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Got a project
                  requiring different operating systems for testing? Don't
                  invest in multiple machines! VMs let you seamlessly switch
                  between Windows, macOS, Linux, and more, all on a single
                  device.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Develop for platforms you
                  don't own? VMs open doors to testing across the software
                  spectrum, ensuring compatibility and smooth user experiences.
                </li>
              </ul>
            </div>
            <div className="xl:pl-12 mt-16">
              <img
                src={vm}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600 "
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 ">
          <div className="grid xl:grid-cols-2 xl:gap-6  md:grid-cols-2 md:gap-4 py-6">
            <div className="xl:block lg:block md:block hidden">
              <img
                src={choosingmarslab}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600 mt-12"
              ></img>
            </div>
            <div className="pt-4 xl:pl-12">
              <h2 className="font font-semibold xl:text-2xl xl:pt-12 xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                Choosing the MARSLAB
              </h2>
              <ul className="list-none xl:pt-2 pt-2">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span>Containers: For
                  faster, lighter-weight deployments of microservices,
                  containers can be a better fit.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Cloud computing: If you need
                  scalability and flexibility without hardware constraints,
                  cloud-based VMs offer a compelling solution.
                </li>
                <h2 className="font font-semibold xl:text-2xl xl:pb-2 md:pb-2 lg:pb-2 pb-2 xl:pt-4 md:pt-4 lg:pt-4 pt-4">
                  Unlocking the VM Potential
                </h2>
                <li className="font xl:text-base text-sm flex items-start pt-2">
                  <span className="mr-2 text-red">✔</span> Enhance your
                  development workflow with diverse testing environments.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Run legacy software without
                  impacting your main system.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Design secure and
                  adaptable systems for your users.
                </li>
              </ul>
            </div>
            <div className="xl:hidden lg:hidden md:hidden block">
              <img
                src={choosingmarslab}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600 mt-12"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 ">
          <div className="grid xl:grid-cols-2 xl:gap-6  md:grid-cols-2 md:gap-4 py-6">
            <div className="pt-4">
              <h2 className="font font-semibold xl:text-2xl text-red xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                Serverless
              </h2>
              <p className="font  xl:text-base">
                It's actually a misnomer! Servers are still involved, but you,
                the developer, are absolved from managing them. Imagine renting
                code execution "slots" in the cloud instead of entire servers.
                You focus on writing and deploying your code, while the provider
                handles provisioning, scaling, and maintenance of the underlying
                infrastructure.
              </p>
              <ul className="list-none xl:pt-4 pt-4">
                <li className="font xl:text-base text-sm items-start flex">
                  <span className="mr-2 text-red">✔</span> Reduced operational
                  burden: No more server patching, security updates, or
                  infrastructure management. Focus on your code, not the
                  scaffolding.
                </li>
                <li className="font xl:text-base text-sm items-start flex py-3">
                  <span className="mr-2">✔</span> Cost efficiency: You only pay
                  for the code execution time, not idle servers. This can lead
                  to significant cost savings, especially for applications with
                  fluctuating workloads.
                </li>
                <li className="font xl:text-base text-sm items-start flex ">
                  <span className="mr-2 text-red">✔</span> Scalability and
                  agility: Serverless automatically scales up and down based on
                  demand, keeping you ahead of traffic spikes and resource
                  constraints.
                </li>
                <li className="font xl:text-base text-sm items-start flex py-3">
                  <span className="mr-2">✔</span> Faster development and
                  deployment: No need to set up and configure servers,
                  accelerating your entire software lifecycle.
                </li>
                <li className="font xl:text-base text-sm items-start flex ">
                  <span className="mr-2 text-red">✔</span> Increased
                  innovation:Focus on building features instead of
                  infrastructure, freeing up your creative potential.
                </li>
              </ul>
            </div>
            <div className="xl:pl-12 xl:mt-16 mt-4">
              <img
                src={serverless}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550  h-250 w-600"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 ">
          <div className="grid xl:grid-cols-2 xl:gap-6  md:grid-cols-2 md:gap-4 py-6">
            <div className="xl:pt-8">
              <img
                src={serverless2}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600"
              ></img>
            </div>
            <div className="pt-4 xl:pl-12">
              <h2 className="font font-semibold xl:text-2xl xl:pt-12 xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                Common Serverless Use Cases
              </h2>
              <ul className="list-none xl:pt-4 pt-4">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span>Microservices
                  architecture: Break down your applications into smaller,
                  independent services that can be easily scaled and deployed
                  individually.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Event-driven applications:
                  React to real-time events like sensor data, API calls, or user
                  interactions with serverless functions triggered
                  automatically.
                </li>

                <li className="font xl:text-base text-sm flex items-start">
                  <span className="mr-2 text-red">✔</span> Background tasks:
                  Schedule batch jobs, handle file processing, or perform data
                  analytics without maintaining dedicated servers.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Mobile and web applications:
                  Build efficient backends for your apps without worrying about
                  server management.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 ">
          <div className="grid xl:grid-cols-2 xl:gap-6  md:grid-cols-2 md:gap-4 py-6">
            <div className="pt-4">
              <h2 className="font font-semibold xl:text-2xl text-red xl:pt-8 xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                Containers and Kubernetes
              </h2>
              <p className="font xl:pt-2 xl:text-base">
                Containerization is a lightweight, portable, and scalable method
                for packaging, distributing, and running applications. It
                involves encapsulating an application and its dependencies into
                a standardized unit called a container. Containers provide a
                consistent and isolated environment, ensuring that the
                application runs reliably across different computing
                environments.
              </p>
              <h2 className="font font-semibold xl:text-base pt-2">Docker</h2>
              <p className="font  xl:text-base">
                Docker is a popular containerization platform that enables the
                creation, distribution, and running of containers. It uses a
                client-server architecture, where the Docker daemon manages
                containers.
              </p>
              <h2 className="font font-semibold xl:text-base pt-2">
                Orchestration
              </h2>
              <p className="font  xl:text-base">
                Container orchestration involves managing and coordinating the
                deployment, scaling and operation of multiple containers.
              </p>
              <h2 className="font font-semibold xl:text-base pt-2">
                Microservices
              </h2>
              <p className="font  xl:text-base">
                Containerization is often used in a microservices architecture,
                where an application is decomposed into smaller, independently
                deployable services, each running in its own container.
              </p>
              <h2 className="font font-semibold xl:text-base pt-2">
                Kubernetes
              </h2>
              <p className="font  xl:text-base">
                Kubernetes is a sophisticated container orchestration platform
                designed to revolutionize how we deploy, scale, and manage
                applications encapsulated within containers.
              </p>
            </div>
            <div className="xl:pl-12 xl:mt-24 md:mt-12 ">
              <img
                src={container}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 ">
          <div className="grid xl:grid-cols-2 xl:gap-6  md:grid-cols-2 md:gap-4 py-6">
            <div className="xl:block lg:block md:block hidden">
              <img
                src={k8}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 xl:mt-20 md:mt-12 md:h-300 md:w-550 h-250 w-600"
              ></img>
            </div>
            <div className="pt-4 xl:pl-12">
              <h2 className="font font-semibold xl:text-2xl xl:pt-12 xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                Here's what K8s brings to the table
              </h2>
              <ul className="list-none xl:pt-2 pt-2">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span>Automated Deployment
                  and Scaling: No more manual scaling or configuration
                  headaches. K8s automatically deploys and scales your
                  containerized applications based on demand, ensuring constant
                  availability and optimal resource utilization.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Self-Healing: Spilled soup? No
                  problem! K8s automatically restarts any failing containers,
                  keeping your applications running smoothly even if something
                  goes wrong.
                </li>

                <li className="font xl:text-base text-sm flex items-start">
                  <span className="mr-2 text-red">✔</span> Load Balancing: Can't
                  handle all those hungry customers? K8s distributes traffic
                  across your containerized applications, ensuring everyone gets
                  served efficiently.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Portability and Flexibility:
                  Unlike the restaurant's specific kitchen layout, K8s works
                  anywhere. Run your applications on different platforms and
                  cloud providers with ease.
                </li>
                <li className="font xl:text-base text-sm flex items-start">
                  <span className="mr-2 text-red">✔</span> Declarative
                  Configuration: Tell K8s what you want, not how to do it. Write
                  simple configurations, and K8s takes care of the rest.
                </li>
              </ul>
            </div>
            <div className="xl:hidden lg:hidden md:hidden block">
              <img
                src={k8}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 xl:pt-24 pt-4 md:h-300 md:w-550 h-250 w-600"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 ">
          <div className="grid xl:grid-cols-2 xl:gap-6  md:grid-cols-2 md:gap-4 py-6">
            <div className="pt-4">
              <h2 className="font font-semibold xl:text-2xl text-red xl:pt-8 xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                Devops
              </h2>
              <p className="font  xl:text-base">
                At Marslab Intelligence Private Limited, we are dedicated to
                transforming the landscape of software development and IT
                operations through a steadfast commitment to DevOps principles.
                Our approach is rooted in the belief that collaboration,
                automation, and continuous improvement are the cornerstones of
                modern software delivery.
              </p>
              <ul className="list-none xl:pt-2 pt-2">
                <li className="font xl:text-base text-sm items-start flex">
                  <span className="mr-2 text-red">✔</span> Jenkins: A Popular
                  Open-Source automation server for building, testing and
                  deploying code.
                </li>
                <li className="font xl:text-base text-sm items-start flex py-3">
                  <span className="mr-2">✔</span> GitLab CI/CD: Integration
                  CI/CD capabilities within the GitLab platform, offering
                  version control and CI/CD in a single interface.
                </li>
                <li className="font xl:text-base text-sm items-start flex">
                  <span className="mr-2 text-red">✔</span> Docker: A platform
                  for developing, shipping, and running applications in
                  containers, enabling consistency across various environments.
                </li>
                <li className="font xl:text-base text-sm items-start flex py-3">
                  <span className="mr-2">✔</span> Kubernetes: An open-source
                  container orchestration platform for automating the
                  deployment, scaling, and management of containerized
                  applications.
                </li>
                <li className="font xl:text-base text-sm items-start flex">
                  <span className="mr-2 text-red">✔</span> An open-source
                  automation tool that automates software provisioning,
                  configuration management, application deployment.
                </li>
              </ul>
            </div>
            <div className="xl:pl-12 xl:mt-24 mt-4 ">
              <img
                src={devops}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 ">
          <div className="grid xl:grid-cols-2 xl:gap-6  md:grid-cols-2 md:gap-4 py-6">
            <div className="xl:block lg:block md:block hidden">
              <img
                src={clientcentric}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600 xl:mt-12 md:mt-10"
              ></img>
            </div>
            <div className="pt-4 xl:pl-12">
              <h2 className="font font-semibold xl:text-2xl xl:mt-28 xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                Client-Centric DevOps Solutions
              </h2>
              <p className="font  xl:text-base xl:pt-2">
                Our commitment extends to delivering DevOps solutions that are
                tailored to meet the unique needs of our clients. Through
                collaborative partnerships, we align our DevOps practices with
                the strategic goals of our clients, driving innovation and
                delivering measurable business value.
              </p>
              <p className="font  xl:text-base xl:pt-2">
                At Marslab Intelligence Private Limited, DevOps is not just a
                Methodology; it’s a mindset that drives us to push boundaries,
                embrace change, and empower our clients to thrive in a rapidly
                evolving digital landscape.
              </p>
            </div>
            <div className="xl:hidden lg:hidden md:hidden block">
              <img
                src={clientcentric}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600 mt-12"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-8">
          <div className="grid xl:grid-cols-2 xl:gap-6  md:grid-cols-2 md:gap-4 py-6">
            <div className="pt-4">
              <h2 className="font font-semibold xl:text-2xl text-red xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                Cloud Networking
              </h2>
              <p className="font  xl:text-base">
                Simply put, it's the architecture and mechanisms enabling
                connectivity within and between cloud environments. Imagine it
                as a web of virtual roads and bridges, facilitating seamless
                communication between your cloud-based assets and the outside
                world.
              </p>
              <ul className="list-none xl:pt-4 pt-4">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span>Agility and
                  scalability: Quickly adapt your network to changing needs by
                  dynamically provisioning and scaling resources, all without
                  the hassle of physical infrastructure management.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Enhanced security: Leverage
                  robust cloud security features to protect your data and
                  applications from threats, with comprehensive monitoring and
                  access control measures.
                </li>

                <li className="font xl:text-base text-sm flex items-start">
                  <span className="mr-2 text-red">✔</span> Cost efficiency:
                  Pay-as-you-go pricing eliminates upfront hardware investments
                  and reduces maintenance costs, optimizing your cloud spend.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Simplified management:
                  Centralized management tools provide a single pane of glass
                  for monitoring, configuring, and troubleshooting your entire
                  cloud network.
                </li>
              </ul>
            </div>
            <div className="">
              <img
                src={cloudnetworking}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600 xl:mt-16 mt-4 "
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 ">
          <div className="grid xl:grid-cols-2 xl:gap-6  md:grid-cols-2 md:gap-4 py-6">
            <div className="xl:block lg:block md:block hidden">
              <img
                src={componentsofcloud}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 xl:mt-12 md:mt-12 lg:h-400 md:h-300 md:w-550 h-250 w-600"
              ></img>
            </div>
            <div className="pt-4 xl:pl-12">
              <h2 className="font font-semibold xl:text-2xl xl:pt-12 xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                Key Components of Cloud Networking
              </h2>
              <ul className="list-none xl:pt-2 pt-2">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span>Virtual networks:
                  Isolated, dynamic networks within the cloud, providing secure
                  and flexible environments for your resources.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Virtual routers and firewalls:
                  Route traffic and enforce security policies between virtual
                  networks and the internet.
                </li>

                <li className="font xl:text-base text-sm flex items-start">
                  <span className="mr-2 text-red">✔</span> Load balancing:
                  Distribute traffic across multiple servers to ensure
                  application availability and performance.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> VPNs: Create secure tunnels
                  for connecting on-premises networks with your cloud
                  environment.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2 text-red">✔</span> SDN (Software-Defined
                  Networking): Programmatically control and manage your network
                  through software, enabling automation and agility.
                </li>
              </ul>
            </div>
            <div className="xl:hidden lg:hidden md:hidden block">
              <img
                src={componentsofcloud}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 xl:pt-12 pt-4 lg:h-400 md:h-300 md:w-550 h-250 w-600"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 ">
          <div className="grid xl:grid-cols-2 xl:gap-6  md:grid-cols-2 md:gap-4 py-6">
            <div className="pt-4 ">
              <h2 className="font font-semibold xl:text-2xl xl:pt-16 xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                Why MARSLAB
              </h2>
              <ul className="list-none xl:pt-2 pt-2">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span>Full cloud migration:
                  Shift your entire network to the cloud for maximum scalability
                  and agility.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Hybrid cloud networking:
                  Combine on-premises and cloud resources for a flexible and
                  secure solution.
                </li>

                <li className="font xl:text-base text-sm flex items-start">
                  <span className="mr-2 text-red">✔</span> Multi-cloud
                  networking: Connect resources across multiple cloud providers
                  for increased redundancy and access to specialized services.
                </li>
              </ul>
            </div>

            <div className="">
              <img
                src={whymarslabnew}
                className="rounded-md xl:w-650 mt-4 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600 "
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 ">
          <div className="grid xl:grid-cols-2 xl:gap-6  md:grid-cols-2 md:gap-4 py-6">
            <div className="xl:block lg:block md:block hidden">
              <img
                src={networkingnew}
                className="rounded-md xl:w-650 xl:mt-0 md:mt-8 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600"
              ></img>
            </div>
            <div className="pt-4 xl:pl-12">
              <h2 className="font font-semibold xl:text-2xl text-red xl:pt-8 xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                Cloud Security
              </h2>
              <p className="font  xl:text-base">
                The vigilant guardian of your digital fortress in the
                ever-evolving landscape of the cloud. It's a complex tapestry
                woven from technology, policies, and best practices, ensuring
                your data, applications, and infrastructure remain safe and
                sound. Let's delve into its intricacies and discover how to
                build an impregnable cloud security posture.
              </p>

              <p className="font  xl:text-base xl:pt-4">
                Shared Responsibility Model: Remember, in the cloud, security is
                a shared responsibility. Cloud providers (AWS, Azure, etc.)
                secure the underlying infrastructure, while you, the customer,
                secure your data, applications, and configurations. It's like
                sharing a castle – the king provides the walls and moat, but you
                need to secure the treasures within.
              </p>
            </div>
            <div className="xl:hidden lg:hidden md:hidden block">
              <img
                src={networkingnew}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 mt-4 xl:mt-0 lg:h-400 md:h-300 md:w-550 h-250 w-600"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 ">
          <div className="grid xl:grid-cols-2 xl:gap-6  md:grid-cols-2 md:gap-4 py-6">
            <div className="pt-4">
              <h2 className="font font-semibold xl:text-2xl xl:pt-12 xl:pb-2 md:pb-2 lg:pb-2 pb-2">
                Benefits of Robust Cloud Security
              </h2>
              <ul className="list-none xl:pt-4 pt-4">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Protect your data and
                  assets: Prevent unauthorized access, data breaches, and
                  cyberattacks, safeguarding your valuable information.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Maintain business continuity:
                  Minimize downtime and ensure your applications and services
                  remain available even during security incidents.
                </li>

                <li className="font xl:text-base text-sm flex items-start">
                  <span className="mr-2 text-red">✔</span> Build trust and
                  compliance: Demonstrate your commitment to data security and
                  comply with relevant regulations, fostering stakeholder trust.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Reduce costs: Proactive
                  security measures can prevent costly data breaches and
                  reputational damage.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2 text-red">✔</span> Gain peace of mind:
                  Sleep soundly knowing your cloud environment is protected by a
                  diligent security posture.
                </li>
              </ul>
            </div>
            <div className="xl:pl-12 xl:pt-12 md:pt-12">
              <img
                src={partner}
                className="rounded-md xl:w-650 xl:h-400 lg:w-650 lg:h-400 md:h-300 md:w-550 h-250 w-600"
              ></img>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Cloud;
