import React, { useState } from "react";
import Footer from "components/Footers/Footer";
import IndexNavbar from "components/Navbars/IndexNavbar";
import ApplicationContent2 from "components/Dropdowns/ApplicationContent2";
import ApplicationContent3 from "components/Dropdowns/ApplicationContent3";
import marshlabbannerr from "assets/img/marshlabbannerr.jpg";
import mission from "assets/img/icons/mission.png";
import customer from "assets/img/icons/customer.png";
import success from "assets/img/icons/success.png";
import sharedvision from "assets/img/icons/shared-vision.png";
import atworkhappyteamwithlaptop from "assets/img/images/atwork-happyteamwithlaptop.jpg";
import vision from "assets/img/images/vision.png";
import mission1 from "assets/img/images/mission1.png";

const Ai = () => {
  const totalSteps = 3;
  const [currentStep, setCurrentStep] = useState(1);

  const handleNext = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrev = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const stepsContent = [
    {
      title: "Our Mission",
      content: (
        <div>
          <p>
            We are committed to providing comprehensive IT solutions that align
            with our customers' unique needs and aspirations. We strive to:
          </p>
        </div>
      ),
    },
    {
      title: "Application Security Audit Frameworks",
      content: (
        <div>
          <p>
            To be the premier IT solutions provider, recognized for our
            exceptional expertise, unwavering commitment to customer success,
            and dedication to ethical practices.
          </p>
        </div>
      ),
    },
    {
      title: "Our Values",
      content: (
        <div>
          <p>
            Our core values guide our every action and decision, ensuring that
            we remain a trusted partner for our customers:
          </p>
        </div>
      ),
    },
    {
      title: "Excellence",
      content: (
        <div>
          <p>
            Our core values guide our every action and decision, ensuring that
            we remain a trusted partner for our customers:
          </p>
        </div>
      ),
    },
  ];

  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <section>
      <IndexNavbar />
      <div className="xl:mt-20 lg:mt-18 mt-16">
        <div className="bg-stone-200 ">
          <div className="container mx-auto relative text-danger overflow-hidden xl:px-16 px-4 xl:py-4 lg:py-4 py-4">
            <a href="index" className="link text-ivory-black">
              Home {"  "} {/* space purpose */}
            </a>
            {"  "}― {"  "}
            <a href="aboutt" className="link">
              Services {"  "}
            </a>
            {"  "} ― {"  "}
            AI Engineering
          </div>
        </div>
      </div>

      {/* section */}

      <div className="">
        <div className="xl:block lg:block md:block block font">
          <div
            style={{
              backgroundImage: `url(${marshlabbannerr})`,
              width: "100%",
            }}
            className="bg-ivory-black xl:h-80 md:h-60 h-48 relative"
          >
            <p className="font uppercase xl:text-5xl lg:text-4xl md:text-4xl  text-2xl text-white text-center xl:pt-32 lg:pt-28 md:pt-28 pt-20 font-semibold">
              AI Engineering
            </p>
          </div>
        </div>
      </div>

      {/* Cyber threat awarness */}

      <div className="container mx-auto relative overflow-hidden xl:px-16 xl:py-16 lg:py-8 md:py-8 py-4 px-4">
        <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-4 lg:grid-cols-2 lg:gap-4 xl:gap-4">
          <div className="">
            <h1 className="font font-semibold xl:text-2xl lg:text-2xl">
              What is machine learning?
            </h1>
            <p className="font pt-2">
              Machine learning is where computers learn like humans, analyzing
              patterns and adapting to become smarter over time. Machine
              learning empowers computers to learn from experience, evolving
              algorithms to predict, classify, and solve problems without
              handholding.
            </p>
            <p className="font py-2">
              Machine learning transforms computers from programmed automatons
              to agile learners, analyzing data to make autonomous decisions and
              constantly improve their performance. This subfield of AI empowers
              computers to learn from data, uncovering hidden patterns and
              making them increasingly adept at handling even complex tasks
              without explicit instructions.
            </p>
            <p className="font py-2">
              Machine learning is all about giving computers the power to learn
              on their own, analyzing information like a data detective to
              become masters of prediction and decision-making, all without being
              explicitly programmed.
            </p>
          </div>
          <div className="xl:pl-12 xl:mt-2 lg:mt-12 md:mt-32 mt-0">
            <img
              src="https://i.ibb.co/ykJjCPK/machinelearning.png"
              className="rounded-md"
            ></img>
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:py-4 xl:pb-10">
          <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-4 lg:grid-cols-2 lg:gap-4 xl:gap-6 py-6">
            <div className="xl:block lg:block md:block hidden">
              <img
                src="https://i.ibb.co/3yNSz25/machinelearning1.png"
                className="rounded-md xl:mt-4 lg:mt-16 md:mt-36 mt-0"
              ></img>
            </div>
            <div className="pt-0 xl:pt-4 md:pt-4 xl:pl-12">
              <h2 className="font font-semibold xl:text-2xl lg:text-2xl">
                Why machine learning?
              </h2>
              <p className="font text-base pt-2">
                Versatility, adaptability, and continuous learning - the secret
                sauce behind machine learning's industry-wide adoption. Machine
                learning's ability to extract insights from data and improve
                over time has made it a valuable tool across diverse industries,
                driving innovation and efficiency.
              </p>
              <p className="font text-base pt-2">
                It's a game-changer. From automating tedious tasks to predicting
                market shifts, its power to unlock efficiency, insights, and
                personalized experiences cuts across industries. Imagine systems
                learning from data like tireless apprentices, extracting hidden
                patterns and making ever-smarter decisions.
              </p>
              <p className="font text-base pt-2">
                That's the magic of machine learning: data-driven decisions,
                predictive power, and continuous improvement, all fueling
                innovation, competitiveness, and cost savings from healthcare to
                customer service. It's not just a tool, it's a revolution
                reshaping landscapes and unlocking possibilities, one byte at a
                time.
              </p>
            </div>

            <div className="xl:hidden lg:hidden md:hidden block">
              <img
                src="https://i.ibb.co/3yNSz25/machinelearning1.png"
                className="rounded-md xl:pl-12 xl:mt-0 lg:mt-16 md:mt-4 mt-6"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 xl:pt-6">
          <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-4 lg:grid-cols-2 lg:gap-4 xl:gap-6 py-6">
            <div className="xl:pt-4 md:pt-4 pt-0">
              <div className="flex items-center">
                <p className="font-bold text-red xl:text-5xl lg:text-3xl md:text-3xl text-3xl">
                  01
                </p>
                <h2 className="font-semibold xl:text-2xl lg:text-2xl md:text-lg font xl:py-2 pl-2">
                  What Deep Learning?
                </h2>
              </div>
              <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                Deep learning leverages the architecture of the human brain,
                employing multi-layered artificial neural networks to extract
                increasingly complex features from data, leading to
                groundbreaking results in domains like image recognition and
                natural language processing. Inspired by the intricate web of
                the human brain, deep learning constructs sophisticated
                artificial neural networks, stacked layer upon layer, to unravel
                the hidden patterns within data, unlocking a new era of machine
                intelligence.
              </p>
              <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                Imagine a sculptor painstakingly shaping a masterpiece, guided
                by the subtle feedback of each chiseled stroke. Backpropagation
                plays a similar role in deep learning, meticulously adjusting
                the model's parameters to achieve increasingly accurate
                predictions.
              </p>
              <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                CNNs, the eagle-eyed masters of image recognition, scrutinize
                pixels like detectives, unearthing patterns and meaning. RNNs,
                the dancers of data, pirouette across sequences, understanding
                the ebb and flow of language. Transformers, the shape-shifters
                of the AI world, adapt to diverse tasks, wielding their power in
                the realm of natural language processing.
              </p>
            </div>

            <div className="">
              <div className="pt-4 xl:pl-12">
                <div className="flex items-center">
                  <p className="font-bold text-red xl:text-5xl lg:text-3xl md:text-3xl text-3xl">
                    02
                  </p>
                  <h2 className="font-semibold xl:text-2xl lg:text-2xl md:text-lg font xl:py-2 pl-2">
                    Why Deep Learning?
                  </h2>
                </div>
                <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                  Deep learning's automated feature extraction not only
                  streamlines the development process but also unlocks
                  previously unattainable insights, leading to more
                  comprehensive and robust models.
                </p>
                <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                  Deep learning models possess the capability for ongoing
                  learning and refinement of predictions as they are exposed to
                  new data. This adaptability enables them to remain effective
                  in dynamic environments and consistently enhance their
                  performance over time.
                </p>
                <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                  Deep learning's prowess in extracting subtle patterns from
                  complex data yields groundbreaking advancements in tasks
                  previously considered challenging, such as image recognition,
                  speech recognition, and machine translation.
                </p>
                <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                  Deep learning models cut through the clutter of traditional
                  pipelines, forging a direct path from input to output,
                  streamlining development and reducing the burden of
                  domain-specific expertise.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 xl:pt-12">
          <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-4 lg:grid-cols-2 lg:gap-4 xl:gap-12 py-6">
            <div className="pt-0 xl:pt-4">
              <h2 className="font font-semibold xl:text-2xl lg:text-xl">
                What is Natural Language Processing?
              </h2>
              <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                NLP is a subfield of artificial intelligence concerned with
                enabling computers to process and understand human language,
                including its nuances and complexities. This involves tasks like
                parsing text, analyzing semantics, and generating natural
                language responses.
              </p>
              <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                NLP leverages cutting-edge machine learning techniques,
                particularly deep learning, to train models on massive datasets
                of text and speech. These models learn to identify patterns,
                extract meaning, and perform various language-related tasks.
              </p>
              <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                Imagine building a bridge between the digital world of computers
                and the expressive world of human language. NLP acts as the
                engineer, constructing complex algorithms and systems that allow
                seamless communication across this bridge.
              </p>
              <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                Think of NLP as a decoder ring for computers, enabling them to
                crack the code of human language. By breaking down words,
                phrases, and sentences, NLP unlocks the hidden meaning and
                emotion within human communication.
              </p>
            </div>
            <div className="pl-12">
              <img
                src="https://i.ibb.co/XSQkHY9/NLP1.png"
                className="rounded-md xl:mt-8 lg:mt-16 md:mt-28 mt-4"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 xl:pt-8">
          <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-4 lg:grid-cols-2 lg:gap-4 xl:gap-6 py-6">
            <div className="xl:block lg:block md:block hidden">
              <img
                src="https://i.ibb.co/1QzFqzc/NLP.png"
                className="rounded-md xl:mt-10 lg:mt-20 md:mt-24 mt-0"
              ></img>
            </div>
            <div className="xl:pt-4 pt-0 md:pt-4 xl:pl-12">
              <h2 className="font font-semibold xl:text-2xl lg:text-xl">
                Why is NLP?
              </h2>
              <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                The power of NLP lies not just in machines, but in our hands.
                Join the conversation, explore its potential, and contribute to
                shaping a future where human-computer interaction is truly
                natural and empowering.
              </p>
              <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                NLP is a vast and ever-evolving field, waiting to be explored.
                Whether you're a tech enthusiast, a creative mind, or simply
                curious about the future of communication, there's a place for
                you in this exciting journey.
              </p>
              <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                <b>To avoid repetition:</b> Replacing words with synonyms can
                make text more engaging and prevent monotony.
              </p>
              <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                <b>To clarify meaning: </b> Using more precise or specific words
                can improve the understanding of a text.
              </p>
              <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                <b>To adapt to context:</b> Replacing words can make text more
                appropriate for a specific audience or situation.
              </p>
              <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                <b>To generate creative text formats:</b> NLP applications like
                poetry generators or dialogue systems may use alternative words
                for artistic purposes.
              </p>
            </div>
            <div className="xl:hidden lg:hidden md:hidden block">
              <img
                src="https://i.ibb.co/1QzFqzc/NLP.png"
                className="rounded-md xl:pl-12 xl:pt-0 lg:pt-16 md:pt-4 mt-4"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 xl:pt-6">
          <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-4 lg:grid-cols-2 lg:gap-4 xl:gap-6 py-6">
            <div className="xl:pt-4 pt-0 md:pt-0">
              <div className="flex items-center">
                <p className="font-bold text-red xl:text-5xl lg:text-3xl text-3xl">01</p>
                <h2 className="font-semibold xl:text-2xl lg:text-xl font xl:py-2 pl-2">
                Why Virtual Assistant?
                </h2>
              </div>
              <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                  Improved customer service: Provide round-the-clock support,
                  addressing frequently asked questions, thereby improving
                  customer satisfaction and minimizing response delays.
                </p>

                <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                  <b>Increased Productivity: </b>Imagine employees unshackled
                  from mundane chores. Virtual assistants handle the repetitive
                  stuff, empowering people to tackle higher-order challenges and
                  unlock their full potential.
                </p>
                <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                  <b>Data Insights and Personalization: </b> Every interaction
                  whispers a story. Virtual assistants analyze user data,
                  revealing hidden preferences and trends, and fueling personalized
                  campaigns and offerings that resonate deeply.
                  </p>
            </div>
            <div className="">
              <div className="pt-4 xl:pl-12">
                <div className="flex items-center">
                  <p className="font-bold text-red xl:text-5xl lg:text-3xl text-3xl">
                    02
                  </p>
                  <h2 className="font-semibold xl:text-2xl lg:text-xl font xl:py-2 pl-2">
                  What is Virtual Assistant?
                  </h2>
                </div>
                <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                Virtual assistants are perpetual learners, growing more
                knowledgeable and helpful with each interaction. They analyze
                user behavior and preferences, constantly refining their skills
                to become indispensable companions.
              </p>
              <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                Leveraging AI and natural language processing, virtual
                assistants perform diverse tasks like appointments,
                reservations, and smart home control, offering a comprehensive
                suite of digital assistance.
              </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 xl:pt-12">
          <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-4 lg:grid-cols-2 lg:gap-4 xl:gap-6 py-6">
            <div className="pt-0 xl:pt-10 md:pt-4">
              <h2 className="font font-semibold xl:text-2xl lg:text-2xl ">
                What is Image Processing?
              </h2>
              <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                Image processing algorithms play digital detective, scrutinizing
                the numerical tapestry of pixels to uncover hidden patterns,
                decipher meaningful features, and unravel the mysteries
                contained within images.
              </p>
              <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                Employing the wizardry of algorithms, image processing delves
                into the numerical heart of digital images, extracting features,
                recognizing patterns, and illuminating the visual landscape for
                machine understanding.
              </p>
              <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                Based on specific criteria, segmentation algorithms partition an
                image into meaningful segments, facilitating object recognition
                and further analysis.
              </p>
            </div>
            <div className="xl:pl-12 mt-4 xl:mt-0 md:mt-8">
              <img
                src="https://i.ibb.co/pJtLnMg/imageprocessing.png"
                className="rounded-md"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 xl:pt-12">
          <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-4 lg:grid-cols-2 lg:gap-4 xl:gap-6 py-6">
            <div className="xl:block lg:block md:block hidden">
              <img
                src="https://i.ibb.co/vhHJFdr/imageprocessing1.png"
                className="rounded-md xl:mt-0 lg:mt-2 md:mt-16 mt-0"
              ></img>
            </div>
            <div className="xl:pt-4 pt-0 md:pt-0 xl:pl-12">
              <h2 className="font font-semibold xl:text-2xl lg:text-xl">
                Why Image Processing is important?
              </h2>
              <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                Beyond imitation, image processing allows machines to complement
                and refine human vision, unveiling hidden clues in medical
                scans, guiding robots through complex environments, and enabling
                us to see the world in ways never imagined.
              </p>
              <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                Revolutionary fields like healthcare, security, and robotics owe
                their advancements to the transformative power of image
                processing, which unlocks hidden information and empowers
                machines to perceive and analyze the visual world.
              </p>
              <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                Image processing acts as a digital decoder ring, cracking the
                code within pictures to reveal hidden patterns and insights
                invisible to the naked eye, empowering machines to make informed
                decisions in ways even humans struggle to achieve.
              </p>
            </div>
            <div className="xl:hidden lg:hidden md:hidden block">
              <img
                src="https://i.ibb.co/vhHJFdr/imageprocessing1.png"
                className="rounded-md xl:pl-12 xl:pt-0 lg:pt-12 md:pt-4 mt-4"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 xl:pt-8">
          <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-4 lg:grid-cols-2 lg:gap-4 xl:gap-6 py-6">
          <div className="">
              <div className="pt-0 xl:pt-4 md:pt-4">
                <div className="flex items-center">
                  <p className="font-bold text-red xl:text-5xl lg:text-3xl md:text-3xl text-3xl">
                    01
                  </p>
                  <h2 className="font-semibold xl:text-2xl font xl:py-2 pl-2">
                    Why Object Detection?
                  </h2>
                </div>
                <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                  The transformative power of object detection manifests in
                  fields like self-driving cars, medical imaging, security, and
                  retail, enhancing safety, diagnosis, security measures, and
                  marketing strategies through precise object identification and
                  location.
                </p>
                <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                  Revolutionary fields like healthcare, security, and robotics
                  owe their advancements to the transformative power of image
                  processing, which unlocks hidden information and empowers
                  machines to perceive and analyze the visual world.
                </p>
                <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                  Image processing acts as a digital decoder ring, cracking the
                  code within pictures to reveal hidden patterns and insights
                  invisible to the naked eye, empowering machines to make
                  informed decisions in ways even humans struggle to achieve.
                </p>
              </div>
            </div>
            <div className="pt-4">
              <div className="flex items-center">
                <p className="font-bold text-red xl:text-5xl lg:text-3xl text-3xl md:text-3xl">02</p>
                <h2 className="font-semibold xl:text-2xl font xl:py-2 pl-2">
                  What is Object Detection?
                </h2>
              </div>
              <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                Object detection algorithms are the digital sleuths of the
                visual world, meticulously sifting through pixels and patterns
                to identify and locate hidden objects within images and videos.
                From a sea of numbers, object detection algorithms weave a
                tapestry of understanding, meticulously analyzing features like
                color, shape, and texture to transform raw data into the
                distinct identities and locations of objects within images and
                videos.
              </p>
              <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                Imagine a curator carefully arranging exhibits within a gallery.
                Object detection algorithms similarly frame each object with a
                bounding box, creating a visual showcase that highlights their
                presence and contextual relationships.
              </p>
            </div>
            
          </div>
        </div>
      </div>

      <Footer />
    </section>
  );
};

export default Ai;
