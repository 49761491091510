import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import mendublicate from "assets/img/mendublicate.png";
import sysarc from "assets/img/images/testimonial/sysarc.jpg";
import diamondpick from "assets/img/images/testimonial/diamondpick.jpg";
import witzone from "assets/img/images/testimonial/witzone.jpg";

// Define CustomPrevArrow and CustomNextArrow components outside of SliderPage

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "10px", zIndex: 1 }}
      onClick={onClick}
    >
      {/* custom left arrow icon or text */}
      {/* <span style={{ color: "blue", fontSize: "24px" }}>{"<"}</span> */}
      <span>{"<"}</span>
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "10px", zIndex: 1 }}
      onClick={onClick}
    >
      {/* custom right arrow icon or text with red color */}
      {/* <span style={{ color: "red", backgroundColor: "red", fontSize: "24px" }}>{">"}</span> */}
      <span>{">"}</span>
    </div>
  );
};

const SliderPage = () => {
  const settings = {
    dots: false,
    infinite: true,
    centerPadding: "10px",
    speed: 1300,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <section
      className="container mx-auto overflow-x-auto xl:px-16 px-4"
      style={{ overflowX: "hidden" }}
    >
      <Slider {...settings}>
        <div className="">
          <div className="grid xl:grid-cols-1 xl:gap-4 md:grid-cols-1 bg-white xl:p-4 md:p-6 mb-8 xl:mb-0 p-6 rounded-xl xl:mx-34 md:mx-4">
            <div className="xl:pl-12 pl-4 ">
              <button
                className="bg-black text-5xl text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none pxx"
                type="button"
                href="youtube"
              >
                <i className="">❝</i>
              </button>
              <div className="xl:pt-4 pt-6 xl:pr-4">
                <p className="font xl:text-xl font-thin">
                  "MARSLAB support a lot to our project. with thier support only
                  we done their project on time"
                </p>
              </div>
              <div className="flex xl:w-12/12">
                <div className="xl:w-2/12 xl:pt-10 pt-8 ">
                  <div className="border border-blueGray-500 rounded-full">
                    <div>
                      <img
                        src={sysarc}
                        alt="Your browser doest not support this content"
                        className="xl:h-20 h-16 xl:w-24 rounded-full"
                      />
                    </div>
                  </div>
                </div>
                <div className="xl:w-9/12 xl:pt-12 xl:ml-8 pt-8 pl-4">
                  <p className="font font-medium xl:text-xl">
                    Sysarc infomatrix
                  </p>
                  <p className="font font-thin xl:text-lg text-sm">
                    (Cloud Architect)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid xl:grid-cols-1 xl:gap-4 md:grid-cols-1 bg-white xl:p-4 md:p-4 mb-8 xl:mb-0 p-4 rounded-xl ">
          <div className="xl:pl-12 pl-4 ">
            <button
              className="bg-black text-5xl text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none pxx"
              type="button"
              href="youtube"
            >
              <i className="">❝</i>
            </button>
            <div className="xl:pt-4 pt-6 xl:pr-4">
              <p className="font xl:text-xl font-thin">
                "Good support and great service on AWS, Exploring new technology
                from AWS for our orgaganization"
              </p>
            </div>
            <div className="flex xl:w-12/12">
              <div className="xl:w-2/12 xl:pt-10 pt-8 ">
                <div className="border border-blueGray-500 rounded-full">
                  <div>
                    <img
                      src={diamondpick}
                      alt="Your browser doest not support this content"
                      className="xl:h-20 h-16 xl:w-24 rounded-full"
                    />
                  </div>
                </div>
              </div>
              <div className="xl:w-9/12 xl:pt-12 xl:ml-8 pt-8 pl-4">
                <p className="font font-medium xl:text-xl">DiamondPick</p>
                <p className="font font-thin xl:text-lg text-sm">
                  (IT Manager)
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="grid xl:grid-cols-1 xl:gap-4 md:grid-cols-1 bg-white xl:p-4 md:p-4 mb-8 xl:mb-0 p-4 rounded-xl xl:mx-4 md:mx-4">
            <div className="xl:pl-12 pl-4 ">
              <button
                className="bg-black text-5xl text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none pxx"
                type="button"
                href="youtube"
              >
                <i className="">❝</i>
              </button>
              <div className="xl:pt-4 pt-6 xl:pr-4">
                <p className="font xl:text-xl font-thin">
                  "It is nice to work with MARSLAB. They could able to provide
                  solution for my requirement."
                </p>
              </div>
              <div className="flex xl:w-12/12">
                <div className="xl:w-2/12 xl:pt-10 pt-8 ">
                  <div className="border border-blueGray-500 rounded-full">
                    <div>
                      <img
                        src={mendublicate}
                        alt="Your browser doest not support this content"
                        className="xl:h-20 h-16 xl:w-24 rounded-full"
                      />
                    </div>
                  </div>
                </div>
                <div className="xl:w-9/12 xl:pt-12 xl:ml-8 pt-8 pl-4">
                  <p className="font font-medium xl:text-xl">Emaster</p>
                  <p className="font font-thin xl:text-lg text-sm">(CEO)</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid xl:grid-cols-1 xl:gap-4 md:grid-cols-1 bg-white xl:p-4 md:p-4 mb-8 xl:mb-0 p-4 rounded-xl">
          <div className="xl:pl-12 pl-4 ">
            <button
              className="bg-black text-5xl text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none pxx"
              type="button"
              href="youtube"
            >
              <i className="">❝</i>
            </button>
            <div className="xl:pt-4 pt-6 xl:pr-4">
              <p className="font xl:text-xl font-thin">
                "MARSLAB team supports a lot to us. they are well confidence."
              </p>
            </div>
            <div className="flex xl:w-12/12">
              <div className="xl:w-2/12 xl:pt-10 pt-8 ">
                <div className="border border-blueGray-500 rounded-full">
                  <div>
                    <img
                      src={sysarc}
                      alt="Your browser doest not support this content"
                      className="xl:h-20 h-16 xl:w-24 rounded-full"
                    />
                  </div>
                </div>
              </div>
              <div className="xl:w-9/12 xl:pt-12 xl:ml-8 pt-8 pl-4">
                <p className="font font-medium xl:text-xl">Sysarc</p>
                <p className="font font-thin xl:text-lg text-sm">
                  (IT Manager)
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="grid xl:grid-cols-1 xl:gap-4 md:grid-cols-1 bg-white xl:p-4 md:p-4 mb-8 xl:mb-0 p-4 rounded-xl xl:mx-4 md:mx-4">
            <div className="xl:pl-12 pl-4 ">
              <button
                className="bg-black text-5xl text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none pxx"
                type="button"
                href="youtube"
              >
                <i className="">❝</i>
              </button>
              <div className="xl:pt-4 pt-6 xl:pr-4">
                <p className="font xl:text-xl font-thin">
                  "Prompt attention, courteous service, solution oriented
                  approach."
                </p>
              </div>
              <div className="flex xl:w-12/12">
                <div className="xl:w-2/12 xl:pt-10 pt-8 ">
                  <div className="border border-blueGray-500 rounded-full">
                    <div>
                      <img
                        src={mendublicate}
                        alt="Your browser doest not support this content"
                        className="xl:h-20 h-16 xl:w-24 rounded-full"
                      />
                    </div>
                  </div>
                </div>
                <div className="xl:w-9/12 xl:pt-12 xl:ml-8 pt-8 pl-4">
                  <p className="font font-medium xl:text-xl">
                    Ecotech solution
                  </p>
                  <p className="font font-thin xl:text-lg text-sm">
                    (Operation Manager)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid xl:grid-cols-1 xl:gap-4 md:grid-cols-1 bg-white xl:p-4 md:p-4 mb-8 xl:mb-0 p-4 rounded-xl">
          <div className="xl:pl-12 pl-4 ">
            <button
              className="bg-black text-5xl text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none pxx"
              type="button"
              href="youtube"
            >
              <i className="">❝</i>
            </button>
            <div className="xl:pt-4 pt-6 xl:pr-4">
              <p className="font xl:text-xl font-thin">
                "Job was great had Colleagues that were very helpful and taught
                me a lot of information. Thank you 😊"
              </p>
            </div>
            <div className="flex xl:w-12/12">
              <div className="xl:w-2/12 xl:pt-10 pt-8 ">
                <div className="border border-blueGray-500 rounded-full">
                  <div>
                    <img
                      src={mendublicate}
                      alt="Your browser doest not support this content"
                      className="xl:h-20 h-16 xl:w-24 rounded-full"
                    />
                  </div>
                </div>
              </div>
              <div className="xl:w-9/12 xl:pt-12 xl:ml-8 pt-8 pl-4">
                <p className="font font-medium xl:text-xl"> COSAI</p>
                <p className="font font-thin xl:text-lg text-sm">
                  (Data scientist)
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="grid xl:grid-cols-1 xl:gap-4 md:grid-cols-1 bg-white xl:p-4 md:p-6 mb-8 xl:mb-0 p-6 rounded-xl xl:mx-4 md:mx-4">
            <div className="xl:pl-12 pl-4 ">
              <button
                className="bg-black text-5xl text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none pxx"
                type="button"
                href="youtube"
              >
                <i className="">❝</i>
              </button>
              <div className="xl:pt-4 pt-6 xl:pr-4">
                <p className="font xl:text-xl font-thin">
                  "Better and reliable user friendly supporting team......."
                </p>
              </div>
              <div className="flex xl:w-12/12">
                <div className="xl:w-2/12 xl:pt-10 pt-8 ">
                  <div className="border border-blueGray-500 rounded-full">
                    <div>
                      <img
                        src={mendublicate}
                        alt="Your browser doest not support this content"
                        className="xl:h-20 h-16 xl:w-24 rounded-full"
                      />
                    </div>
                  </div>
                </div>
                <div className="xl:w-9/12 xl:pt-12 xl:ml-8 pt-8 pl-4">
                  <p className="font font-medium xl:text-xl">Triway</p>
                  <p className="font font-thin xl:text-lg text-sm">
                    (IT Manager)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid xl:grid-cols-1 xl:gap-4 md:grid-cols-1 bg-white xl:p-4 md:p-4 mb-8 xl:mb-0 p-4 rounded-xl ">
          <div className="xl:pl-12 pl-4 ">
            <button
              className="bg-black text-5xl text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none pxx"
              type="button"
              href="youtube"
            >
              <i className="">❝</i>
            </button>
            <div className="xl:pt-4 pt-6 xl:pr-4">
              <p className="font xl:text-xl font-thin">
                "Excellent service.one time response and clear the issues.thank
                you Team,keep it up"
              </p>
            </div>
            <div className="flex xl:w-12/12">
              <div className="xl:w-2/12 xl:pt-10 pt-8 ">
                <div className="border border-blueGray-500 rounded-full">
                  <div>
                    <img
                      src={witzone}
                      alt="Your browser doest not support this content"
                      className="xl:h-20 h-16 xl:w-24 rounded-full"
                    />
                  </div>
                </div>
              </div>
              <div className="xl:w-9/12 xl:pt-12 xl:ml-8 pt-8 pl-4">
                <p className="font font-medium xl:text-xl">
                  Witzone Technologies
                </p>
                <p className="font font-thin xl:text-lg text-sm">(Director)</p>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="grid xl:grid-cols-1 xl:gap-4 md:grid-cols-1 bg-white xl:p-4 md:p-4 mb-8 xl:mb-0 p-4 rounded-xl xl:mx-4 md:mx-4">
            <div className="xl:pl-12 pl-4 ">
              <button
                className="bg-black text-5xl text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none pxx"
                type="button"
                href="youtube"
              >
                <i className="">❝</i>
              </button>
              <div className="xl:pt-4 pt-6 xl:pr-4">
                <p className="font xl:text-lg font-thin">
                  "We got a good support from MARSLAB and technicaly they are
                  very strong. We are very much happy with them . Many time we
                  approached them for AWS support. "
                </p>
              </div>
              <div className="flex xl:w-12/12">
                <div className="xl:w-2/12 xl:pt-10 pt-8 ">
                  <div className="border border-blueGray-500 rounded-full">
                    <div>
                      <img
                        src={mendublicate}
                        alt="Your browser doest not support this content"
                        className="xl:h-20 h-16 xl:w-24 rounded-full"
                      />
                    </div>
                  </div>
                </div>
                <div className="xl:w-9/12 xl:pt-12 xl:ml-8 pt-8 pl-4">
                  <p className="font font-medium xl:text-xl">Orisenc</p>
                  <p className="font font-thin xl:text-lg text-sm">(CEO) </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid xl:grid-cols-1 xl:gap-4 md:grid-cols-1 bg-white xl:p-4 md:p-4 mb-8 xl:mb-0 p-4 rounded-xl">
          <div className="xl:pl-12 pl-4 ">
            <button
              className="bg-black text-5xl text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none pxx"
              type="button"
              href="youtube"
            >
              <i className="">❝</i>
            </button>
            <div className="xl:pt-4 pt-6 xl:pr-4">
              <p className="font xl:text-xl font-thin">
                "you guys done a great job. Its always pleasure to coordinate
                with you. keep growing."
              </p>
            </div>
            <div className="flex xl:w-12/12">
              <div className="xl:w-2/12 xl:pt-10 pt-8 ">
                <div className="border border-blueGray-500 rounded-full">
                  <div>
                    <img
                      src={mendublicate}
                      alt="Your browser doest not support this content"
                      className="xl:h-20 h-16 xl:w-24 rounded-full"
                    />
                  </div>
                </div>
              </div>
              <div className="xl:w-9/12 xl:pt-12 xl:ml-8 pt-8 pl-4">
                <p className="font font-medium xl:text-xl">Winsar</p>
                <p className="font font-thin xl:text-lg text-sm">
                  (Cloud Architect)
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="grid xl:grid-cols-1 xl:gap-4 md:grid-cols-1 bg-white xl:p-4 md:p-4 mb-8 xl:mb-0 p-4 rounded-xl xl:mx-4 md:mx-4">
            <div className="xl:pl-12 pl-4 ">
              <button
                className="bg-black text-5xl text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none pxx"
                type="button"
                href="youtube"
              >
                <i className="">❝</i>
              </button>
              <div className="xl:pt-4 pt-6 xl:pr-4">
                <p className="font xl:text-xl font-thin">
                  "Aws services super and then any query is asking helping
                  flexible"
                </p>
              </div>
              <div className="flex xl:w-12/12">
                <div className="xl:w-2/12 xl:pt-10 pt-8 ">
                  <div className="border border-blueGray-500 rounded-full">
                    <div>
                      <img
                        src={mendublicate}
                        alt="Your browser doest not support this content"
                        className="xl:h-20 h-16 xl:w-24 rounded-full"
                      />
                    </div>
                  </div>
                </div>
                <div className="xl:w-9/12 xl:pt-12 xl:ml-8 pt-8 pl-4">
                  <p className="font font-medium xl:text-xl">
                    Ask technologies
                  </p>
                  <p className="font font-thin xl:text-lg text-sm">
                    (DB Specialist)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid xl:grid-cols-1 xl:gap-4 md:grid-cols-1 bg-white xl:p-4 md:p-4 mb-8 xl:mb-0 p-4 rounded-xl">
          <div className="xl:pl-12 pl-4 ">
            <button
              className="bg-black text-5xl text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none pxx"
              type="button"
              href="youtube"
            >
              <i className="">❝</i>
            </button>
            <div className="xl:pt-4 pt-6 xl:pr-4">
              <p className="font xl:text-xl font-thin">
                "Service and solutions that they provided was very cost
                effective and high available. Their 15 mins response SLA support
                was really good."
              </p>
            </div>
            <div className="flex xl:w-12/12">
              <div className="xl:w-2/12 xl:pt-10 pt-8 ">
                <div className="border border-blueGray-500 rounded-full">
                  <div>
                    <img
                      src={mendublicate}
                      alt="Your browser doest not support this content"
                      className="xl:h-20 h-16 xl:w-24 rounded-full"
                    />
                  </div>
                </div>
              </div>
              <div className="xl:w-9/12 xl:pt-12 xl:ml-8 pt-8 pl-4">
                <p className="font font-medium xl:text-xl"> Dhanhind</p>
                <p className="font font-thin xl:text-lg text-sm">
                  (IT Manager)
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="grid xl:grid-cols-1 xl:gap-4 md:grid-cols-1 bg-white xl:p-4 md:p-6 mb-8 xl:mb-0 p-6 rounded-xl xl:mx-4 md:mx-4">
            <div className="xl:pl-12 pl-4 ">
              <button
                className="bg-black text-5xl text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none pxx"
                type="button"
                href="youtube"
              >
                <i className="">❝</i>
              </button>
              <div className="xl:pt-4 pt-6 xl:pr-4">
                <p className="font xl:text-xl font-thin">
                  " All staff are very cooperative and helpful They attend to
                  you immediately."
                </p>
              </div>
              <div className="flex xl:w-12/12">
                <div className="xl:w-2/12 xl:pt-10 pt-8 ">
                  <div className="border border-blueGray-500 rounded-full">
                    <div>
                      <img
                        src={mendublicate}
                        alt="Your browser doest not support this content"
                        className="xl:h-20 h-16 xl:w-24 rounded-full"
                      />
                    </div>
                  </div>
                </div>
                <div className="xl:w-9/12 xl:pt-12 xl:ml-8 pt-8 pl-4">
                  <p className="font font-medium xl:text-xl">Nosis</p>
                  <p className="font font-thin xl:text-lg text-sm">
                    (Commercial Manager)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid xl:grid-cols-1 xl:gap-4 md:grid-cols-1 bg-white xl:p-4 md:p-4 mb-8 xl:mb-0 p-4 rounded-xl ">
          <div className="xl:pl-12 pl-4 ">
            <button
              className="bg-black text-5xl text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none pxx"
              type="button"
              href="youtube"
            >
              <i className="">❝</i>
            </button>
            <div className="xl:pt-4 pt-6 xl:pr-4">
              <p className="font xl:text-xl font-thin">
                "Their service was good and timely. Support and flexibility is
                awesome."
              </p>
            </div>
            <div className="flex xl:w-12/12">
              <div className="xl:w-2/12 xl:pt-10 pt-8 ">
                <div className="border border-blueGray-500 rounded-full">
                  <div>
                    <img
                      src={mendublicate}
                      alt="Your browser doest not support this content"
                      className="xl:h-20 h-16 xl:w-24 rounded-full"
                    />
                  </div>
                </div>
              </div>
              <div className="xl:w-9/12 xl:pt-12 xl:ml-8 pt-8 pl-4">
                <p className="font font-medium xl:text-xl"> Dignity India</p>
                <p className="font font-thin xl:text-lg text-sm">(Director)</p>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="grid xl:grid-cols-1 xl:gap-4 md:grid-cols-1 bg-white xl:p-4 md:p-6 mb-8 xl:mb-0 p-6 rounded-xl xl:mx-4 md:mx-4">
            <div className="xl:pl-12 pl-4 ">
              <button
                className="bg-black text-5xl text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none pxx"
                type="button"
                href="youtube"
              >
                <i className="">❝</i>
              </button>
              <div className="xl:pt-4 pt-6 xl:pr-4">
                <p className="font xl:text-xl font-thin">
                  " Prompt attention, courteous service, solution oriented
                  approach."
                </p>
              </div>
              <div className="flex xl:w-12/12">
                <div className="xl:w-2/12 xl:pt-10 pt-8 ">
                  <div className="border border-blueGray-500 rounded-full">
                    <div>
                      <img
                        src={mendublicate}
                        alt="Your browser doest not support this content"
                        className="xl:h-20 h-16 xl:w-24 rounded-full"
                      />
                    </div>
                  </div>
                </div>
                <div className="xl:w-9/12 xl:pt-12 xl:ml-8 pt-8 pl-4">
                <p className="font font-medium xl:text-xl">
                    Ecotech solution
                  </p>
                  <p className="font font-thin xl:text-lg text-sm">
                    (Operation Manager)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        

        {/* <div className="grid xl:grid-cols-1 xl:gap-4 md:grid-cols-1 bg-white xl:p-4 md:p-4 mb-8 xl:mb-0 p-4 rounded-xl">
          <div className="xl:pl-12 pl-4 ">
            <button
              className="bg-black text-5xl text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none pxx"
              type="button"
              href="youtube"
            >
              <i className="">❝</i>
            </button>
            <div className="xl:pt-4 pt-6 xl:pr-4">
              <p className="font xl:text-xl font-thin">
                " They solved our cloud requirements the solution made by sid
                corp is very important in iaas."
              </p>
            </div>
            <div className="flex xl:w-12/12">
              <div className="xl:w-2/12 xl:pt-10 pt-8 ">
                <div className="border border-blueGray-500 rounded-full">
                  <div>
                    <img
                      src="  https://i.ibb.co/JqZXTxk/africa.jpg"
                      alt="Your browser doest not support this content"
                      className="xl:h-20 h-16 xl:w-24 rounded-full"
                    />
                  </div>
                </div>
              </div>
              <div className="xl:w-9/12 xl:pt-12 xl:ml-8 pt-8 pl-4">
                <p className="font font-medium xl:text-xl">AIE Group</p>
                <p className="font font-thin xl:text-lg text-sm">
                  (IT Manager)
                </p>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="">
          <div className="grid xl:grid-cols-1 xl:gap-4 md:grid-cols-1 bg-white xl:p-4 md:p-4 mb-8 xl:mb-0 p-4 rounded-xl xl:mx-4 md:mx-4">
            <div className="xl:pl-12 pl-4 ">
              <button
                className="bg-black text-5xl text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none pxx"
                type="button"
                href="youtube"
              >
                <i className="">❝</i>
              </button>
              <div className="xl:pt-4 pt-6 xl:pr-4">
                <p className="font xl:text-lg font-thin">
                  "We have a marketplace website built on Magento2, after
                  revamping our website design and integrating some plugins, our
                  website page load time increased to 17-20 seconds which is the
                  worst page load time and we got some hits from google adds for
                  a poor load time rate."
                </p>
              </div>
              <div className="flex xl:w-12/12">
                <div className="xl:w-2/12 xl:pt-10 pt-8 ">
                  <div className="border border-blueGray-500 rounded-full">
                    <div>
                      <img
                       src={mendublicate}
                        alt="Your browser doest not support this content"
                        className="xl:h-20 h-16 xl:w-24 rounded-full"
                      />
                    </div>
                  </div>
                </div>
                <div className="xl:w-9/12 xl:pt-12 xl:ml-8 pt-8 pl-4">
                  <p className="font font-medium xl:text-xl">Inasquare</p>
                  <p className="font font-thin xl:text-lg text-sm">(OPS)</p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </Slider>
    </section>
  );
};

export default SliderPage;
