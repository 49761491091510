import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

// views without layouts

import Landing from "views/Landing.js";
import Profile from "views/Profile.js";
import Index from "views/Index.js";

//about us
import Aboutt from "about/aboutpage/Aboutt.js";

// contact us
import Contactus from "about/aboutpage/Contactus";
import Applications from "components/Dropdowns/ApplicationsContent";
import Whymarslab from "components/Dropdowns/Whymarslab";
import Leadership from "components/Dropdowns/Leadership";
import Cloud1 from "components/Cloud/Cloud1";
import Cloudslider from "views/Cloudslider";
import Service from "components/Homepage/Service";
import CloudService from "components/Homepage/CloudService";
import PrivateCloudService from "components/Homepage/PrivateCloudService";
// import PublicCloudService from "components/Homepage/PublicCloudService";
import ModernOperations from "components/Homepage/ModernOperations";
import CloudMigration from "components/Homepage/CloudMigration";
import Cyber from "components/Service/Cyber.js";
import Cloud from "components/Service/Cloud.js";
import Data from "components/Service/Data.js";
import Ai from "components/Service/Ai.js";
import Infrastructure from "components/Service/Infrastructure.js";
import Careers from "about/aboutpage/Careers";



ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* add routes with layouts */}
      {/* <Route path="/admin" component={Admin} />
      <Route path="/auth" component={Auth} /> */}
      {/* <Route path="/aboutt" component={Aboutt} /> */}
      {/* add routes without layouts */}
      {/* <Route path="/landing" exact component={Landing} />
      <Route path="/profile" exact component={Profile} /> */}

      {/* Homepage */}

      {/* Aboutus page */}
      <Route path="/about-us" exact component={Aboutt} />
      <Route path="/whymarslab" exact component={Whymarslab} />
      <Route path="/index" exact component={Index} />
      <Route path="/team" exact component={Leadership} />
      <Route path="/cloud" exact component={Cloud1} />
      <Route path="/cloudslider" exact component={Cloudslider} />

      {/* services */}
      <Route path="/service" exact component={Service} />
      <Route path="/cloudservice" exact component={CloudService} />
      <Route path="/privatecloudservice" exact component={PrivateCloudService} />
      {/* <Route path="/publiccloudservice" exact component={PublicCloudService} /> */}
      <Route path="/modernoperations" exact component={ModernOperations} />
      <Route path="/cloudmigration" exact component={CloudMigration} />

      {/* Contact us */}
      <Route path="/contact" exact component={Contactus} />
      <Route path="/careers" exact component={Careers} />
      <Route path="/" exact component={Index} />

      {/* Inside dropdown content  */}
      <Route path="/applications" exact component={Applications} />

      <Route path="/cyber-security" exact component={Cyber} />
      <Route path="/cloud-engineering" exact component={Cloud} />
      <Route path="/data-engineering" exact component={Data} />
      <Route path="/ai-engineering" exact component={Ai} />
      <Route path="/infrastructure-engineering" exact component={Infrastructure} />


      {/* add redirect for first page */}
      <Redirect from="*" to="/" />
      
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
