import { auto } from "@popperjs/core";
import React from "react";
import marshlabbannerr from "assets/img/marshlabbannerr.jpg";
import diamondpick from "assets/img/icons/diamondpick.jpg";
import redington from "assets/img/icons/redington.jpg";
import srkf from "assets/img/icons/srkf.jpg";
import saksoft from "assets/img/icons/saksoft.jpg";
import indiaoillogo from "assets/img/icons/indiaoillogo.jpg";
import aws from "assets/img/icons/aws.jpg";
import azure from "assets/img/icons/azure.jpg";
import backupeverything from "assets/img/icons/backupeverything.jpg";
import googlegloud from "assets/img/icons/googlegloud.jpg";
import rack from "assets/img/icons/rack.jpg";
import sap from "assets/img/icons/sap.jpg";
import syno from "assets/img/icons/syno.jpg";
import wasabi from "assets/img/icons/wasabi.jpg";
import connectmarslab from "assets/img/connectmarslab.jpg";
import dhanhind from "assets/img/images/dhanhind.jpg";
import sindoorifiber from "assets/img/images/sindoorifiber.jpg";
import dssi from "assets/img/images/dssi.jpg";
import tallymaster from "assets/img/images/tallymaster.jpg";
import sysarc from "assets/img/images/sysarc.jpg";
import sixtags from "assets/img/images/sixtags.jpg";
import swelect from "assets/img/images/swelect.jpg";
import tekclover from "assets/img/images/tekclover.jpg";
import crescent from "assets/img/images/crescent.jpg";
import vivaeducation from "assets/img/images/vivaeducation.jpg";
import codentrix from "assets/img/images/codentrix.jpg";
import witzone from "assets/img/images/witzone.jpg";
import winsar from "assets/img/images/winsar.jpg";
import { HashLink } from "react-router-hash-link";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import kun from "assets/img/kun.jpg";
const handleHover = (event) => {
  event.currentTarget.classList.toggle("paused");
};

const About2 = () => {
  return (
    <section>
      <div className="container mx-auto relative overflow-hidden xl:px-16 px-4">
        <div className="xl:pt-16">
          <div>
            <p className="font xl:text-3xl text-ivory-black font-semibold md:text-2xl text-xl xl:w-9/12 md:pt-4 pt-4 xl:py-0">
              Clientele
            </p>
          </div>
          <section class="slide-option">
            <div id="infinite" class="highway-slider">
              <div class="container highway-barrier">
                <ul class="highway-lane">
                  <li class="highway-car">
                    <span class="">
                      <img
                        src={indiaoillogo}
                        className="marquee-image"
                        alt="Your browser does not support this image"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>
                  <li class="highway-car">
                    <span class="">
                      <img
                        src={kun}
                        className="marquee-image"
                        alt="Your browser does not support this image"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>
                  <li class="highway-car">
                    <span class="">
                      <img
                        src={dhanhind}
                        className="marquee-image"
                        alt="Your browser does not support this image"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>
                  <li class="highway-car">
                    <span class="">
                      <img
                        src={sindoorifiber}
                        className="marquee-image"
                        alt="Your browser does not support this image"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>
                  <li class="highway-car">
                    <span class="">
                      <img
                        src={dssi}
                        className="marquee-image"
                        alt="Your browser does not support this image"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>
                  <li class="highway-car">
                    <span class="">
                      <img
                        src={tallymaster}
                        className="marquee-image"
                        alt="Your browser does not support this image"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>
                  <li class="highway-car">
                    <span class="">
                      <img
                        src={sysarc}
                        className="marquee-image"
                        alt="Your browser does not support this image"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>
                  <li class="highway-car">
                    <span class="">
                      <img
                        src={sixtags}
                        className="marquee-image"
                        alt="Your browser does not support this image"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>
                  <li class="highway-car">
                    <span class="">
                      <img
                        src={swelect}
                        className="marquee-image"
                        alt="Your browser does not support this image"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>
                  <li class="highway-car">
                    <span class="">
                      <img
                        src={tekclover}
                        className="marquee-image"
                        alt="Your browser does not support this image"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>
                  <li class="highway-car">
                    <span class="">
                      <img
                        src={crescent}
                        className="marquee-image"
                        alt="Your browser does not support this image"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>
                  <li class="highway-car">
                    <span class="">
                      <img
                        src={vivaeducation}
                        className="marquee-image"
                        alt="Your browser does not support this image"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>
                  <li class="highway-car">
                    <span class="">
                      <img
                        src={codentrix}
                        className="marquee-image"
                        alt="Your browser does not support this image"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>
                  <li class="highway-car">
                    <span class="">
                      <img
                        src={witzone}
                        className="marquee-image"
                        alt="Your browser does not support this image"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>
                  <li class="highway-car">
                    <span class="">
                      <img
                        src={winsar}
                        className="marquee-image"
                        alt="Your browser does not support this image"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>
                  <li class="highway-car">
                    <span class="">
                      <img
                        src={diamondpick}
                        className="marquee-image"
                        alt="Your browser does not support this image"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>
                  <li class="highway-car">
                    <span class="">
                      <img
                        src={redington}
                        className="marquee-image"
                        alt="Your browser does not support this image"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>
                  <li class="highway-car">
                    <span class="">
                      <img
                        src={srkf}
                        className="marquee-image"
                        alt="Your browser does not support this image"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>
                  <li class="highway-car">
                    <span class="">
                      <img
                        src={saksoft}
                        className="marquee-image"
                        alt="Your browser does not support this image"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>

        <div className="xl:pt-8 xl:pb-20">
          <div>
            <p className="font xl:text-3xl text-ivory-black font-semibold md:text-2xl text-xl xl:w-9/12 md:pt-4 pt-4 xl:py-0">
              Technology Partners
            </p>
          </div>
          <section class="slide-option">
            <div id="infinite" class="highway-sliderr">
              <div class="container highway-barrierr">
                <ul class="highway-lanee">
                  <li class="highway-carr">
                    <span class="">
                      <img
                        src={aws}
                        className="marquee-image"
                        alt="6"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>
                  <li class="highway-carr">
                    <span class="">
                      <img
                        src={azure}
                        className="marquee-image"
                        alt="4"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>
                  <li class="highway-carr">
                    <span class="">
                      <img
                        src={backupeverything}
                        className="marquee-image"
                        alt="4"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>
                  <li class="highway-carr">
                    <span class="">
                      <img
                        src={googlegloud}
                        className="marquee-image"
                        alt="4"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>

                  <li class="highway-carr">
                    <span class="">
                      <img
                        src={rack}
                        className="marquee-image"
                        alt="4"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>
                  <li class="highway-carr">
                    <span class="">
                      <img
                        src={sap}
                        className="marquee-image"
                        alt="4"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>

                  <li class="highway-carr">
                    <span class="">
                      <img
                        src={syno}
                        className="marquee-image"
                        alt="4"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>
                  <li class="highway-carr">
                    <span class="">
                      <img
                        src={wasabi}
                        className="marquee-image"
                        alt="4"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>

                  <li class="highway-carr">
                    <span class="">
                      <img
                        src={aws}
                        className="marquee-image"
                        alt="6"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>
                  <li class="highway-carr">
                    <span class="">
                      <img
                        src={azure}
                        className="marquee-image"
                        alt="4"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>
                  <li class="highway-carr">
                    <span class="">
                      <img
                        src={backupeverything}
                        className="marquee-image"
                        alt="4"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>
                  <li class="highway-carr">
                    <span class="">
                      <img
                        src={googlegloud}
                        className="marquee-image"
                        alt="4"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>

                  <li class="highway-carr">
                    <span class="">
                      <img
                        src={rack}
                        className="marquee-image"
                        alt="4"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>
                  <li class="highway-carr">
                    <span class="">
                      <img
                        src={sap}
                        className="marquee-image"
                        alt="4"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                      />
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </div>

      <div
        style={{
          backgroundImage: `url(${marshlabbannerr})`,
          width: "100%",
          backgroundSize: "cover",
        }}
        className="xl:h-460 md:h-25"
      >
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4">
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 xl:py-16">
            <div className="xl:block md:block lg:block block ">
              <div className="font mt-8 xl:mt-0 md:p-6 md:mt-4 xl:p-0 lg:p-10">
                <img
                  src={connectmarslab}
                  alt="your browser doesnot support this content"
                  className="xl:w-650 md:h-200 lg:h-330 rounded-xl"
                ></img>
              </div>
            </div>

            <div className="font">
              <div className="xl:p-0 xl:pl-16 lg:p-12">
                <div className="">
                  <p className="font xl:text-4xl md:text-2xl text-xl text-white xl:pt-10 lg:pt-8 md:pt-8 pt-4">
                    Connect with MARSLAB
                  </p>
                </div>

                <p className="font text-white xl:text-lg text-sm xl:pt-8 md:pt-4 pt-4">
                  Connect with a MARSLAB expert to discuss how to design, build,
                  manage and modernize the mission-critical technology systems.
                </p>
                <div className="xl:my-0 md:my-0 my-6">
                  <div className="mb-8">
                    <HashLink smooth to="/contact#enquiry">
                      <button className="xl:buttonnewshade1 md:buttonnewshade1 buttonnewshade1">
                        Talk to an expert ➙
                      </button>
                    </HashLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container mx-auto relative overflow-hidden xl:px-16 px-4">
        <div className="grid xl:grid-cols-2 md:py-16 py-10 ">
          <div>
            <p className="font text-red-500 xl:text-4xl md:text-4xl text-4xl font-square">
              Connect with Kyndryl
            </p>
            <p className="font text-black xl:text-lg text-lg xl:pt-8 md:pt-8 pt-4">
              Connect with a Kyndryl expert to discuss how to design, build,
              manage and modernize the mission-critical technology systems.
            </p>
            <div className="mb-8">
              <button className="buttonnewshadenew">Talk to an expert ➙</button>
            </div>
          </div>
          <div></div>
        </div>
      </div> */}
    </section>
  );
};
export default About2;
