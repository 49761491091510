import React, { useState } from "react";
import Footer from "components/Footers/Footer";
import IndexNavbar from "components/Navbars/IndexNavbar";
import ApplicationContent2 from "components/Dropdowns/ApplicationContent2";
import ApplicationContent3 from "components/Dropdowns/ApplicationContent3";
import marshlabbannerr from "assets/img/marshlabbannerr.jpg";
import mission from "assets/img/icons/mission.png";
import customer from "assets/img/icons/customer.png";
import success from "assets/img/icons/success.png";
import sharedvision from "assets/img/icons/shared-vision.png";
import atworkhappyteamwithlaptop from "assets/img/images/atwork-happyteamwithlaptop.jpg";
import vision from "assets/img/images/vision.png";
import mission1 from "assets/img/images/mission1.png";
import datapreprocessing from "assets/img/datapreprocessing.webp";
import whyprocessing from "assets/img/whyprocessing.webp";

const Data = () => {
  const totalSteps = 3;
  const [currentStep, setCurrentStep] = useState(1);

  const handleNext = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrev = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const stepsContent = [
    {
      title: "Our Mission",
      content: (
        <div>
          <p>
            We are committed to providing comprehensive IT solutions that align
            with our customers' unique needs and aspirations. We strive to:
          </p>
        </div>
      ),
    },
    {
      title: "Application Security Audit Frameworks",
      content: (
        <div>
          <p>
            To be the premier IT solutions provider, recognized for our
            exceptional expertise, unwavering commitment to customer success,
            and dedication to ethical practices.
          </p>
        </div>
      ),
    },
    {
      title: "Our Values",
      content: (
        <div>
          <p>
            Our core values guide our every action and decision, ensuring that
            we remain a trusted partner for our customers:
          </p>
        </div>
      ),
    },
    {
      title: "Excellence",
      content: (
        <div>
          <p>
            Our core values guide our every action and decision, ensuring that
            we remain a trusted partner for our customers:
          </p>
        </div>
      ),
    },
  ];

  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <section>
      <IndexNavbar />
      <div className="xl:mt-20 lg:mt-18 mt-16">
        <div className="bg-stone-200 ">
          <div className="container mx-auto text-danger relative overflow-hidden xl:px-16 px-4 xl:py-4 lg:py-4 py-4">
            <a href="index" className="link text-ivory-black">
              Home {"  "} {/* space purpose */}
            </a>
            {"  "}― {"  "}
            <a href="aboutt" className="link">
              Services {"  "}
            </a>
            {"  "} ― {"  "}
            Data Engineering
          </div>
        </div>
      </div>

      {/* section */}

      <div className="">
        <div className="xl:block lg:block md:block block font">
          <div
            style={{
              backgroundImage: `url(${marshlabbannerr})`,
              width: "100%",
            }}
            className="bg-ivory-black xl:h-80 md:h-60 h-48 relative"
          >
            <p className="font uppercase xl:text-5xl md:text-2ml text-xl text-white text-center xl:pt-32 lg:pt-28 md:pt-28 pt-20 font-semibold">
              Data Engineering
            </p>
          </div>
        </div>
      </div>

      {/* Cyber threat awarness */}

      <div className="container mx-auto relative overflow-hidden xl:px-16 xl:py-16 lg:py-8 md:py-8 py-4 px-4">
        <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-4 lg:grid-cols-2 lg:gap-4  xl:gap-4">
          <div className="">
            <h1 className="font font-semibold xl:text-2xl lg:text-2xl md:text-lg text-lg">
              What are Predictive Analytics?
            </h1>
            <p className="font pt-2">
              Armed with the calculus of history, predictive analytics builds
              bridges into the future, using statistical models and past data to
              illuminate potential outcomes like a mathematically empowered
              fortune teller.
            </p>
            <p className="font py-2">
              The power of predictive analytics lies not in the crystal ball
              itself, but in the actionable roadmap it creates. Companies
              leverage insightful forecasts to fine-tune marketing, prevent
              costly outages, delight customers with personalized offerings, and
              navigate the future with confidence, one data-driven step at a
              time.
            </p>
            <p className="font py-2">
              Rather than simply analyzing the past, predictive analytics
              algorithms wield the power of historical data to anticipate the
              future. They scrutinize past events, transactions, and customer
              behavior, paving the way for proactive decisions and optimized
              outcomes.
            </p>
          </div>
          <div className="xl:pl-12 xl:pt-0 lg:pt-0 md:pt-4 pt-0">
            <img
              src="https://i.ibb.co/0BdFMZ5/predictiveanalysis1.png"
              className="rounded-md"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 xl:pt-12">
          <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-4 lg:grid-cols-2 lg:gap-4 xl:gap-6 py-6">
            <div className="xl:block md:block lg:block hidden">
              <img
                src="https://i.ibb.co/CKrqVcp/predictiveanalysis.png"
                className="rounded-md xl:pt-0 md:pt-8 pt-0 lg:pt-8"
              ></img>
            </div>
            <div className="pt-4 xl:pt-2 xl:pl-12">
              <h2 className="font font-semibold xl:text-2xl lg:text-2xl md:text-xl">
                Why Predictive Analytics?
              </h2>
              <p className="font text-base pt-2">
                Kick gut feelings to the curb and say hello to the future!
                Predictive analytics is your time traveling data guru, revealing
                what's coming to optimize campaigns, avoid breakdowns,
                personalize experiences, and outsmart risk, all before it even
                happens.
              </p>
              <p className="font text-base pt-2">
                In a world of data-driven gladiators, predictive analytics
                becomes the ultimate weapon, offering a decisive edge by
                unlocking insights that optimize operations, personalize
                experiences, and predict failures before they strike.
              </p>
              <p className="font text-base pt-2 xl:pb-0 lg:pb-0 md:pb-0 pb-4">
                Bridging the gap between human intuition and data-driven
                foresight, predictive analytics empowers businesses to make
                smarter decisions, together. It fuels strategic investments,
                tailors customer journeys, and anticipates disruptions, paving
                the way for a symbiotic future of success.
              </p>
            </div>
            <div className="xl:hidden md:hidden lg:hidden block">
              <img
                src="https://i.ibb.co/CKrqVcp/predictiveanalysis.png"
                className="rounded-md xl:pt-0 md:pt-8 pt-0 lg:pt-8"
              ></img>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 xl:pt-6">
          <div className="grid xl:grid-cols-2 xl:gap-6 py-6">
            <div className="pt-4">
              <div className="flex items-center">
                <p className="font-bold text-red xl:text-5xl">01</p>
                <h2 className="font-semibold xl:text-xl font xl:py-2 pl-2">
                  What is data pre-processing?
                </h2>
              </div>
              <p className="font text-base pt-2">
                Think of raw data as a chaotic attic, overflowing with
                mismatched socks, faded maps, and cryptic notes. Data cleaning
                becomes the meticulous archivist, sorting, dusting, and
                deciphering the clutter to uncover nuggets of usable
                information.
              </p>
              <p className="font text-base pt-2">
                Data, like a multifaceted jewel, requires careful faceting to
                reveal its true brilliance. Preprocessing involves scaling
                numerical values, encoding text into numerical representations,
                and converting categorical variables, ensuring each dimension of
                data is ready to shine in the light of analysis.
              </p>
              <p className="font text-base pt-2">
                Think of data sources as scattered puzzle pieces. Data
                integration plays the role of the master puzzle builder,
                meticulously assembling surveys, transactions, and other
                fragments into a single, stunning picture that unveils the whole
                truth.
              </p>
            </div>

            <div className="">
              <div className="pt-4 xl:pl-12">
                <div className="flex items-center">
                  <p className="font-bold text-red xl:text-5xl">02</p>
                  <h2 className="font-semibold xl:text-xl font xl:py-2 pl-2">
                    Why data pre-processing?
                  </h2>
                </div>
                <p className="font text-base pt-2">
                  Preprocessing acts as a bridge between data chaos and human
                  understanding. It refines information, making it easier to
                  navigate, interpret, and ultimately extract valuable
                  knowledge, enabling analysts to see patterns and make sense of
                  the world through data's lens.
                </p>
                <p className="font text-base pt-2">
                  Preprocessing elevates data analysis by bolstering accuracy
                  and reliability, simplifying interpretation, mitigating bias,
                  and optimizing size, ultimately paving the way for robust and
                  efficient insights
                </p>
                <p className="font text-base pt-2">
                  Preprocessing isn't just about neatness; it's about
                  trustworthy insights. By ensuring clean, accurate data, it
                  empowers confident decisions, fuels reliable predictions, and
                  steers clear of misleading conclusions, ultimately shaping a
                  data-driven future.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="font container mx-auto relative overflow-hidden xl:px-16 px-4 xl:py-8 lg:py-0 space-y-6 lg:space-y-2 pb-4 xl:pt-24 pt-8">
        <div className="tab-buttons text-center  xl:px-36">
          <div className="grid xl:grid-cols-2 md:grid-cols-2 grid-cols-1 border-b-c xl:gap-0 gap-2 ">
            <button
              className={`${
                activeTab === 1 ? "active xl:text-xl text-xs" : "border-sky-600"
              } font font-semibold mx-4 xl:text-xl text-base xl:pt-2 xl:py-0 py-2 focus:outline-none`}
              onClick={() => handleTabClick(1)}
            >
              <div className="flex items-center xl:ml-10">
                <img
                  src="https://i.ibb.co/kqr6g8q/applicationsecurity.png"
                  className="xl:h-10 xl:w-10 xl:mr-2 xl:mb-2 h-5 w-5 mr-2"
                />
                <p className="xl:text-xl lg:text-lg xl:mb-2 text-left pl-2">
                  {" "}
                  What is data pre-processing?
                </p>
              </div>
            </button>

            <button
              className={`${
                activeTab === 2 ? "active xl:text-xl text-xs" : "border-sky-600"
              } font font-semibold mx-4 xl:text-xl text-base xl:pt-2 xl:py-0 py-2 focus:outline-none`}
              onClick={() => handleTabClick(2)}
            >
              <div className="flex items-center xl:ml-10">
                <img
                  src="https://i.ibb.co/CwpwFXB/networksecurity.png"
                  className="xl:h-10 xl:w-10 xl:mr-2 xl:mb-2 h-5 w-5 mr-2"
                />
                <p className="xl:text-xl lg:text-lg xl:mb-2 text-left pl-2">
                  Why data pre-processing?
                </p>
              </div>
            </button>
          </div>
        </div>
      </div>

      <div className="font container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-16">
        <div className="tab-content text-center">
          {activeTab === 1 && (
            <div className="content-center xl:px-5 xl:pt-0 xl:text-xl">
              {/* Content for Our Vision */}
              <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-4 lg:grid-cols-2 lg:gap-4 text-lg ">
                <div className="font text-left xl:text-lg text-sm xl:pt-4 xl:p-5 p-5 ">
                  <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                    Think of raw data as a chaotic attic, overflowing with
                    mismatched socks, faded maps, and cryptic notes. Data
                    cleaning becomes the meticulous archivist, sorting, dusting,
                    and deciphering the clutter to uncover nuggets of usable
                    information.
                  </p>
                  <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                    Data, like a multifaceted jewel, requires careful faceting
                    to reveal its true brilliance. Preprocessing involves
                    scaling numerical values, encoding text into numerical
                    representations, and converting categorical variables,
                    ensuring each dimension of data is ready to shine in the
                    light of analysis.
                  </p>
                  <p className="font xl:text-base lg:text-base md:text-sm pt-2">
                    Think of data sources as scattered puzzle pieces. Data
                    integration plays the role of the master puzzle builder,
                    meticulously assembling surveys, transactions, and other
                    fragments into a single, stunning picture that unveils the
                    whole truth.
                  </p>
                </div>
                <div >
                  <img src={datapreprocessing} className="rounded-md xl:mt-0 lg:mt-8 md:mt-8 mt-0" />
                </div>
              </div>
            </div>
          )}
          {activeTab === 2 && (
            <div className="content-center xl:px-5 xl:pt-0 xl:text-xl">
              {/* Content for Our Vision */}
              <div className="grid xl:grid-cols-2  md:grid-cols-2 md:gap-4 lg:grid-cols-2 lg:gap-4 text-lg ">
                <div className="font text-left xl:text-lg text-sm xl:pt-4 xl:p-5 p-5">
                  <p className="font xl:text-base lg:text-base md:text-sm  pt-2">
                    Preprocessing acts as a bridge between data chaos and human
                    understanding. It refines information, making it easier to
                    navigate, interpret, and ultimately extract valuable
                    knowledge, enabling analysts to see patterns and make sense
                    of the world through data's lens.
                  </p>
                  <p className="font xl:text-base lg:text-base md:text-sm  pt-2">
                    Preprocessing elevates data analysis by bolstering accuracy
                    and reliability, simplifying interpretation, mitigating
                    bias, and optimizing size, ultimately paving the way for
                    robust and efficient insights
                  </p>
                  <p className="font xl:text-base lg:text-base md:text-sm  pt-2">
                    Preprocessing isn't just about neatness; it's about
                    trustworthy insights. By ensuring clean, accurate data, it
                    empowers confident decisions, fuels reliable predictions,
                    and steers clear of misleading conclusions, ultimately
                    shaping a data-driven future.
                  </p>
                </div>
                <div >
                  <img src={whyprocessing} className="rounded-md xl:mt-0 lg:mt-8 md:mt-8 mt-0"/>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 xl:pt-12">
          <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-4 lg:grid-cols-2 lg:gap-4 xl:gap-6 py-6">
            <div className="pt-4 xl:pt-10">
              <h2 className="font font-semibold xl:text-2xl lg:text-xl">
                What is Text Extraction?
              </h2>
              <p className="font text-base pt-2">
                Text extraction identifies and retrieves pertinent textual
                information from various sources, including documents, images,
                and web content, unlocking the value hidden within diverse
                formats.
              </p>
              <p className="font text-base pt-2">
                Algorithms streamline information retrieval by identifying key
                phrases and keywords, enabling targeted extraction of relevant
                content, and ensuring efficient access to the most meaningful
                insights within a text.
              </p>
              <p className="font text-base pt-2">
                Leveraging text extraction from diverse sources like customer
                reviews and social media, businesses glean insights into market
                trends, customer sentiment, and competitor analysis, informing
                strategic decision-making.
              </p>
            </div>
            <div className="pl-12 xl:pt-0 lg:pt-0 md:pt-8 pt-4">
              <img
                src="https://i.ibb.co/qyBTg39/textextraction1.png"
                className="rounded-md"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 xl:pt-12">
          <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-4 lg:grid-cols-2 lg:gap-4 xl:gap-6 py-6">
            <div className="xl:block lg:block md:block hidden">
              <img
                src="https://i.ibb.co/9GYgjLc/textextraction.png"
                className="rounded-md xl:pt-0 lg:pt-0 md:pt-12"
              ></img>
            </div>
            <div className="pt-4 xl:pt-10 xl:pl-12">
              <h2 className="font font-semibold xl:text-2xl">
                Why text Extraction?
              </h2>
              <p className="font text-base pt-2">
                Text extraction revolutionizes how we interact with information,
                automating tedious tasks, enriching data analysis, unlocking
                hidden treasures, and ensuring accuracy, ultimately transforming
                the way we gather and utilize knowledge.
              </p>
              <p className="font text-base pt-2">
                It refines data, unearths hidden gems, and enhances accuracy,
                transforming information into a potent tool for decision-making
                and knowledge discovery.
              </p>
              <p className="font text-base pt-2">
                Text extraction empowers machines to become information
                extraction ninjas, saving time and resources by autonomously
                identifying and retrieving nuggets of knowledge from vast
                textual oceans.
              </p>
            </div>
            <div className="xl:hidden lg:hidden md:hidden block">
              <img
                src="https://i.ibb.co/9GYgjLc/textextraction.png"
                className="rounded-md xl:pt-0 lg:pt-0 md:pt-12 pt-4"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 xl:pt-6">
          <div className="grid xl:grid-cols-2 xl:px-16 md:grid-cols-2 md:gap-0 lg:grid-cols-2 lg:gap-0 rounded-md xl:gap-0 py-6">
            <div className="">
              <div className="bg-grey-100 p-6 rounded-l-md">
                <div className="flex items-center">
                  <p className="font-bold text-red xl:text-5xl md:text-2xl">01</p>
                  <h2 className="font-semibold xl:text-xl font xl:py-2 pl-2">
                    What is Data Encoder?
                  </h2>
                </div>
                <p className="font text-base pt-2">
                  Data encoding, translates information for machine
                  understanding, enabling efficient storage, transmission, and
                  analysis. As data permeates every facet of life, embracing its
                  foundational principles empowers us to navigate the digital
                  landscape with confidence and insight.
                </p>
                <p className="font text-base pt-2">
                  Encoding bridges language barriers by translating different
                  character sets into a universal digital language.
                </p>
                <p className="font text-base pt-2 xl:pb-8 md:pb-8 pb-8">
                  Imagine letters morphing, numbers dancing, and images
                  dissolving, all whispering their secrets in a binary ballet,
                  the symphony of 0s and 1s that machines understand.
                </p>
              </div>
            </div>
            <div className="bg-red-100 p-6 rounded-r-md">
              <div className="">
                <div className="flex items-center">
                  <p className="font-bold text-red xl:text-5xl md:text-2xl">02</p>
                  <h2 className="font-semibold xl:text-xl font xl:py-2 pl-2">
                    Why data pre-processing?
                  </h2>
                </div>
                <p className="font text-base pt-2">
                  Data encoding serves three primary purposes
                </p>
                <ul className="list-none xl:pt-4 pt-4">
                  <li className="font xl:text-base text-sm flex items-start ">
                    <span className="mr-2 text-red">✔</span> Bridging the
                    language barrier between humans and computers.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start py-3">
                    <span className="mr-2">✔</span> Optimizing storage and
                    transmission efficiency.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start ">
                    <span className="mr-2 text-red">✔</span> Ensuring data
                    integrity through error detection and correction mechanisms.
                  </li>
                </ul>
                <p className="font text-base pt-2">
                  To bridge the divide between human expression and machine
                  understanding, data encoding acts as a digital interpreter,
                  expertly translating our words, images, and sounds into the
                  binary language computers can comprehend.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 xl:pt-12">
          <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-4 lg:grid-cols-2 lg:gap-4  xl:gap-6 py-6">
            <div className="pt-4 xl:pt-12">
              <h2 className="font font-semibold xl:text-2xl lg:text-xl">
                What is Business Intelligence?
              </h2>
              <p className="font text-base pt-2">
                BI acts as your digital archaeologist, meticulously brushing
                away dust, piecing together fragments, and visualizing the
                hidden patterns and trends with charts, graphs, and dashboards,
                revealing the buried riches within.
              </p>
              <p className="font text-base pt-2">
                It translates data into actionable insights and recommendations,
                empowering business leaders to navigate the complexities of
                marketing, sales, finance, and operations with informed steps
                and strategic decisions.
              </p>
            </div>
            <div className="xl:pl-12 xl:pt-0 lg:pt-0 md:pt-8 pt-4">
              <img
                src="https://i.ibb.co/DYLc56Y/businessintelligence1.png"
                className="rounded-md"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 xl:pt-12">
          <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-4 lg:grid-cols-2 lg:gap-4 xl:gap-6 py-6">
            <div className="xl:block lg:block md:block hidden">
              <img
                src="https://i.ibb.co/SwbDp4y/businessintelligence.png"
                className="rounded-md xl:pt-0 lg:pt-0 md:pt-12 pt-0"
              ></img>
            </div>
            <div className="pt-4 xl:pl-12 xl:pt-12">
              <h2 className="font font-semibold xl:text-2xl">
                Why Business Intelligence?
              </h2>
              <p className="font text-base pt-2">
                From sharper decisions to smoother processes, and deeper customer
                understanding to a sharper competitive edge, BI delivers a
                symphony of benefits, orchestrating organizational growth across
                all key areas.
              </p>

              <p className="font text-base pt-2">
                It boosts bottom lines through smarter decisions, reduces waste
                through efficiency gains, fosters loyalty through
                customer-centricity, and positions businesses for dominance
                through strategic agility.
              </p>
              <p className="font text-base pt-2">
                It unlocks data's hidden potential, driving better choices,
                streamlining operations, delighting customers, and leaving the
                competition in the dust.
              </p>
            </div>
            <div className="xl:hidden lg:hidden md:hidden block">
              <img
                src="https://i.ibb.co/SwbDp4y/businessintelligence.png"
                className="rounded-md xl:pt-0 lg:pt-0 md:pt-12 pt-4"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </section>
  );
};

export default Data;
