import React, { useState } from "react";
import Footer from "components/Footers/Footer";
import IndexNavbar from "components/Navbars/IndexNavbar";
import ApplicationContent2 from "components/Dropdowns/ApplicationContent2";
import ApplicationContent3 from "components/Dropdowns/ApplicationContent3";
import marshlabbannerr from "assets/img/marshlabbannerr.jpg";
import mission from "assets/img/icons/mission.png";
import customer from "assets/img/icons/customer.png";
import success from "assets/img/icons/success.png";
import sharedvision from "assets/img/icons/shared-vision.png";
import atworkhappyteamwithlaptop from "assets/img/images/atwork-happyteamwithlaptop.jpg";
import vision from "assets/img/images/vision.png";
import mission1 from "assets/img/images/mission1.png";
import enterprisemailsolutions from "assets/img/enterprisemailsolutions.webp";
import whyenterprisemailsolutions from "assets/img/whyenterprisemailsolutions.jpg";
import mailingsolutions from "assets/img/mailingsolutions.jpg";

const Infrastructure = () => {
  const totalSteps = 3;
  const [currentStep, setCurrentStep] = useState(1);

  const handleNext = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrev = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const stepsContent = [
    {
      title: "Our Mission",
      content: (
        <div>
          <p>
            We are committed to providing comprehensive IT solutions that align
            with our customers' unique needs and aspirations. We strive to:
          </p>
        </div>
      ),
    },
    {
      title: "Application Security Audit Frameworks",
      content: (
        <div>
          <p>
            To be the premier IT solutions provider, recognized for our
            exceptional expertise, unwavering commitment to customer success,
            and dedication to ethical practices.
          </p>
        </div>
      ),
    },
    {
      title: "Our Values",
      content: (
        <div>
          <p>
            Our core values guide our every action and decision, ensuring that
            we remain a trusted partner for our customers:
          </p>
        </div>
      ),
    },
    {
      title: "Excellence",
      content: (
        <div>
          <p>
            Our core values guide our every action and decision, ensuring that
            we remain a trusted partner for our customers:
          </p>
        </div>
      ),
    },
  ];

  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <section>
      <IndexNavbar />
      <div className="xl:mt-20 lg:mt-18 mt-16">
        <div className="bg-stone-200 ">
          <div className="container mx-auto relative text-danger overflow-hidden xl:px-16 px-4 xl:py-4 lg:py-4 py-4">
            <a href="index" className="link text-ivory-black">
              Home {"  "} {/* space purpose */}
            </a>
            {"  "}― {"  "}
            <a href="aboutt" className="link">
              Services {"  "}
            </a>
            {"  "} ― {"  "}
            Infrastructure Engineering
          </div>
        </div>
      </div>

      {/* section */}

      <div className="">
        <div className="xl:block lg:block md:block block font">
          <div
            style={{
              backgroundImage: `url(${marshlabbannerr})`,
              width: "100%",
            }}
            className="bg-ivory-black xl:h-80 md:h-60 h-48 relative"
          >
            <p className="font uppercase xl:text-5xl lg:text-4xl xl:text-5xl md:text-3xl text-xl text-white text-center xl:pt-32 lg:pt-28 md:pt-28 pt-20 font-semibold">
              Infrastructure Engineering
            </p>
          </div>
        </div>
      </div>

      {/* Cyber threat awarness */}

      <div className="container mx-auto relative overflow-hidden xl:px-16 xl:py-8 lg:py-8 md:py-8 py-4 px-4">
        <div className="grid xl:grid-cols-1 xl:gap-4">
          <div className="xl:pt-6 text-center">
            <h1 className="font font-semibold xl:text-3xl lg:text-3xl md:text-2xl text-xl text-red xl:pb-3 lg:pb-3 md:pb-3 sm:pb-3 pb-3">
              Backup & Restore Service
            </h1>
            <p className="font pt-2 xl:px-24 xl:text-lg">
              MARSLAB, the most substantial Backup Cloud Company, defends your
              data from the unforeseen with the smartest move as per your
              business needs since all the businesses expanding online, the need
              for backup arises.
            </p>
            <p className="font py-2 xl:px-24  xl:text-lg">
              We overhaul your data protection ensuring your data is always
              dependable and ready to access, we are impregnable to your
              enterprise which is the business data that plays a vital role in
              all successful business model. Start your data resiliency journey
              here with MARSLAB.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 xl:pt-6">
          <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-4 lg:grid-cols-2 lg:gap-4 xl:gap-6 py-6">
            <div className="xl:mt-2 lg:mt-2 md:mt-16 mt-0">
              <img
                src="https://i.ibb.co/Qff87Cf/marshlabprovide.png"
                className="rounded-md"
              ></img>
            </div>
            <div className="pt-4 xl:pl-12">
              <h2 className="font font-semibold xl:text-xl">
                What MARSLAB Provide?
              </h2>
              <ul className="list-none xl:pt-4 pt-4">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Ease Adaptable:
                  Clients can always scale up or down their storage needs
                  depending on the changing business requirements through our
                  cloud backup service swiftly.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2 ">✔</span>Reliable: Our cloud
                  backup service are designed to be highly reliable with regular
                  updates and disaster recovery plans to ensure your data is
                  always safe.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Cost Effective: With our cloud
                  backup service, you only pay for the amount of storage that
                  suits your needs. Expensive hardware or software is not
                  required at all.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2 ">✔</span> Secure: With our
                  cloud backup service, organizations can be assured their data
                  are secured through layers of security to protect their data
                  of any malicious attack.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto relative overflow-hidden xl:px-16 xl:py-16 md:py-8 px-4">
        <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-4 lg:grid-cols-2 lg:gap-4 xl:gap-12">
          <div className="">
            <h1 className="font font-semibold xl:text-2xl mt-6">Our Specialty</h1>

            <p className="font py-2">
              <ul className="list-none xl:pt-4 pt-0">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Centralized
                  Management: We'll provide a robust monitoring and operations
                  platform to ensure your data is protected at scale, with a
                  single view of the entire environment.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Multiple Workload Support: We
                  ensure that the backup is safely stored and ready to restore
                  whenever required.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Multiple Storage:
                  Store your backups in LRS, GRS and ZR disco. This will ensure
                  that they are always available for use no matter where you
                  happen to be.
                </li>
                <h1 className="font font-semibold xl:text-2xl xl:pt-4 md:pt-4 lg:pt-4 pt-2">
                  Restore
                </h1>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2 text-red">✔</span>The process of
                  retrieving backed-up data and returning it to its original
                  location or a new system. It's like time travel for your data,
                  allowing you to recover from unexpected disasters.
                </li>
              </ul>
            </p>
          </div>
          <div className="xl:mt-0 lg:mt-4 md:mt-24 mt-0">
            <img
              src="https://i.ibb.co/HDBtS3D/backupdata.png"
              className="rounded-md "
              alt="Your browser does not support this part content"
            />
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-8 xl:pt-6">
          <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-4 lg:grid-cols-2 lg:gap-4 xl:gap-6 py-6">
            <div className="xl:block lg:block md:block hidden">
              <div className="xl:mt-16 lg:mt-24 md:mt-48 mt-0">
                <img
                  src="https://i.ibb.co/tBHzr3s/cyberstorage.png"
                  className="rounded-md  "
                ></img>
              </div>
            </div>
            <div className="pt-4 xl:pl-12">
              <h2 className="text-red font font-semibold xl:text-2xl">
                Storage Solution
              </h2>
              <p className="font pt-2">
                The perfect storage solution is a harmonious composition of your
                needs, budget, and security requirements. By understanding your
                data and exploring the available options, you can create a
                symphony of storage that safeguards your valuable information
                and keeps your digital world in tune.
              </p>
              <p className="font py-2">
                <ul className="list-none xl:pt-2 pt-4">
                  <li className="font xl:text-base text-sm flex items-start ">
                    <span className="mr-2 text-red">✔</span>Hard Disk Drives
                    (HDDs): Traditional and affordable, but slower and prone to
                    physical damage.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start py-3">
                    <span className="mr-2">✔</span> Solid State Drives (SSDs):
                    Significantly faster and more durable, but pricier., Optical
                    Drives (CDs, DVDs): Long-term archival option, but limited
                    capacity and access speed.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start ">
                    <span className="mr-2 text-red">✔</span> Network Attached
                    Storage (NAS): Centralized storage accessible from multiple
                    devices on a network, offering convenience and scalability.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start py-3">
                    <span className="mr-2 ">✔</span>Cloud Storage:
                    Remote storage accessed via the internet, with advantages
                    like accessibility, redundancy, and flexibility. Popular
                    options include Dropbox, Google Drive, and Amazon S3.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start ">
                    <span className="mr-2 text-red">✔</span> Backup Solutions: Dedicated
                    solutions for data protection and disaster recovery,
                    ensuring data redundancy and ease of restoration. Consider
                    external hard drives or cloud backup services.
                  </li>
                </ul>
              </p>
            </div>
            <div className="xl:hidden lg:hidden md:hidden block">
              <img
                src="https://i.ibb.co/tBHzr3s/cyberstorage.png"
                className="rounded-md xl:pl-12 xl:pt-12 lg:pt-12 md:pt-12 mt-2"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto relative overflow-hidden xl:px-16 xl:py-16 md:py-8 px-4">
        <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-4 lg:grid-cols-2 lg:gap-4 xl:gap-12">
          <div className="">
            <h1 className="font font-semibold xl:text-2xl xl:mt-10 lg:mt-10 xl:mt-10 mt-4">
              Choosing the Right Instrument
            </h1>

            <p className="font py-2">
              <ul className="list-none xl:pt-2 pt-0">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> For everyday personal
                  use: Cloud storage like Google Drive or OneDrive might be
                  ideal for easy access and affordability.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> For large media files:
                  External hard drives or NAS options offer good value for bulk
                  storage.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> For businesses with
                  critical data: Cloud storage with strong security features and
                  backup solutions provide comprehensive protection.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2 ">✔</span>For long-term
                  archival: Optical discs or tape storage offer cost-effective
                  options for offline data preservation.
                </li>
              </ul>
            </p>
          </div>
          <div className="">
            <img
              src="https://i.ibb.co/rvVMp4k/cloudstorage.png"
              className="rounded-md xl:mt-0 lg:mt-0 md:mt-8 mt-0"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-8 xl:pt-6">
          <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-4 lg:grid-cols-2 lg:gap-4 xl:gap-6 py-6">
            <div className="xl:block lg:block md:block hidden">
              <img
                src="https://i.ibb.co/rtqX9PZ/mailingsolutions.png"
                className="rounded-md xl:mt-12 lg:mt-10 md:mt-24 mt-2"
              ></img>
            </div>
            <div className="pt-4 xl:pl-12">
              <h2 className="font font-semibold xl:text-2xl text-red">
                Mailing Solutions
              </h2>
              <p className="font py-2">
                <ul className="list-none xl:pt-2 lg:pt-2 pt-0">
                  <li className="font xl:text-base text-sm flex items-start ">
                    <span className="mr-2 text-red">✔</span> Email: Powerful
                    email with advanced features like search, labels, and
                    filters.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start py-3">
                    <span className="mr-2">✔</span> Calendar: Create and manage
                    events, schedule meetings, and share calendars with
                    colleagues.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start ">
                    <span className="mr-2 text-red">✔</span> Drive: Secure cloud
                    storage for files and documents, accessible from any device.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start py-3">
                    <span className="mr-2 ">✔</span> Docs, Sheets,
                    Slides: Create and edit documents, spreadsheets, and
                    presentations collaboratively in real-time.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start ">
                    <span className="mr-2 text-red">✔</span> Meet Video:
                    Conferencing and online meetings for seamless communication.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start py-3">
                    <span className="mr-2 ">✔</span> Chat: Instant
                    messaging for quick communication and team collaboration.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start ">
                    <span className="mr-2 text-red">✔</span> Admin console:
                    Manage user accounts, set permissions, and configure
                    security settings.
                  </li>
                </ul>
              </p>
            </div>
            <div className="xl:hidden lg:hidden md:hidden block">
              <img
                src="https://i.ibb.co/rtqX9PZ/mailingsolutions.png"
                className="rounded-md xl:pl-12 xl:pt-12 lg:pt-16 md:pt-12 mt-2"
              ></img>
            </div>
          </div>
        </div>
      </div>
 
      <div className="font container mx-auto relative overflow-hidden xl:px-16 px-4 xl:py-8 lg:py-0 space-y-6 lg:space-y-2 pb-4 xl:pt-24 pt-8">
        <div className="tab-buttons text-center">
          <div className="grid xl:grid-cols-3 md:grid-cols-3 grid-cols-1 border-b-c xl:gap-0 gap-2 ">
            <button
              className={`${
                activeTab === 1 ? "active xl:text-xl text-xs" : "border-sky-600"
              } font font-semibold mx-4 xl:text-xl text-base xl:pt-2 xl:py-0 py-2 focus:outline-none`}
              onClick={() => handleTabClick(1)}
            >
              <div className="flex items-center xl:ml-10">
                <img
                  src="https://i.ibb.co/kqr6g8q/applicationsecurity.png"
                  className="xl:h-10 xl:w-10 xl:mr-2 xl:mb-2 h-5 w-5 mr-2"
                />
                <p className="xl:text-base xl:mb-2 text-left pl-2">
                  Benefits of Enterprise Mail Solution
                </p>
              </div>
            </button>

            <button
              className={`${
                activeTab === 2 ? "active xl:text-xl text-xs" : "border-sky-600"
              } font font-semibold mx-4 xl:text-xl text-base xl:pt-2 xl:py-0 py-2 focus:outline-none`}
              onClick={() => handleTabClick(2)}
            >
              <div className="flex items-center xl:ml-10">
                <img
                  src="https://i.ibb.co/CwpwFXB/networksecurity.png"
                  className="xl:h-10 xl:w-10 xl:mr-2 xl:mb-2 h-5 w-5 mr-2"
                />
                <p className="xl:text-base xl:mb-2 text-left pl-2">
                  What is Enterprise Mailing Solutions?
                </p>
              </div>
            </button>

            <button
              className={`${
                activeTab === 3 ? "active xl:text-xl text-xs" : "border-sky-600"
              } font font-semibold mx-4 xl:text-xl text-base xl:pt-2 xl:py-0 py-2 focus:outline-none`}
              onClick={() => handleTabClick(3)}
            >
              <div className="flex items-center xl:ml-10">
                <img
                  src="https://i.ibb.co/khKjyZq/cloudsecurity.png"
                  className="xl:h-10 xl:w-10 xl:mr-2 xl:mb-2 h-5 w-5 mr-2"
                />
                <p className="xl:text-base xl:mb-2 text-left pl-2">
                  Getting Started with Mailing Solutions
                </p>
              </div>
            </button>
          </div>
        </div>
      </div>

      <div className="font container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-16">
        <div className="tab-content">
          {activeTab === 1 && (
            <div className="content-center xl:px-5 xl:pt-0 xl:text-xl">
              {/* Content for Our Vision */}
              <div className="grid xl:grid-cols-2 xl:gap-4 md:grid-cols-2 md:gap-4 text-lg ">
                <div>
                  <p className="font text-base pt-2">
                    <ul className="list-none xl:pt-4 pt-4">
                      <li className="font xl:text-base text-sm flex items-start ">
                        <span className="mr-2 text-red">✔</span> Accessibility:
                        Work from anywhere with access from any device with an
                        internet connection.
                      </li>
                      <li className="font xl:text-base text-sm flex items-start py-3">
                        <span className="mr-2">✔</span> Security:
                        Google's/Microsoft’s robust security infrastructure
                        protects your data.
                      </li>
                      <li className="font xl:text-base text-sm flex items-start ">
                        <span className="mr-2 text-red">✔</span> Collaboration:
                        Improve teamwork and communication with integrated
                        tools.
                      </li>
                      <li className="font xl:text-base text-sm flex items-start py-3">
                        <span className="mr-2 ">✔</span> Scalability: Easily add
                        licenses as your business grows.
                      </li>
                      <li className="font xl:text-base text-sm flex items-start ">
                        <span className="mr-2 text-red">✔</span> Cost-effective:
                        Affordable subscription plans for different needs.
                      </li>
                      <li className="font xl:text-base text-sm flex items-start py-3">
                        <span className="mr-2 ">✔</span> Integration: Easily
                        integrates with other popular business tools.
                      </li>
                      <li className="font xl:text-base text-sm flex items-start ">
                        <span className="mr-2 text-red xl:mb-8 lg:mb-8 md:mb-8 mb-8">✔</span> Automatic
                        updates: Always have the latest features and security
                        updates.
                      </li>
                    </ul>
                  </p>
                </div>
                <div>
                  <img
                    src={enterprisemailsolutions}
                    className="rounded-md xl:mt-4 md:mt-20 mt-4"
                  ></img>
                </div>
              </div>
            </div>
          )}
          {activeTab === 2 && (
            <div className="content-center xl:px-5 xl:pt-0 xl:text-xl">
              {/* Content for Our Vision */}
              <div className="grid xl:grid-cols-2 xl:gap-4 md:grid-cols-2 md:gap-4 text-lg ">
                <div>
                  <ul className="list-none xl:pt-20 pt-4 md:pt-4">
                    <li className="font xl:text-base text-sm flex items-start ">
                      <span className="mr-2 text-red">✔</span> Businesses of all
                      sizes: From startups to large enterprises, Workspace
                      offers flexible plans to suit any need.
                    </li>
                    <li className="font xl:text-base text-sm flex items-start py-3">
                      <span className="mr-2">✔</span> Remote teams: Stay
                      connected and productive even when working remotely.
                    </li>
                    <li className="font xl:text-base text-sm flex items-start ">
                      <span className="mr-2 text-red">✔</span> Educational
                      institutions: Manage student accounts, share resources,
                      and collaborate on projects.
                    </li>
                    <li className="font xl:text-base text-sm flex items-start py-3">
                      <span className="mr-2 xl:mb-8 lg:mb-8 md:mb-8 mb-8">✔</span> Individuals: Simplify
                      tasks and collaborate with others on personal projects.
                    </li>
                  </ul>
                </div>
                <div>
                  <div>
                    <img
                      src={whyenterprisemailsolutions}
                      className="rounded-md xl:mt-4 lg:mt-4 md:mt-4 mt-4 xl:mb-6 lg:mb-6 md:mb-6 mb-6 "
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeTab === 3 && (
            <div className="content-center xl:px-5 xl:pt-0 xl:text-xl">
              {/* Content for Our Vision */}
              <div className="grid xl:grid-cols-2 xl:gap-4 xl:gap-4 md:grid-cols-2 md:gap-4 text-lg ">
                <div>
                  <ul className="list-none xl:pt-20 pt-4 md:pt-4">
                    <li className="font xl:text-base text-sm flex items-start ">
                      <span className="mr-2 text-red">✔</span> Choose the right
                      plan for your needs: MARSLAB offers various plans with
                      different features and storage limits correspondence to
                      the OEM.
                    </li>
                    <li className="font xl:text-base text-sm flex items-start py-3">
                      <span className="mr-2">✔</span> Sign up for a free trial:
                      Start with a free trial to test all the features.
                    </li>
                    <li className="font xl:text-base text-sm flex items-start ">
                      <span className="mr-2 text-red">✔</span> Migrate your
                      data: Easily migrate your email, documents, and calendar
                      from other platforms.
                    </li>
                    <li className="font xl:text-base text-sm flex items-start py-3">
                      <span className="mr-2">✔</span> Get help and support:
                      Google/Microsoft offers extensive resources and support to
                      help you get started and use it effectively.
                    </li>
                  </ul>
                </div>
                <div>
                  <img
                    src={mailingsolutions}
                    className="rounded-md xl:mt-4 md:mt-4 md:mt-4 md:mt-4 xl:mb-6 lg:mb-6 md:mb-6 mb-6 "
                  ></img>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-8 xl:pt-6">
          <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-4 lg:grid-cols-2 lg:gap-4 xl:gap-6 py-6">
            <div className="xl:block lg:block md:block hidden">
              <img
                src="https://i.ibb.co/khnx7d9/hybridcloud.png"
                className="rounded-md xl:mt-12 lg:mt-8 md:mt-36 pt-0"
              ></img>
            </div>
            <div className="pt-4 xl:pl-12">
              <h2 className="font font-semibold xl:text-2xl text-red">
                Hybrid Cloud
              </h2>
              <p className="font  xl:text-base xl:pt-4 mt-2 ">
                Hybrid cloud has become the buzzword of the technology world,
                offering a unique approach to balancing the strengths of
                on-premises, infrastructure with the agility and scalability of
                the cloud.
              </p>
              <ul className="list-none xl:pt-4 pt-4">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Agility and
                  Scalability: Benefit from the cloud's agility for
                  unpredictable workloads, while maintaining control over core
                  assets on-premises.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Cost Optimization: Pay only
                  for the cloud resources you use, while keeping existing
                  infrastructure investments.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Improved Security:
                  Enhance data security by keeping sensitive information
                  onpremises, while exploiting cloud security features for
                  public data.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2 ">✔</span> Flexibility and Choice:
                  Choose the best environment for each application based on its
                  specific needs and requirements.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Disaster Recovery:
                  Ensure greater resiliency and backup options by distributing
                  data and applications across both environments.
                </li>
              </ul>
            </div>{" "}
            <div className="xl:hidden lg:hidden md:hidden block">
              <img
                src="https://i.ibb.co/khnx7d9/hybridcloud.png"
                className="rounded-md xl:pl-12 xl:pt-6 lg:pt-12 md:pt-8 mt-4"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 ">
          <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-4 lg:grid-cols-2 lg:gap-4 xl:gap-12 py-6">
            <div className="pt-4">
              <h2 className="font font-semibold xl:text-2xl ">
                Hybrid Cloud Providers
              </h2>
              <p className="font  xl:text-base xl:pt-4 md:pt-2 mt-2  ">
                Major cloud providers like AWS, Azure, and Google Cloud Platform
                offer comprehensive hybrid cloud solutions with tools and
                services tailored for seamless integration with on-premises,
                infrastructure.
              </p>

              <ul className="list-none xl:pt-4 pt-4">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Hybrid Cloud
                  Strategy: We are experts in developing a clear strategy
                  outlining your hybrid cloud goals, migration process, and
                  management procedures.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Security Best Practices: We
                  Implement robust security measures and data encryption across
                  both environments.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Continuous Monitoring
                  and Optimization: We regularly monitor performance and adapt
                  your hybrid environment to optimize its efficiency and
                  security as per the client’s requirement.
                </li>
              </ul>
            </div>
            <div className="">
              <img
                src="https://i.ibb.co/Xyg6pPP/hybridcloudproviders.png"
                className="rounded-md xl:mt-6 lg:mt-4 mt-4 md:mt-20"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 xl:pt-6">
          <div className="pt-4 xl:pl-12">
            <h2 className="font font-semibold xl:text-3xl text-xl text-red text-center xl:pb-3 lg:pb-3 md:pb-3 sm:pb-3 pb-3">
              Managed Support
            </h2>
            <p className="font  text-center xl:text-base xl:px-32 xl:pt-2">
              Managing your IT infrastructure can be demanding. Servers
              whirring, networks humming, security patches piling up it's enough
              to turn anyone into a tech hermit. But fear not, there's a knight
              in shining armor waiting to swoop in <br></br>Managed Support for
              IT Infrastructure.
            </p>
          </div>
          <div className="grid xl:grid-cols-2 md:grid-cols-2 xl:px-16 lg:grid-cols-2 py-6">
            <div className="pt-4">
              <div className="bg-grey-100 xl:p-10 p-4 md:p-6 rounded-l-md">
                <h2 className="font font-semibold xl:text-base xl:pl-2">
                  Benefits of Managed Support
                </h2>
                <ul className="list-none xl:pt-4 pt-4">
                  <li className="font xl:text-base text-sm flex items-start ">
                    <span className="mr-2 text-red">✔</span> Peace of Mind:
                    Focus on your core business knowing your IT is in expert
                    hands.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start py-3">
                    <span className="mr-2">✔</span> Reduced Costs: Avoid costly
                    downtime and expensive technical fixes with proactive
                    maintenance.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start ">
                    <span className="mr-2 text-red">✔</span> Increased
                    Efficiency: Let the knights handle the tech, freeing up your
                    team for more productive tasks.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start py-3">
                    <span className="mr-2">✔</span> Enhanced Security: Benefit
                    from advanced security protocols and expertise to protect
                    your valuable data.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start ">
                    <span className="mr-2 text-red">✔</span> Scalability and
                    Flexibility: Tailor your support package to your specific
                    needs and adjust it as your business grows.
                  </li>
                </ul>
              </div>
            </div>
            <div className="pt-4 ">
              <div className="bg-red-100 xl:p-10 p-4 md:p-6 rounded-r-md">
                <ul className="list-none xl:pt-4 pt-4">
                  <li className="font xl:text-base text-sm flex items-start ">
                    <span className="mr-2 text-red">✔</span> Monitoring and
                    Proactive Maintenance: Say goodbye to sleepless nights
                    worrying about server crashes. Your knights are constantly
                    on watch, detecting and resolving issues before they even
                    impact your day.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start py-3">
                    <span className="mr-2">✔</span> Patch Management and
                    Security Updates: No more manual patch updates! Your knights
                    keep your software and systems current with the latest
                    security fixes, leaving hackers out in the cold.
                  </li>
                  <li className="font xl:text-base text-sm flex items-start xl:pb-6 md:pb-12 pb-0">
                    <span className="mr-2 text-red">✔</span> Helpdesk and
                    Technical Support: Got a tech hiccup? No problem! Just reach
                    out to your knights, and they'll be there to diagnose and
                    resolve any issue, big or small.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 xl:pt-6">
          <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-4 lg:grid-cols-2 lg:gap-4 xl:gap-6 py-6">
            <div className="">
              <img
                src="https://i.ibb.co/23C365N/consulting.png"
                className="rounded-md xl:mt-12 lg:mt-12 md:mt-36 mt-0"
              ></img>
            </div>
            <div className="pt-8 xl:pl-12">
              <h2 className="font font-semibold xl:text-2xl text-red xl:pb-3 md:pb-3 lg:pb-3 sm:pb-3 pb-3">
                Consulting
              </h2>
              <p className="font xl:text-base ">
                MARSLAB guides you through the maze of servers, networks, and
                software. We help you to design, implement, and manage your IT
                infrastructure, a complex ecosystem that powers your entire
                digital operation.
              </p>
              <ul className="list-none xl:pt-4 pt-2">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Infrastructure
                  Assessment: Analyze your current IT systems, identifying
                  strengths, weaknesses, and potential bottlenecks.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Strategic Planning: Develop a
                  roadmap for optimizing your infrastructure based on your
                  business goals and budget.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Technology
                  Recommendations: Guide you in choosing the right hardware,
                  software, and cloud solutions for your specific needs.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2 ">✔</span> Implementation and Migration:
                  Assist with deploying new technologies and seamlessly
                  migrating from outdated systems.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Security and Private
                  Compliance: Help you design and implement robust security
                  measures and comply with industry regulations.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto relative overflow-hidden xl:px-16 px-4">
        <div className="xl:px-16 ">
          <h3 className="font  xl:text-lg text-center xl:pt-16 lg:pt-8 xl:pb-6 xl:px-32 xl:mt-4 lg:mt-4 md:mt-4 mt-4">
            IT Facility Management Services are the guardians of your digital
            throne room. By partnering with MARSLAB, you can create a secure,
            efficient, and scalable IT environment that empowers your business
            to reach its full potential.
          </h3>
          <div className="grid xl:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 pt-4">
            <div className="bg-grey-100 p-6 rounded-l-md">
              <h5 className="font text-left font-semibold ">
                IT Facility Management Service
              </h5>
              <ul className="list-none xl:pt-4 pt-4">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Data Center
                  Management: Optimize your data center environment for maximum
                  uptime, security, and cooling efficiency.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Network Infrastructure
                  Maintenance: Maintain flawless network operations with
                  cabling, equipment upkeep, and proactive monitoring.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Power and Cooling
                  Systems Management: Ensure steady power delivery and optimal
                  cooling to keep your equipment humming happily.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2 ">✔</span> Physical Security: Secure
                  your IT assets with access control, surveillance, and disaster
                  recovery plans.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Environmental
                  Monitoring: Maintain optimal temperature, humidity, and dust
                  levels for device health and data integrity.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2 ">✔</span> Compliance Management: Ensure
                  adherence to environmental and safety regulations in your IT
                  facilities.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Reporting and
                  Analytics: Gain valuable insights into your infrastructure
                  performance and identify areas for improvement.
                </li>
              </ul>{" "}
            </div>

            <div className="bg-red-100 p-6 rounded-r-md">
              <h5 className="font text-left font-semibold ">
                Benefits of IT Infrastructure Consulting
              </h5>
              <ul className="list-none xl:pt-4 pt-4">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Reduced Costs:
                  Optimize your infrastructure for efficiency and avoid costly
                  mistakes with expert guidance.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Improved Performance and
                  Scalability: Ensure your IT keeps pace with your business
                  growth and evolving needs.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Enhanced Security and
                  Compliance: Minimize vulnerabilities and meet essential
                  industry regulations.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Increased Agility and
                  Innovation: Gain the flexibility to embrace new technologies
                  and drive digital transformation.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Peace of Mind: Leave
                  the heavy lifting to the experts and focus on running your
                  business.
                </li>
              </ul>{" "}
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 xl:mt-32">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 ">
          <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-4 lg:grid-cols-2 lg:gap-4 xl:gap-12 py-6 xl:py-12 md:py-0 lg:py-0">
            <div className="pt-4">
              <h2 className="font font-semibold xl:text-2xl text-red xl:pb-3 md:pb-3 lg:pb-3 sm:pb-3 pb-3">
                Private Cloud
              </h2>
              <p className="font  xl:text-base">
                Imagine a walled garden for your IT infrastructure. Unlike the
                public cloud's shared pastures, a private cloud is exclusively
                yours, offering customized resources, enhanced security, and
                complete control over your data and applications.
              </p>
              <ul className="list-none xl:pt-4 pt-4">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Dedicated Resources:
                  Your servers, storage, and network are entirely dedicated to
                  your needs, ensuring optimal performance and scalability.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Enhanced Security: You control
                  access, data encryption, and security protocols, creating a
                  fortress for your sensitive information.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Flexibility and
                  Customization: Tailor your infrastructure to your specific
                  needs and applications, without limitations imposed by shared
                  resources.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Greater Control: Manage your
                  cloud environment on your terms, including hardware, software,
                  and configuration.
                </li>
              </ul>
            </div>
            <div className="md:mt-32 xl:mt-6 lg:mt-8">
              <img
                src="https://i.ibb.co/Qv46XbN/privatecloud.png"
                className="rounded-md "
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 xl:pt-6">
          <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-4 lg:grid-cols-2 lg:gap-4 xl:gap-6 py-6">
            <div className="xl:block lg:block md:block hidden">
              <img
                src="https://i.ibb.co/nMdJ7Pq/benefitsprivatecloud.png"
                className="rounded-md xl:mt-6 lg:mt-8 md:mt-20"
              ></img>
            </div>
            <div className="pt-4 xl:pt-8 xl:pl-12">
              <h2 className="font font-semibold xl:text-xl">
                Who Benefits from a Private Cloud?
              </h2>
              <ul className="list-none xl:pt-4 pt-4">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Businesses with
                  sensitive data: Financial institutions, healthcare
                  organizations, and government agencies prioritize data
                  security and compliance, making private clouds ideal.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Organizations with high
                  compliance requirements: Industries with strict regulations
                  benefit from the control and customization offered by private
                  clouds.
                </li>

                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Companies with
                  predictable workloads: Large organizations with consistent
                  resource needs can optimize costs and performance with a
                  dedicated private cloud.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Businesses seeking agility and
                  control: Those wanting to quickly adapt to changing demands
                  and experiment with new technologies can benefit from private
                  cloud customization.
                </li>
              </ul>
            </div>
            <div className="xl:hidden lg:hidden md:hidden block">
              <img
                src="https://i.ibb.co/nMdJ7Pq/benefitsprivatecloud.png"
                className="rounded-md xl:pt-6  lg:pt-8 md:pt-12"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 xl:pt-12">
          <div className="grid xl:grid-cols-2 md:grid-cols-2 md:gap-4 lg:grid-cols-2 lg:gap-4 xl:gap-12 py-6">
            <div className="pt-4">
              <h2 className="font font-semibold xl:text-2xl text-red xl:pb-3 md:pb-3 lg:pb-3 sm:pb-3 pb-3">
                Software Defined Networking
              </h2>
              <p className="font  xl:text-base">
                In traditional networks, hardware rules. Routers and switches
                act independently, making decisions based on their local
                configuration. SDN breaks these chains, decoupling the control
                plane (the brain that makes decisions) from the data plane (the
                muscle that forwards traffic).
              </p>
              <ul className="list-none xl:pt-4 pt-4">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Centralized
                  intelligence: A software-based controller oversees the entire
                  network, making decisions from a global perspective.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Programmable networks: You can
                  write software to define how traffic flows, customize network
                  behavior, and automate tasks.
                </li>
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span> Vendor neutrality:
                  SDN supports hardware from different vendors, freeing you from
                  vendor lock-in.
                </li>
              </ul>
            </div>
            <div className="">
              <img
                src="https://i.ibb.co/PTrLrp9/networking.png"
                className="rounded-md  mt-4 md:mt-20 lg:mt-2 xl:mt-0"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="container mx-auto relative overflow-hidden xl:px-16 px-4 xl:pb-12 ">
          <div className="grid xl:grid-cols-3 md:grid-cols-3 md:gap-4 lg:grid-cols-3 lg:gap-4 xl:gap-6 py-6">
            <div className="pt-4">
              <h2 className="font font-semibold xl:text-xl">Benefits of SDN</h2>
              <ul className="list-none xl:pt-4 pt-4">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span>Agility: Rapidly adapt
                  network resources to changing demands, deploy new services
                  quickly, and optimize traffic flows.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Automation: Automate network
                  provisioning, configuration, and management tasks, reducing
                  manual errors and operational costs.
                </li>

                <li className="font xl:text-base text-sm flex items-start">
                  <span className="mr-2 text-red">✔</span> Innovation:
                  Experiment with new network architectures and technologies
                  without disrupting existing infrastructure.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Scalability: Seamlessly add or
                  remove network capacity to meet growing needs.
                </li>
              </ul>
            </div>

            <div className="pt-4">
              <h2 className="font font-semibold xl:text-xl">
                Key Components of SDN
              </h2>

              <ul className="list-none xl:pt-4 pt-4">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span>SDN Controller: The
                  brain of the network, responsible for decision-making, policy
                  enforcement, and managing network resources.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> SDN Applications: Software
                  programs that interact with the controller to define network
                  behavior, such as security, load balancing, and traffic
                  optimization
                </li>

                <li className="font xl:text-base text-sm flex items-start">
                  <span className="mr-2 text-red">✔</span> OpenFlow Protocol: A
                  common language used by the controller to communicate with
                  network devices, enabling vendor-neutral interoperability.
                </li>
              </ul>
            </div>

            <div className="mt-4">
              <h2 className="font font-semibold xl:text-xl">
                Use Cases for SDN
              </h2>
              <ul className="list-none xl:pt-4 pt-4">
                <li className="font xl:text-base text-sm flex items-start ">
                  <span className="mr-2 text-red">✔</span>Data center
                  networking: Optimize traffic flows, improve resource
                  utilization, and automate data center operations.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Cloud computing: Enable
                  dynamic resource allocation and network segmentation for
                  multi-tenant environments.
                </li>

                <li className="font xl:text-base text-sm flex items-start">
                  <span className="mr-2 text-red">✔</span> Wide area networks
                  (WANs): Improve network performance, reduce costs, and
                  simplify management of distributed networks.
                </li>
                <li className="font xl:text-base text-sm flex items-start py-3">
                  <span className="mr-2">✔</span> Mobile networks: Enhance
                  network flexibility and scalability to manage the growing
                  demands of mobile devices and applications.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </section>
  );
};

export default Infrastructure;
